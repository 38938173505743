import { useEffect, useRef, useState } from "react";
import { Button, InputGroup, Modal, ModalProps } from "react-bootstrap";
import GetAppIcon from '@material-ui/icons/GetApp';
import Close from "@material-ui/icons/Close";
import { EXPORT_EXCEL_IMPORT_URL, StudentAPI, TEMPLATE_URL } from "../../api/student-api";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getAccessToken } from "../../store/auth/selectors";
import { StudentType } from "../../api/define";
import { SearchPanel, TableHeaderBlack } from "../../style/tableStyle";
import { CustomPage } from "../share/CustomPage";
import excellogo from '../../img/excel.png';
import { queryclear } from "../../store/search/actions";
import { clearTempClassStudent, importStudentModal, saveTempStudent, tempStudentPagination } from "../../store/editstudent/actions";
import { MAX_FILE_UPLOAD } from "../../api/conf";
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from "../../api/label";
import { StudentList, TempClassStudentUpdation } from "../../model/response/student-list";
import CancelIcon from '@material-ui/icons/Cancel';
import loadinglogo from '../../img/loading.gif';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = ModalProps & {
    id: string;
    onHide: () => void;
}

const ImportStudentModal = (props: Props) => {

    const boxImport: React.CSSProperties = {
        padding: "20px",
        backgroundColor: "#fff7dd",
        borderRadius: "5px",
        border: "2px solid #fce053",
        marginTop: "1rem",
    };

    const boxSearch: React.CSSProperties = {
        padding: "10px",
        backgroundColor: "#EEEEEE",
        borderRadius: "5px",
        border: "2px solid #e8e8e8",
    };

    const styles = {
        container: {
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
        },
        textInput: {
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            flex: "1",
        },
        label: {
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "18px",
            margin: "0",
        },
        checkbox: {
            margin: "0",
        },
        clearButton: {
            padding: "8px 12px",
            borderRadius: "4px",
            border: "none",
            backgroundColor: "#ddd",
            cursor: "pointer",
        },
    };

    const rowTableDelete: React.CSSProperties = {
        backgroundColor: "#fff2f0",
    };

    const store = useStore();
    const token = useSelector(getAccessToken);
    const dispatch = useDispatch();
    const ref = useRef<any>();
    const [currentFile, setCurrentFile] = useState<FileList>();
    const [success, setSuccess] = useState(false)
    const [errorUpload, setErrorUpload] = useState("");
    const [page, setPage] = useState(0);
    const [size, setSize] = useState<number>(5);
    const [totalElement, setTotalElement] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [searchName, setSearchName] = useState<string>("");
    const [items, setItems] = useState<StudentList[]>([]);
    const [itemsUpdate, setItemsUpdate] = useState<TempClassStudentUpdation>();
    const [summarize, setSummarize] = useState({
        totalPages: 0,
        totalElements: 0,
        passedElements: 0,
        newElements: 0,
        updatedElements: 0,
        failedElements: 0,
        allElements: 0
    });
    const [uploadComplete, setUploadComplete] = useState(false);

    const [filters, setFilters] = useState({
        ERROR: true,
        NEW: false,
        UPDATED: false,
    });

    const [isDelete, setIsDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<number>();
    const [isClose, setIsClose] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isCanNotSave, setIsCanNotSave] = useState(false);
    const [isNewUpload, setIsNewUpload] = useState(false);
    const [isFirst, setIsFirst] = useState(false);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            console.log("res", res);
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "temp_student_pagination") {
                        summarize.failedElements = res.data.failedElements;
                        summarize.newElements = res.data.newElements;
                        summarize.passedElements = res.data.passedElements;
                        summarize.totalPages = res.data.totalPages;
                        summarize.totalElements = res.data.totalElements;
                        summarize.updatedElements = res.data.updatedElements;
                        summarize.allElements = res.data.allElements;
                        setSummarize(summarize);
                        setTotalElement(res.data.totalElements);
                        setItems(res.data.elements);
                        setUploadComplete(true);
                        setItemsUpdate(undefined);
                        setIsFirst(true);
                    }
                }

            } else if (res.isUpdating) {

            } else if (res.isUpdated) {
                if (res.table === "import_student") {
                    const activeFilters = Object.entries(filters)
                        .filter(([key, value]) => value)
                        .map(([key]) => key);

                    setPage(0);
                    dispatch(queryclear());
                    dispatch(tempStudentPagination(parseInt(props.id), searchName, activeFilters, undefined, page, size));

                } else if ((res.table === "save_temp_student") && (res.isUpdated)) {
                    onCloseConfirm();
                    props.onHide();
                }

            } else if (res.isFailed) {
                setLoading(false);
            }
        });
        dispatch(queryclear());
        return unsubscribe;
    }, []);

    const upload = async () => {
        var res = validateFile();

        if (uploadComplete && res) {
            setIsNewUpload(true);
        } else {
            if ((res) && (currentFile !== undefined)) {
                dispatch(queryclear());
                dispatch(clearTempClassStudent(parseInt(props.id)));
                await dispatch(importStudentModal(parseInt(props.id), currentFile));
                ref.current.value = "";
            }
        }

    }

    useEffect(() => {
        if (!isFirst) {
            upload();
        }

        if (isFirst && uploadComplete) {
            const isNewCall = summarize.failedElements === 0;
            if (isNewCall) {
                filters.ERROR = true;
                filters.NEW = true;
                filters.UPDATED = true;
                setFilters(filters);

                const activeFilters = Object.entries(filters)
                    .filter(([key, value]) => value)
                    .map(([key]) => key);

                dispatch(queryclear());
                dispatch(tempStudentPagination(parseInt(props.id), searchName, activeFilters, undefined, page, size));
            }
        }

    }, [isFirst]);

    const validateFile = (): boolean => {
        setErrorUpload('');
        if ((currentFile !== undefined) && (currentFile.length > 0)) {
            let file_size = currentFile[0].size;
            if (file_size > MAX_FILE_UPLOAD * 1000000) {
                setErrorUpload(MAX_UPLOAD_FILE_SIZE_LABEL);
                return false;
            }
        }
        if ((currentFile === undefined) || (currentFile.length === 0)) {
            setErrorUpload(REQUIRE_LABEL);
            return false;
        }
        return true;
    }


    const handleTemplate = () => {
        window.open(TEMPLATE_URL + "?template_type=" + StudentType.LIST + "&token=" + encodeURIComponent(token), '_blank', 'noopener,noreferrer')
    }

    const handleFileChange = (selectorFiles: FileList | null) => {
        if (selectorFiles !== null) {
            setCurrentFile(selectorFiles);
        }
    }

    useEffect(() => {
        if (uploadComplete) {
            setPage(0);
            const activeFilters = Object.entries(filters)
                .filter(([key, value]) => value)
                .map(([key]) => key);
            dispatch(queryclear());
            dispatch(tempStudentPagination(parseInt(props.id), searchName, activeFilters, itemsUpdate, page, size));
        }
    }, [searchName]);


    const handleCheckboxChange = (event: { target: { name: string; checked: boolean } }) => {
        const { name, checked } = event.target;

        setFilters((prev) => {
            const updatedFilters = { ...prev, [name]: checked };

            // Compute activeFilters based on the updated state
            const activeFilters = Object.entries(updatedFilters)
                .filter(([key, value]) => value)
                .map(([key]) => key);

            dispatch(queryclear());
            dispatch(tempStudentPagination(parseInt(props.id), searchName, activeFilters, itemsUpdate, page, size));

            return updatedFilters;
        });
    };

    const clearFilters = () => {
        setSearchName("");
        setFilters({
            ERROR: false,
            NEW: false,
            UPDATED: false,
        });
        setPage(0);
        dispatch(queryclear());
        dispatch(tempStudentPagination(parseInt(props.id), "", [], undefined, page, size));
    };

    const onPageChange = (value: number) => {
        setPage(value);
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);
    }

    useEffect(() => {
        if (uploadComplete) {
            const activeFilters = Object.entries(filters)
                .filter(([key, value]) => value)
                .map(([key]) => key);
            dispatch(queryclear());
            dispatch(tempStudentPagination(parseInt(props.id), searchName, activeFilters, itemsUpdate, page, size));
        }
    }, [page, size]);

    const mapTypeStatus = (status: string) => {
        switch (status) {
            case "NEW":
                return "N";
            case "UPDATED":
                return "U";
            case "ERROR":
                return "E";
            default:
                return "";
        }
    }

    const onCheckIsNotError = () => {
        const validate = items.some((item) => (item.validateStatus === "ERROR") && !item.willDelete);
        if (validate) {
            setIsCanNotSave(true);
        } else {
            setIsSave(true);
        }
    }

    const onDelete = (id: number) => {
        setIsDelete(true);
        setIdDelete(id);
    }

    const onDeleteConfirm = () => {
        // Update items array and set willDelete = true for the matching item
        const updatedItems = items.map((item) =>
            item.tempClassStudentId === idDelete
                ? { ...item, willDelete: true }
                : item
        );

        setItems(updatedItems);

        // Create the itemsUpdate object with updated items
        const itemsUpdatePayload: TempClassStudentUpdation = {
            willDelete: updatedItems.some((item) => item.willDelete), // Check if at least one item will be deleted
            tempClassStudentList: updatedItems,
        };

        setItemsUpdate(itemsUpdatePayload);

        // Close the delete modal
        setIsDelete(false);
    };

    const onCloseConfirm = () => {
        setSuccess(false);
        setErrorUpload("");
        setItems([]);
        setSummarize({
            totalPages: 0,
            totalElements: 0,
            passedElements: 0,
            newElements: 0,
            updatedElements: 0,
            failedElements: 0,
            allElements: 0
        });
        setPage(0);
        setSize(5);
        setTotalElement(0);
        setUploadComplete(false);
        setFilters({
            ERROR: true,
            NEW: false,
            UPDATED: false,
        });
        setSearchName("");
        setIsClose(false);
        setIsSave(false);

    }

    const onNewUploadConfirm = () => {
        onCloseConfirm();
        setIsNewUpload(false);
    }

    const onSaveConfirm = () => {
        dispatch(queryclear());
        dispatch(saveTempStudent(parseInt(props.id), itemsUpdate));
    }

    const excelElement: any = () => {
        return <Button type="button" className="btn btn-success green-button ml-1" onClick={() => handleExcel()} >
            <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
        </Button>
    }

    const handleExcel = () => {
        const activeFilters = Object.entries(filters)
            .filter(([key, value]) => value)
            .map(([key]) => key);
        window.open(EXPORT_EXCEL_IMPORT_URL + "/" + props.id + "/export" + "?" + StudentAPI.getParamImportListStudent(parseInt(props.id), searchName, activeFilters, page, size) + "&token=" + encodeURIComponent(token));
    }

    return (
        <Modal
            {...props}
            dialogClassName="modal-90w"
            // size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            onShow={() => {
                onCloseConfirm();
            }}
        >
            <Modal.Header >
                <div className="col" style={boxImport}>
                    <h5>Import ผู้เข้าร่วมโครงการ</h5>
                    <div className="row">
                        <div className="col-9">
                            <div className="row">
                                <div className="col-8" style={{ textAlign: "left" }}>
                                    <InputGroup hasValidation>
                                        <input type="file" className={"form-control " + ((errorUpload !== "") ? "is-invalid" : "")} accept=".xlsx" id="contained-button-file" ref={ref} onChange={(e: any) => handleFileChange(e.target.files)} disabled={success} /><br />
                                    </InputGroup>
                                    <div className={(errorUpload !== "") ? "text-danger" : ""}><small>{errorUpload}</small></div>
                                </div>
                                <div className="col-4" onClick={(e) => { upload() }}>
                                    <Button className="btn btn-primary" style={{ width: "120px" }} disabled={success}>
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="col-3" style={{ textAlign: "right", justifyContent: "right" }}>
                            <div onClick={(e) => { handleTemplate() }} style={{ cursor: "pointer" }}><Button className="btn green-button" style={{ color: "white" }}><GetAppIcon></GetAppIcon> Template File</Button></div>
                        </div>
                    </div>
                </div>
                <div onClick={() => uploadComplete ? setIsClose(true) : props.onHide()} style={{ cursor: "pointer" }}><Close></Close></div>

            </Modal.Header>
            <Modal.Body >
                {(loading) ? (
                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                ) : null
                }
                {uploadComplete && <>
                    <div className="col">
                        <span>จำนวนข้อมูลทั้งหมด : {summarize.allElements}</span>
                        <div className="row" style={{ gap: "1rem", paddingLeft: "15px" }}>
                            <span className='green'>จำนวนข้อมูลที่สามารถนำเข้าได้ : {summarize.passedElements}</span><br />
                            <span className='green'>ข้อมูลใหม่ : {summarize.newElements}</span><br />
                            <span className='green'>ข้อมูลอัพเดต : {summarize.updatedElements}</span><br />
                        </div>
                        <span className='red'>จำนวนข้อมูลที่ไม่สามารถนำเข้าได้ : {summarize.failedElements}</span>
                    </div>

                    <div style={styles.container}>
                        <span>ชื่อ-นามสกุล</span>
                        {/* Text Input */}
                        <input className="col-3"
                            type="text"
                            style={styles.textInput}
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                        <br />
                        <span>สถานะการ Import</span>
                        {/* Checkboxes */}
                        <label style={styles.label}>
                            <input
                                type="checkbox"
                                name="ERROR"
                                checked={filters.ERROR}
                                onChange={handleCheckboxChange}
                                style={styles.checkbox}
                            />
                            ไม่สามารถนำเข้าได้ (E)
                        </label>

                        <label style={styles.label}>
                            <input
                                type="checkbox"
                                name="NEW"
                                checked={filters.NEW}
                                onChange={handleCheckboxChange}
                                style={styles.checkbox}
                            />
                            ข้อมูลใหม่ (N)
                        </label>

                        <label style={styles.label}>
                            <input
                                type="checkbox"
                                name="UPDATED"
                                checked={filters.UPDATED}
                                onChange={handleCheckboxChange}
                                style={styles.checkbox}
                            />
                            ข้อมูลอัพเดต (U)
                        </label>
                        <br />
                        {/* Clear Button */}
                        <Button style={{ justifyContent: "flex-end" }} variant="secondary" onClick={() => { clearFilters() }}>
                            Clear
                        </Button>
                    </div>
                    <br />

                    <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={excelElement()} disabledAll={true}>
                        <TableHeaderBlack striped hover className='mt-2'>
                            <thead>
                                <tr>
                                    <th style={{ alignContent: 'center' }}>Excel Line</th>
                                    <th style={{ alignContent: 'center' }}>รหัสองค์กร</th>
                                    <th style={{ alignContent: 'center' }}>ชื่อองค์กร</th>
                                    <th style={{ alignContent: 'center' }}>รุ่น</th>
                                    <th style={{ alignContent: 'center' }}>ประเภทการเรียน</th>
                                    <th style={{ alignContent: 'center' }}>ชื่อ-นามสกุล</th>
                                    <th style={{ alignContent: 'center' }}>email</th>
                                    <th style={{ alignContent: 'center' }}>SET Member Profile</th>
                                    <th style={{ alignContent: 'center' }}>Remark</th>
                                    <th style={{ alignContent: 'center' }}>สถานะ</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(items || []).map((item: StudentList, index: number) => (
                                    <tr key={'answer_' + item.tempClassStudentId} style={item.willDelete ? rowTableDelete : undefined}>
                                        <td className='text-center'>{item.excelLine}</td>
                                        <td className='text-left'> {item.partnerCode}</td>
                                        <td className='text-left'> {item.partnerName} </td>
                                        <td className='text-center'> {item.generation} </td>
                                        <td className='text-left'> {item.course} </td>
                                        <td className='text-left'> {item.firstName} {item.lastName} </td>
                                        <td className='text-left'> {item.email} </td>
                                        <td className="text-left">
                                            {item.setMemberProfile != null ? (
                                                <>
                                                    {item.setMemberProfile.email}
                                                    <br />
                                                    {item.setMemberProfile.firstName} {item.setMemberProfile.lastName}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                        <td className='text-left'> {item.remark} </td>
                                        <td className='text-center'> {mapTypeStatus(item.validateStatus)} </td>
                                        <td className='text-center'>
                                            {!item.willDelete && <div onClick={() => onDelete(item.tempClassStudentId)}><DeleteIcon className="red pointer" ></DeleteIcon></div>}
                                            {item.willDelete && <div>ไม่นำเข้าข้อมูลนี้</div>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableHeaderBlack>
                    </CustomPage>
                    <br />
                </>}
            </Modal.Body>


            {uploadComplete && <Modal.Footer>
                <div className="col">
                    <div className="red" style={{ fontSize: "16px" }}>*** หากท่านต้องการบันทึกข้อมูลเข้าระบบ กรุณาเคลียร์ข้อมูลที่ต้องการบันทึกให้เหลือเฉพาะรายการที่สามารถนำเข้าได้เท่านั้น</div>
                    <div className="row" style={{ justifyContent: "center", gap: "20px", marginTop: "20px" }}>
                        <Button variant="secondary" onClick={() => { setIsClose(true) }}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={() => { onCheckIsNotError() }}>
                            Save
                        </Button>
                    </div>

                </div>

            </Modal.Footer>}

            <Modal show={isDelete} onHide={() => { setIsDelete(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>กรุณายืนยันไม่นำเข้าข้อมูลบรรทัดนี้</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIsDelete(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => { onDeleteConfirm() }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isClose} onHide={() => { setIsClose(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>ยกเลิกการนำเข้าข้อมูลนี้ทั้งหมด</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIsClose(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => { onCloseConfirm(); props.onHide() }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isSave} onHide={() => { setIsSave(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการบันทึกข้อมูล</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIsSave(false) }}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={() => { onSaveConfirm() }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isCanNotSave} onHide={() => { setIsCanNotSave(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>หากท่านต้องการบันทึกข้อมูลเข้าระบบ กรุณาเคลียร์ข้อมูลที่ต้องการบันทึกให้เหลือเฉพาะรายการที่สามารถนำเข้าได้เท่านั้น</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIsCanNotSave(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isNewUpload} onHide={() => { setIsNewUpload(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>กรุณายืนยันการ Import ข้อมูลผู้เรียนด้วยไฟล์ใหม่</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIsNewUpload(false) }}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={() => {
                        onNewUploadConfirm();
                        setIsFirst(false);
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </Modal >
    )
};

export default ImportStudentModal;
