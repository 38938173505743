import React, { useEffect, useState } from 'react'
import MainMenu from './menu'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Footer from './adminfooter';
import PreviewQuestionModal from './previewQuestionModal';
import { AlertContainer, alert } from 'react-custom-alert';
import loadinglogo from '../../img/loading.gif';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { queryclear } from '../../store/search/actions';
import { SingleSearch } from '../tab/search-component';
import { createSurveyQuestion, deleteSurveyQuestion, duplicateSurveyQuestion, getQuestionDetail, listQuestionGroup, previewSurveyQuestion, updateSurveyQuestion } from '../../store/surveygroup/actions';
import { INTERNAL_ERROR_LABEL } from '../../api/label';
import { ActionType } from '../../store/search/types';
import { SurveyType, createSurveyType } from '../../api/define';
import { Util } from '../../api/util';

type Props = {}

const defaultConst: any = {
    surveyType: SurveyType.CHOICE
};
const EditSurveyQuestion = (props: Props) => {
    const [init, setInit] = useState(0)
    const [initQuestionGroup, setInitQuestionGroup] = useState(0)
    const [item, setItem] = useState<any>({ ...defaultConst });
    const [defaultItem, setDefaultItem] = useState<any>(JSON.parse(JSON.stringify(defaultConst)))
    const [edit, setEdit] = useState(false);
    const [success, setSuccess] = useState(false)
    const [answered, setAnswered] = useState<boolean>(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false);
    const [leave, setLeave] = useState("")
    const [deleteId, setDeleteId] = useState<string>("");
    let { id } = useParams<{ id: string }>();
    const [modalShow, setModalShow] = useState<boolean | undefined>();
    const [modalItem, setModalItem] = useState<any>();
    const [questionGroupItem, setQuestionGroupItem] = useState<Array<{ id: number, name: string }>>([])
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    const search = useLocation().search;
    const duplicate = new URLSearchParams(search).get('duplicate');
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.table == 'list_question_group') {
                    setQuestionGroupItem(res.data);
                    setInitQuestionGroup(1);
                } else if (res.table == 'preview_survey_question') {
                    setModalItem(res.data);
                    setModalShow(true);
                } else {
                    if (res.data != null) {
                        var newData = res.data;
                        if (res.table == 'duplicateSurveyQuestion') {
                            newData.version = null;
                        }
                        setDefaultItem(JSON.parse(JSON.stringify(newData)));
                        setItem(newData);

                        setAnswered(newData.questionAlreadyUsed);
                    } else {
                        setError(INTERNAL_ERROR_LABEL);
                    }
                    setInit(1);
                }

            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                setEdit(false);
                setSuccess(true);
                if (res.action === ActionType.DELETE) {
                    alert({ message: 'Deleted', type: 'success' });
                    setItem({ name: "", description: "" });
                    handleClose();
                } else {
                    alert({ message: 'Saved', type: 'success' });
                    setDefaultItem(JSON.parse(JSON.stringify(res.data)));
                    setItem(res.data);
                }
                dispatch(queryclear());
            } else if (res.isFailed) {
                if (res.data.success !== undefined) {
                    setItem(res.data);
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
                dispatch(queryclear());
            }
        });
        setItem({ ...defaultConst }); //reset
        setDefaultItem(JSON.parse(JSON.stringify(defaultConst)));

        dispatch(queryclear());
        dispatch(listQuestionGroup());
        window.scroll(0, 0);
        if (id != null) {
            dispatch(getQuestionDetail(id));
        } else if (duplicate != null) {
            dispatch(duplicateSurveyQuestion(duplicate));

        } else {
            setInit(1);
        }
        return unsubscribe;
    }, [])

    const setTitle = (v: string) => {
        var newItem: any = { ...item };
        newItem.title = v;
        setItem(newItem);
        setEdit(true);
    }

    const getTitle = () => {
        return item.title;
    }

    const getErrorTitle = () => {
        return item.errorTitle;
    }
    const setDescription = (v: string) => {
        var newItem: any = { ...item };
        newItem.description = v;
        setItem(newItem);
        setEdit(true);
    }

    const getDescription = () => {
        return item.description;
    }

    const getErrorDescription = () => {
        return item.errorDescription;
    }

    const handleClear = () => {
        if (edit) setLeave("clear");
    }

    const handleSubmit = () => {
        if (validateForm()) {
            dispatch(queryclear());
            if (id == null) {
                dispatch(createSurveyQuestion(item))
            } else {
                dispatch(updateSurveyQuestion(item))
            }
        }
    }

    const validateForm = () => {
        let validForm = true;
        var newItem: any = { ...item };
        newItem.errorTitle = null;
        newItem.errorQuestionGroupId = null;
        newItem.errorSurveyType = null;
        newItem.errorItems = null;
        if (!item.title) {
            newItem.errorTitle = "Required";
            validForm = false;
        }
        if (!item.questionGroupId) {
            newItem.errorQuestionGroupId = "Required";
            validForm = false;
        }
        if (!item.surveyType) {
            newItem.errorSurveyType = "Required";
            validForm = false;
        }
        if (item.surveyType !== SurveyType.TEXT && (!item.items || item.items.length == 0)) {
            newItem.errorItems = "Required";
            validForm = false;
        }
        if (!validForm) {
            setItem(newItem);
        }
        return validForm;
    }

    const handleClose = () => {
        history.push("/admin/manage/survey");
    }

    const handleBack = () => {
        if (edit) setLeave("/admin/manage/survey");
        else history.push("/admin/manage/survey");
    }

    const handleDelete = () => {
        setDeleteId(id?id.toString():item.id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    const handleDeleteConfirm = () => {
        dispatch(queryclear());
        dispatch(deleteSurveyQuestion(deleteId));
        setDeleteId("");
    }

    const handleReedit = () => {
        setSuccess(false);
    }

    const handleLeave = () => {
        if (leave === "clear") {
            clear();
        } else {
            history.push(leave);
        }
        setLeave("");
    }
    const clear = () => {
        var newItem = JSON.parse(JSON.stringify(defaultItem));
        setItem(newItem);
        setEdit(false);
    }
    const handlePreview = () => {
        console.log(id);
        dispatch(previewSurveyQuestion(id?id.toString():item.id.toString(), null, null))
    }
    const getErrorQuestionGroupId = () => {
        return item.errorQuestionGroupId;
    }
    const setQuestionGroup = (e: String) => {
        var newItem: any = { ...item };
        newItem.questionGroupId = e;
        setItem(newItem);
        setEdit(true);
    }

    const getQuestionGroup = () => {
        for (var i = 0; i < questionGroupItem.length; i++) {
            if (questionGroupItem[i].id === item.questionGroupId) {
                return { label: questionGroupItem[i].name, value: item.questionGroupId };
            }
        }
        return null;
    }
    const createQuestionGroupSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < questionGroupItem.length; i++) {
            res.push({ label: questionGroupItem[i].name, value: questionGroupItem[i].id });
        }
        return res;
    }
    const getErrorSurveyType = () => {
        return item.errorSurveyType;
    }
    const setSurveyType = (v: SurveyType) => {
        var newItem = { ...item };
        newItem.surveyType = v;
        setItem(newItem);
        setEdit(true);
    }
    const getSurveyType = () => {
        return item.surveyType;
    }
    const handleAddAnswer = () => {
        var newItem = { ...item };
        if (newItem.items == null) newItem.items = [];
        newItem.items.push({});
        setItem(newItem);
        setEdit(true);
    }

    const getErrorMainAnswer = () => {
        return item.errorItems;
    }

    const createChoice = (showUnit: boolean) => {
        return (<>
            <div className="row">
                <div className="col-2">
                    <label className="col-form-label">คำตอบ<span className='red'>*</span></label>
                </div>
                <div className="col-10">
                    {createAnswer(showUnit)}
                    {(!success && !answered) &&
                        <div className="col-1" style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer() }}>
                            <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
                        </div>
                    }
                    <div className={(getErrorMainAnswer() != null) ? "text-danger" : ""}><small>{getErrorMainAnswer()}</small></div>
                </div>
            </div>
        </>);
    }

    const getErrorAnswer = (k: number) => {
        return item.items[k].errorTitle;
    }
    const getAnswer = (k: number) => {
        return (item.items[k].title);
    }

    const setAnswer = (k: number, v: string) => {
        var newItem = { ...item };
        newItem.items[k].title = v;
        setItem(newItem);
        setEdit(true);
    }
    const getUnit = (k: number) => {
        return (item.items[k].unit);
    }

    const setUnit = (k: number, v: string) => {
        var newItem = { ...item };
        newItem.items[k].unit = v;
        setItem(newItem);
        setEdit(true);
    }

    const getErrorUnit = (k: number) => {
        return item.items[k].errorUnit;
    }
    const handleDeleteAnswer = (id: number) => {
        var newItem = { ...item };
        //correct id
        var newAnswer: any[] = [];
        var k = 0;
        for (var i = 0; i < newItem.items.length; i++) {
            if (i < id) { newAnswer.push({ ...newItem.items[i] }); k++; }
            else if (i > id) {
                var c = { ...newItem.items[i] };
                c.priority = k;
                newAnswer.push(c);
                k++;
            }
        }
        newItem.items = newAnswer;
        setItem(newItem);
        setEdit(true);
    }
    const createAnswer = (unit: boolean) => {
        var res = [];
        var s = item.items ? item.items : [];
        for (var k = 0; k < s.length; k++) {
            res.push(<>
                <div className="row">
                    <div className={(unit) ? "col-5" : "col-10"}>
                        <InputGroup hasValidation>
                            <input type="text" id={"answer_" + k} maxLength={200} className={"form-control " + ((getErrorAnswer(k) != null) ? "is-invalid" : "")} value={getAnswer(k)} onChange={e => setAnswer(parseInt(e.currentTarget.id.split("_")[1]), e.target.value)} disabled={success || answered} /><br />
                        </InputGroup>
                        <div className={(getErrorAnswer(k) != null) ? "text-danger" : ""}><small>{getErrorAnswer(k)}</small></div>
                    </div>
                    {(unit) &&
                        <div className={"col-5"}>
                            <InputGroup hasValidation>
                                <input type="text" id={"answer_" + k} maxLength={200} className={"form-control " + ((getErrorUnit(k) != null) ? "is-invalid" : "")} value={getUnit(k)} onChange={e => setUnit(parseInt(e.currentTarget.id.split("_")[1]), e.target.value)} disabled={success || answered} /><br />
                            </InputGroup>
                            <div className={(getErrorUnit(k) != null) ? "text-danger" : ""}><small>{getErrorUnit(k)}</small></div>
                        </div>
                    }
                    {(!success && !answered) &&
                        <div className="col-2" id={"answer_" + k} style={{ paddingTop: "5px", marginLeft: "-10px" }} onClick={(e) => { handleDeleteAnswer(parseInt(e.currentTarget.id.split("_")[1])) }}>
                            <RemoveCircleOutlineIcon className="red"></RemoveCircleOutlineIcon>
                        </div>
                    }
                </div><br /></>);
        }
        return res;
    }
    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">คำถาม</h4></div>
                        {(init + initQuestionGroup === 2) &&
                            <>
                                <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                    <br />
                                    <div className="row">
                                        <div className="col-2">
                                            <label className="col-form-label">คำถามที่แสดงในแบบสอบถาม<span className='red'>*</span></label>
                                        </div>
                                        <div className="col-10">
                                            <InputGroup hasValidation>
                                                <Form.Control maxLength={200} className="form-control" type="text" onChange={(e: any) => setTitle(e.target.value)} value={getTitle()}
                                                    isInvalid={(getErrorTitle() != null)} disabled={success} />
                                                <Form.Control.Feedback type="invalid">{getErrorTitle()}</Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className="col-2">
                                            <label className="col-form-label">วันที่บันทึก</label>
                                        </div>
                                        <div className='col-6'>
                                            <input className="form-control" type="text" value={item.version ? Util.datetimetostr(new Date(item.version)) : ''} readOnly={true} />
                                        </div>
                                    </div>

                                    <br />
                                    <div className="row">
                                        <div className="col-2">
                                            <label className="col-form-label">คำอธิบายคำถาม</label>
                                        </div>
                                        <div className="col-10">
                                            <InputGroup hasValidation>
                                                <textarea rows={5}  maxLength={1000} className={"form-control " + ((getErrorDescription()) ? "is-invalid" : "")} onChange={(e: any) => setDescription(e.target.value)}
                                                    disabled={success} value={getDescription()}></textarea>
                                                <Form.Control.Feedback type="invalid">{getErrorDescription()}</Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-2">
                                            <label className="col-form-label">กลุ่มของคำถาม<span className='red'>*</span></label>
                                        </div>
                                        <div className="col-6">
                                            <SingleSearch
                                                disabled={success || answered}
                                                isError={getErrorQuestionGroupId()}
                                                isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                                onChange={(id: string, e: any) => setQuestionGroup(e == null ? null : e?.value)}
                                                value={getQuestionGroup()}
                                                options={createQuestionGroupSearchFilter()}
                                            />
                                            <div className={(getErrorQuestionGroupId() != null) ? "text-danger" : ""}><small>{getErrorQuestionGroupId()}</small></div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className='col-2'>
                                            <label className='col-form-label'>ประเภทของคำถาม<span className='red'>*</span></label>
                                        </div>
                                        <div className="col-6">
                                            <InputGroup hasValidation>
                                                <select className={"form-control " + ((getErrorSurveyType()) ? "is-invalid" : "")} onChange={e => setSurveyType(e.target.value as SurveyType)} value={getSurveyType()} disabled={success || answered}>
                                                    {createSurveyType()}
                                                </select>
                                            </InputGroup><br />
                                            <div className={(getErrorSurveyType() != null) ? "invalid-feedback" : ""}>{getErrorSurveyType()}</div>
                                        </div>
                                    </div>

                                    <div>
                                        {(item.surveyType === SurveyType.CHOICE) && createChoice(false)}
                                        {(item.surveyType === SurveyType.MULTIPLE) && createChoice(false)}
                                        {(item.surveyType === SurveyType.DEGREE) && createChoice(false)}
                                        {(item.surveyType === SurveyType.RANKING) && createChoice(false)}
                                        {(item.surveyType === SurveyType.TEXT) && <></>}
                                        {(item.surveyType === SurveyType.NUMBER) && createChoice(true)}
                                    </div>

                                </div>
                                <br />
                            </>
                        }
                        <br />
                        {(((init + initQuestionGroup < 2) || (loading) || (updating)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                        {(init + initQuestionGroup === 2) && (!loading) && (!success) && (!answered) && (!updating) &&
                            <div className='d-flex'>
                                <div className='text-left'>
                                    <div>สร้างโดย {item.createdBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.createdDate)) || "-"}  </div>
                                    <div>แก้ไขล่าสุดโดย {item.updatedBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.updatedDate)) || "-"} </div>
                                </div>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>&nbsp;
                                    {(id !== undefined) &&
                                        <><button type="button" className="btn btn-danger" style={{ width: "120px" }}
                                            onClick={() => handleDelete()} >
                                            Delete
                                        </button>&nbsp;</>
                                    }
                                    <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                        onClick={() => handleClear()} >
                                        Reset
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                        onClick={() => handleSubmit()} >
                                        Save
                                    </button>&nbsp;
                                    {((id !== undefined) && (edit === false)) &&
                                        <button type="button" className="btn purple-button" style={{ width: "120px" }}
                                            onClick={() => handlePreview()} >
                                            Preview
                                        </button>
                                    }
                                </div>

                            </div>
                        }
                        {(!success && answered) && 
                            <div className='d-flex'>
                                <div className='text-left'>
                                    <div>สร้างโดย {item.createdBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.createdDate)) || "-"}  </div>
                                    <div>แก้ไขล่าสุดโดย {item.updatedBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.updatedDate)) || "-"} </div>
                                </div>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                        onClick={() => handleClose()} >
                                        Back
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                        onClick={() => handleSubmit()} >
                                        Save
                                    </button>&nbsp;
                                    {(item.id !== undefined) &&
                                        <button type="button" className="btn purple-button" style={{ width: "120px" }}
                                            onClick={() => handlePreview()} >
                                            Preview
                                        </button>
                                    }
                                </div>

                            </div>
                        }
                        {((success && answered) || (success && !answered)) && 
                            <div className='d-flex'>
                                <div className='text-left'>
                                    <div>สร้างโดย {item.createdBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.createdDate)) || "-"}  </div>
                                    <div>แก้ไขล่าสุดโดย {item.updatedBy || "-"} เมื่อ {Util.datetimetostr(new Date(item.updatedDate)) || "-"} </div>
                                </div>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                        onClick={() => handleClose()} >
                                        Back
                                    </button>&nbsp;
                                    <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                                        onClick={() => handleReedit()} >
                                        Edit
                                    </button>&nbsp;
                                    {(item.id !== undefined) &&
                                        <button type="button" className="btn purple-button" style={{ width: "120px" }}
                                            onClick={() => handlePreview()} >
                                            Preview
                                        </button>
                                    }
                                </div>

                            </div>
                        }
                    </div>
                    <br />
                    <Footer />
                </div>
            </MainMenu>
            <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
                <Modal.Header closeButton>
                    <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setLeave("") }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => { handleLeave() }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <PreviewQuestionModal backdrop="static" show={modalShow != undefined} onHide={() => setModalShow(undefined)} onOk={() => { }} editItem={modalItem} />
            <AlertContainer floatingTime={10000} />
        </div>

    )
}

export default EditSurveyQuestion