import { PartnerGradeCompareResult } from '../../../../model/response/partner-ranking';
import { Util } from '../../../../api/util';

type Props = {
    grade: string;
    gradeCompareResult: PartnerGradeCompareResult | null;
    createDate: Date | null;
}

const PartnerAnalyzeRankingGrade = (props: Props) => {
    const gradeStyle = {
        color: "#F9B232",
        textAlign: "center" as const,
        fontWeight: "bolder" as const,
        fontSize: "130px",
    };
    
    const GradeDisplay: React.FC<{ grade: string; gradeCompareResult?: PartnerGradeCompareResult | null }> = ({ grade, gradeCompareResult }) => {
        const arrowPosition = {
            position: 'absolute' as const,
                left: '5px', // Place it to the left of the container
                top: '50%', // Center it vertically
                transform: 'translateY(-50%)', // Correct vertical alignment
                width: '30px',
                height: '30px',
        };
    
        if (gradeCompareResult != null) {
            if (gradeCompareResult === PartnerGradeCompareResult.INCREASED) {
                return (
                    <>
                        <svg style={{...arrowPosition}} viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 0L28.7894 24H0.210581L14.5 0Z" fill="#03A439"/>
                        </svg>
                        <div style={{...gradeStyle, position:'relative'}}>{grade}</div>
                    </>
                );
            }  else if (gradeCompareResult === PartnerGradeCompareResult.DECREASED) {
                return (
                    <>
                        <svg style={{...arrowPosition}} viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 24L28.7894 0H0.210581L14.5 24Z" fill="#FF0000"/>
                        </svg>
                        <div style={{...gradeStyle, position:'relative'}}>{grade}</div>
                    </>
                );
            } 
        }
        return (
            <div style={gradeStyle}>{grade}</div>
        );
    };

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', width:"250px", textAlign:"center" }}>
                <div className="light-yellow-button" style={{ padding: "14px 0px 14px 0px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <span>Grade</span>
                </div>
                
                <div className="bg-white text-center" style={{ borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", 
                        borderStyle:'solid', borderWidth:"0 2px 2px", borderColor:"#FFD081", height:'140px', position:'relative',
                        display:'flex', justifyContent:'center', alignItems:'center' }}>
                    {props.grade ? (
                        <>
                            <GradeDisplay
                                    grade={props.grade}
                                    gradeCompareResult={props.gradeCompareResult} />
                        </>)
                        : (<div style={{...gradeStyle, color:"#B9B9B9"}}>N/A</div>)
                    }
                    <span style={{ visibility: props.createDate ? 'visible' : 'hidden', fontSize: "20px", position:'absolute', bottom:'-1px' }}>
                        {props.createDate && Util.datetimetostr(new Date(props.createDate))}
                    </span>
                </div>
            </div>
        </>
    );

}

export default PartnerAnalyzeRankingGrade