import React, { useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from '../../menu';
import loadinglogo from '../../../../img/loading.gif';
import PartnerTab from '../../../tab/partner-tab';
import PartnerActivityTab from '../../../tab/partner-activity-tab';
import PartnerActivityOrganizeTrainingOverview from './PartnerActivityOrganizeTrainingOverview';
import { PartnerActivityTrainingResponse, TrainingInfo } from '../../../../model/response/partner-activity-training';
import { queryclear } from '../../../../store/search/actions';
import PartnerActivityOrganizeTrainingPartner from './PartnerActivityOrganizeTrainingPartner';
import { getInfoTrainingOrganizationPartner, getInfoTrainingOrganizationPublic, getPaticipantActivity } from '../../../../store/partner/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerActivityOrganizeTrainingPublic from './PartnerActivityOrganizeTrainingPublic';
type Props = {}

const PartnerActivityOrganizeTraining = (props: Props) => {
    const [init, setInit] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState<PartnerActivityTrainingResponse>();
    const [itemTrainingInfoPartner, setItemTrainingInfoPartner] = useState<TrainingInfo[]>([]);
    const [pageTrainingInfoPartner, setPageTrainingInfoPartner] = useState<number>(0)
    const [sizeTrainingInfoPartner, setSizeTrainingInfoPartner] = useState<number>(5)
    const [totalElementTrainingInfoPartner, setTotalElwmentTrainingInfoPartner] = useState<number>(0);

    const [itemTrainingInfoPublic, setItemTrainingInfoPublic] = useState<TrainingInfo[]>([]);
    const [pageTrainingInfoPublic, setPageTrainingInfoPublic] = useState<number>(0)
    const [sizeTrainingInfoPublic, setSizeTrainingInfoPublic] = useState<number>(5)
    const [totalElementTrainingInfoPublic, setTotalElwmentTrainingInfoPublic] = useState<number>(0);
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "get_partner_participant") {
                        setItem(res.data);
                        setInit(1);
                    }else if(res.table === "get_info_training_partner"){
                        setItemTrainingInfoPartner(res.data.elements);
                        setTotalElwmentTrainingInfoPartner(res.data.totalElements);
                    }else if(res.table === "get_info_training_public"){
                        setItemTrainingInfoPublic(res.data.elements);
                        setTotalElwmentTrainingInfoPublic(res.data.totalElements)
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                dispatch(queryclear());
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        if (id != "0") {
            dispatch(getPaticipantActivity(parseInt(id)))
            dispatch(getInfoTrainingOrganizationPartner(parseInt(id),pageTrainingInfoPartner,sizeTrainingInfoPartner))
            dispatch(getInfoTrainingOrganizationPublic(parseInt(id),pageTrainingInfoPublic,sizeTrainingInfoPublic))
        } else {
            setInit(1);
        }
        return unsubscribe;
    }, []);
    let { id } = useParams<{ id: string }>();
    const handleProfile = () => {
        history.push("/admin/partner/profile/" + id);
    }
    const handleActivityOrganizeTraining = () => {
        history.push("/admin/partner/activity/" + id + "/organize-training");
    }
    const handleActivityReceiveMedia = () => {
        history.push("/admin/partner/activity/" + id + "/receive-media");
    }
    const handlePackage = () => {
        history.push("/admin/partner/package/" + id);
    }
    const handleAnalyzeRanking = () => {
        history.push("/admin/partner/analyze-ranking/" + id);
    }
    const onPageTrainingInfoPartnerChange = (value: number) => {
        setPageTrainingInfoPartner(value);
        dispatch(getInfoTrainingOrganizationPartner(parseInt(id),value,sizeTrainingInfoPartner));
    }
    const onPageTrainingInfoPartnerSizeChange = (value: number) => {
        setPageTrainingInfoPartner(0);
        setSizeTrainingInfoPartner(value);
        dispatch(getInfoTrainingOrganizationPartner(parseInt(id), 0, value));
    }

    const onPageTrainingInfoPublicChange = (value: number) => {
        setPageTrainingInfoPublic(value);
        dispatch(getInfoTrainingOrganizationPublic(parseInt(id),value,sizeTrainingInfoPublic));
    }
    const onPageTrainingInfoPublicSizeChange = (value: number) => {
        setPageTrainingInfoPublic(0);
        setSizeTrainingInfoPublic(value);
        dispatch(getInfoTrainingOrganizationPublic(parseInt(id), 0, value));
    }
    const handleBack = () => {
        history.push('/admin/search/partner')
    }
    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">ข้อมูลองค์กร</h4></div>
                        {(init === 1) &&
                            <>
                                <br />
                                {PartnerTab(2, true, handleProfile, handleActivityOrganizeTraining, handlePackage, handleAnalyzeRanking)}
                                <div className='my-2'>
                                    {PartnerActivityTab(1, true, handleActivityOrganizeTraining, handleActivityReceiveMedia)}
                                </div>
                                <PartnerActivityOrganizeTrainingOverview item={item} />
                                <PartnerActivityOrganizeTrainingPartner items={itemTrainingInfoPartner}
                                    page={pageTrainingInfoPartner}
                                    size={sizeTrainingInfoPartner}
                                    totalElement={totalElementTrainingInfoPartner}
                                    loading={loading}
                                    setItems={setItemTrainingInfoPartner}
                                    onPageChange={onPageTrainingInfoPartnerChange}
                                    onPageSizeChange={onPageTrainingInfoPartnerSizeChange}
                                />
                                <PartnerActivityOrganizeTrainingPublic items={itemTrainingInfoPublic}
                                    page={pageTrainingInfoPublic}
                                    size={sizeTrainingInfoPublic}
                                    totalElement={totalElementTrainingInfoPublic}
                                    loading={loading}
                                    setItems={setItemTrainingInfoPublic}
                                    onPageChange={onPageTrainingInfoPublicChange}
                                    onPageSizeChange={onPageTrainingInfoPublicSizeChange}
                                />
                                <br />
                            </>
                        }
                        <div className='d-flex mt-3'>
                            <div className='ml-auto mb-3'>
                                <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                    onClick={() => handleBack()} >
                                    Back
                                </button>
                            </div>
                        </div>
                        {(((init < 1) || (loading)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                </div>
                <AlertContainer floatingTime={3000} />
            </MainMenu>
        </div>
    )
}

export default PartnerActivityOrganizeTraining