import { useHistory } from "react-router-dom";
import { useState } from "react";
import MainMenu from "../../../menu";
import PartnerRankingTab from "../../../../tab/partner-ranking-tab";
import PartnerRemarketingTarget from "./PartnerRemarketingTarget";
import PartnerRemarketingHistory from "./PartnerRemarketingHistory";


type Props = {}
const PartnerRemarketingMain = (props: Props) => {

    const tabStyle = { paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", minWidth: "250px" };
    const tabPointerStyle = { cursor: "pointer", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px", minWidth: "250px" };


    const history = useHistory();
    const [activeTab, setActiveTab] = useState("tab1");


    const handleRanking = () => {
        history.push("/admin/search/partner-ranking");
    }

    const handleRemarketing = () => {
        history.push("/admin/search/partner-remarketing");
    }


    return (
        <div>
            <MainMenu>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">Partner Ranking & Remarketing</h4></div>

                    <br />
                    {PartnerRankingTab(2, handleRanking, handleRemarketing)}
                    <div className='my-2'>
                        <div className="row" style={{ justifyContent: "left", paddingLeft: "40px" }}>
                            <div className={(activeTab === "tab1") ? "blue-tab-active" : "blue-tab-inactive"} style={activeTab === "tab1" ? tabStyle : tabPointerStyle} onClick={() => setActiveTab("tab1")}>
                                <b>ข้อมูลกลุ่มเป้าหมาย</b>
                            </div>&nbsp;&nbsp;
                            <div className={(activeTab === "tab2") ? "blue-tab-active" : "blue-tab-inactive"} style={activeTab === "tab2" ? tabStyle : tabPointerStyle} onClick={() => setActiveTab("tab2")}>
                                <b>ประวัติการทำ Remarketing</b>
                            </div>&nbsp;

                        </div>
                    </div>

                    {activeTab === "tab1" && < PartnerRemarketingTarget />}
                    {activeTab === "tab2" && < PartnerRemarketingHistory />}


                </div>
            </MainMenu>
        </div>
    )

}

export default PartnerRemarketingMain