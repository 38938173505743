import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { SingleSearch } from "../../../../tab/search-component";
import { SingleValue } from "react-select";
import { PartnerRankingBody, PartnerRankingYear } from "../../../../../model/response/partner-ranking";
import { SearchPanel, TableHeaderBlack } from "../../../../../style/tableStyle";
import { Button, InputGroup, Modal, ModalProps } from "react-bootstrap";
import { ThaiDateTimePicker } from "../../../../tab/thai-datetime-picker";
import CancelIcon from '@material-ui/icons/Cancel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { MultiSelectComponent } from "../../../../tab/multi-select-component";
import loadinglogo from '../../../../../img/loading.gif';
import { CustomPage } from "../../../../share/CustomPage";
import excellogo from '../../../../../img/excel.png';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Util } from "../../../../../api/util";
import EditIcon from '@material-ui/icons/Edit';
import StatisticsIcon from '@material-ui/icons/SignalCellularAlt';
import RemarketingHistoryImportModal from "./RemarketingHistoryImportModal";
import { AlertContainer } from "react-custom-alert";
import { useDispatch, useSelector, useStore } from "react-redux";
import { PartnerRemarketing, PartnerRemarketingOverview, PartnerRemarketingSender } from "../../../../../model/response/partner-remarketing";
import { queryclear } from "../../../../../store/search/actions";
import { deleteRemargetingInformation, getHistoryPartnerRemarketingOverview, getPartnerRemarketingListSender, partnerRemarketingHistoryPagination } from "../../../../../store/partner/actions";
import React from "react";
import { alert } from 'react-custom-alert';
import { set } from "date-fns";
import { EXPORT_HISTORY_PARTNER_REMARKETING, PartnerAPI } from "../../../../../api/partner-api";
import { getAccessToken } from "../../../../../store/auth/selectors";
import Sort from "../../../../share/Sort";

type Props = {}

const PartnerRemarketingHistory = (props: Props) => {

    const containerStyle: React.CSSProperties = {
        display: "flex",
        justifyContent: "space-between",
        gap: "150px",
        padding: "20px",
    };

    const columnStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        flex: 1,
    };

    const rowStyle: React.CSSProperties = {
        display: "flex",
        justifyContent: "space-between",
    };

    const valueStyle: React.CSSProperties = {
        fontSize: "20px",
        color: "#607D8B",
    };

    const valuePerStyle: React.CSSProperties = {
        fontWeight: "bold",
        color: "#607D8B",
    };

    const noDataStyle: React.CSSProperties = {
        color: "#CCC",
        textAlign: "center",
        padding: "50px",
    };

    const token = useSelector(getAccessToken);
    const store = useStore();
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const [expandBoard, setExpandBoard] = useState(true)
    const [expandData, setExpandData] = useState(true)
    const [loading, setLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [init, setInit] = useState(0)
    const [updating, setUpdating] = useState(false)
    const [upload, setUpload] = useState(false);


    const [listObjectYear, setListOverview] = useState<Array<PartnerRemarketingOverview>>([]);
    const [objectYear, setObjectYear] = useState<PartnerRemarketingOverview>()
    const [selectedYear, setSelectedYear] = useState<{ label: string; value: number } | null>(null);
    const [noDataBoard, setNoDataBoard] = useState(false);

    const [listPartnerSent, setListPartnerSent] = useState<{ label: string; value: number }[]>([]);

    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const [subject, setSubject] = useState<string>();
    const [partnerSent, setPartnerSent] = useState<number[]>([]);

    const [items, setItems] = useState<PartnerRemarketing[]>([]);
    const [modalItem, setModalItem] = useState<PartnerRemarketing>();
    const [page, setPage] = useState(0);
    const [size, setSize] = useState<number>(10);
    const [totalElement, setTotalElement] = useState<number>(0);

    const [sortBy, setSortBy] = useState<string | null>('deliveryDate'); // Default sort by deliveryDate
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc'); // Default sort type is descending

    const [deleteId, setDeleteId] = useState<number>();

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);


    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            // console.log(res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_history_partner_remarketing") {
                        setItems(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setInit(1);
                    }
                    else if (res.table === "list_histtory_partner_remarketing_overview") {
                        const options = res.data.map((item: PartnerRemarketingOverview) => ({
                            label: item.year.toString(),
                            value: item.year,
                        }));
                        setListOverview(res.data);
                        setObjectYear(res.data[0]);
                        setSelectedYear(options[0]);
                    }
                    else if (res.table === "list_sender_partner_remarketing") {
                        const options = res.data.map((item: PartnerRemarketingSender) => ({
                            value: item.userId,
                            label: item.name
                        }));
                        setListPartnerSent(options);
                    }

                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                if (res.table === "save_remarketing_information") {
                    alert({ message: 'Saved', type: 'success' });
                    hideImportModal();
                    handleClear();
                }
                else if (res.table === "delete_remarketing_information") {
                    alert({ message: 'Deleted', type: 'success' });
                    handleDeleteClose();
                    handleClear();
                }
                setError('');
                setUpdating(false);
                setUpload(false);
                dispatch(queryclear());
            } else if (res.isFailed) {
                setError(res.data.message);
                setUpload(false);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }
        })
        return unsubscribe;
    }, [])

    useEffect(() => {
        dispatch(partnerRemarketingHistoryPagination(fromDate || undefined, toDate || undefined, subject, partnerSent, page, size, sortBy + ',' + sortType));
        dispatch(getHistoryPartnerRemarketingOverview());
        dispatch(getPartnerRemarketingListSender());
    }, []);

    useEffect(() => {
        dispatch(partnerRemarketingHistoryPagination(fromDate || undefined, toDate || undefined, subject, partnerSent, page, size, sortBy + ',' + sortType));
    }, [page, size]);

    const handleChangeYear = (year: any) => {
        if (year) {
            const selectedYear = listObjectYear.find((item) => item.year === year) || null;
            if (selectedYear) {
                setObjectYear(selectedYear || undefined);
                setNoDataBoard(false);
            } else {
                setObjectYear(undefined);
                setNoDataBoard(true);
            }
        }
    };

    const handleFromDate = (e: Date | null) => {
        setFromDate(e);
    }

    const handleToDate = (e: Date | null) => {
        setToDate(e);
    }

    const handleClear = () => {
        setPage(0);
        setFromDate(null);
        setToDate(null);
        setSubject("");
        setPartnerSent([]);
        dispatch(partnerRemarketingHistoryPagination(undefined, undefined, undefined, undefined, page, size, sortBy + ',' + sortType));
        dispatch(getHistoryPartnerRemarketingOverview());
        dispatch(getPartnerRemarketingListSender());
    }

    const handleSubmit = () => {
        dispatch(queryclear());
        dispatch(partnerRemarketingHistoryPagination(fromDate || undefined, toDate || undefined, subject, partnerSent, page, size, sortBy + ',' + sortType));
    }

    const customHeader = () => {
        return <div onClick={() => openImportModal()} style={{ fontWeight: 600, fontSize: 30 }}>
            <AddCircleOutlineIcon className="yellow mr-2" style={{ cursor: 'pointer', fontSize: 35 }} />
            เพิ่มข้อมูล
        </div>;
    }

    const openImportModal = () => {
        setShowAddModal(true)
    }
    const hideImportModal = () => {
        setModalItem(undefined);
        setShowAddModal(false)
    }

    const onPageChange = (value: number) => {
        setPage(value);
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);
    }

    const handleEdit = (item: PartnerRemarketing) => {
        setModalItem(item);
        setShowAddModal(true);
    }

    const handleDelete = (id: number) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const handleDeleteClose = () => {
        setShowDeleteModal(false);
    }

    const handleDeleteConfirm = () => {
        dispatch(queryclear());
        dispatch(deleteRemargetingInformation(deleteId as number));
    }

    const excelElement: any = (showOnly: boolean) => {
        if (showOnly) {
            return <Button type="button" className="btn btn-success green-button ml-1" style={{ cursor: 'default', pointerEvents: 'none' }} >
                <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
            </Button>
        } else {
            return <Button type="button" className="btn btn-success green-button ml-1" onClick={() => handleExcel()} >
                <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
            </Button>
        }
    }

    const handleExcel = () => {
        window.open(EXPORT_HISTORY_PARTNER_REMARKETING + "?" + PartnerAPI.getParamPartnerRemarketingHistory(fromDate || undefined, toDate || undefined, subject, partnerSent, undefined, undefined) + "&token=" + encodeURIComponent(token));
    }

    const options = Array.from(
        new Map(
            items
                .filter((item) => item && item.createBy && item.createById) // Ensure valid items
                .map((item: PartnerRemarketing) => [
                    item.createById,
                    { label: item.createBy, value: item.createById },
                ])
        ).values()
    );

    const createYearFilter = (yearDropdown: PartnerRemarketingOverview[] | null) => {
        const res: any[] = [];
        const currentYear = new Date().getFullYear();

        if (yearDropdown && yearDropdown.length > 0) {
            // Extract years from the data and sort them in descending order
            const years = yearDropdown.map(item => item.year).sort((a, b) => b - a);

            const maxYear = Math.max(...years);
            const minYear = Math.min(...years);

            // Generate a complete range of years
            for (let year = maxYear; year >= minYear; year--) {
                res.push({ label: year, value: year });
            }
        } else {
            // Add the current year if the list is empty
            res.push({ label: currentYear, value: currentYear });
        }

        return res;
    };

    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        dispatch(queryclear());
        dispatch(partnerRemarketingHistoryPagination(fromDate || undefined, toDate || undefined, subject, partnerSent, page, size, sortName + ',' + newSortType));
    }


    return (
        <React.Fragment>
            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expandBoard === undefined) || (expandBoard === false)) &&
                            <div onClick={(e) => setExpandBoard(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>ภาพรวมการทำ Remarketing</div>
                        }
                        {(expandBoard === true) &&
                            <div onClick={(e) => setExpandBoard(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>ภาพรวมการทำ Remarketing</div>
                        }
                    </div>

                    {expandBoard && (<>
                        <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                            <div style={{
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                                padding: "20px",
                            }}>
                                <div style={{ alignItems: 'center', display: 'flex' }}>จำนวนรายการในการทำ Remarketing ปี
                                    <span className="col-2">
                                        <SingleSearch
                                            isSearchable={false}
                                            isMulti={false}
                                            isClearable={false}
                                            value={selectedYear}
                                            options={createYearFilter(listObjectYear)}
                                            onChange={(id: string, e: any) => {
                                                setSelectedYear(e);
                                                handleChangeYear(e.value);
                                            }}
                                        />
                                    </span>
                                    <StatisticsIcon className="yellow pointer" onClick={() => { }} />
                                    <span style={{
                                        paddingLeft: '5px',
                                        color: '#FCB134',
                                        textDecoration: isHovered ? 'underline' : 'none',
                                        cursor: 'pointer',
                                    }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}> view statistic</span>
                                </div>

                                {!noDataBoard && (
                                    <div style={containerStyle}>
                                        <div style={columnStyle}>
                                            <div style={rowStyle}>
                                                <span >จำนวนที่ส่ง</span>
                                                <span style={valuePerStyle}>{objectYear?.quantitySentSum}</span>
                                            </div>
                                            <div style={rowStyle}>
                                                <span >Delivery Rate</span>
                                                <span style={valuePerStyle}>
                                                    {objectYear?.deliveryRatePerSum.toFixed(2)}% <span style={valueStyle}>({objectYear?.deliveryRateSum})</span>
                                                </span>
                                            </div>
                                            <div style={rowStyle}>
                                                <span >Bounce Rate</span>
                                                <span style={valuePerStyle}>
                                                    {objectYear?.bounceRatePerSum.toFixed(2)}% <span style={valueStyle}>({objectYear?.bounceRateSum})</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div style={columnStyle}>
                                            <div style={rowStyle}>
                                                <span >Open Rate</span>
                                                <span style={valuePerStyle}>
                                                    {objectYear?.openRatePerSum.toFixed(2)}% <span style={valueStyle}>({objectYear?.openRateSum})</span>
                                                </span>
                                            </div>
                                            <div style={rowStyle}>
                                                <span >Click Rate</span>
                                                <span style={valuePerStyle}>
                                                    {objectYear?.clickRatePerSum.toFixed(2)}% <span style={valueStyle}>({objectYear?.clickRateSum})</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                {noDataBoard && (
                                    <div style={noDataStyle}>
                                        “ไม่พบข้อมูลการทำ Remarketing”
                                    </div>
                                )}
                            </div>
                        </div>
                    </>)}
                </div>
            </div>

            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expandData === undefined) || (expandData === false)) &&
                            <div onClick={(e) => setExpandData(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>ข้อมูลการทำ Remarketing</div>
                        }
                        {(expandData === true) &&
                            <div onClick={(e) => setExpandData(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>ข้อมูลการทำ Remarketing</div>
                        }
                    </div>

                    {expandData && (init === 1) && (<>
                        <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>

                            <SearchPanel>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="col-form-label">วันที่ส่ง</label>
                                        <div className="row" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <InputGroup className="col-5">
                                                <label className='position-relative' style={{ width: "100%" }}>
                                                    <ThaiDateTimePicker
                                                        className="form-control"
                                                        selected={fromDate}
                                                        onChange={(fromDate: any) => handleFromDate(fromDate)}
                                                    />
                                                    <div
                                                        className="search-icon"
                                                        style={{ position: "absolute", right: "10px", top: "5px", zIndex: 1 }}
                                                    >
                                                        <CancelIcon
                                                            style={{ visibility: (fromDate == null) ? "hidden" : "visible", color: "gray" }}
                                                            onClick={(e) => { setFromDate(null); }}
                                                        />
                                                        <CalendarTodayIcon style={{ color: "gray" }} />
                                                    </div>
                                                </label>
                                            </InputGroup>
                                            <InputGroup className="col-5">
                                                <label className='position-relative' style={{ width: "100%" }}>
                                                    <ThaiDateTimePicker
                                                        locale="th"
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={toDate}
                                                        clear={() => setToDate(null)}
                                                        onChange={(toDate: any) => handleToDate(toDate)}
                                                    />
                                                    <div
                                                        className="search-icon"
                                                        style={{ position: "absolute", right: "10px", top: "5px", zIndex: 1 }}
                                                    >
                                                        <CancelIcon
                                                            style={{ visibility: (toDate == null) ? "hidden" : "visible", color: "gray" }}
                                                            onClick={(e) => setToDate(null)}
                                                        />
                                                        <CalendarTodayIcon style={{ color: "gray" }} />
                                                    </div>
                                                </label>
                                            </InputGroup>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label className="col-form-label">หัวข้อเรื่อง</label>
                                        <input className="form-control" value={subject} onChange={(e) => { setSubject(e.currentTarget.value) }} />
                                    </div>

                                    <div className="col-5" style={{ paddingRight: '0px' }}>
                                        <label className="col-form-label">ผู้ส่ง</label>
                                        <MultiSelectComponent
                                            options={listPartnerSent}
                                            value={listPartnerSent.filter((option) => partnerSent.includes(option.value))} // Map selected values
                                            onChange={(selectedOptions: { label: string; value: number }[]) =>
                                                setPartnerSent(selectedOptions.map((option) => option.value))
                                            }
                                        />

                                    </div>
                                </div>

                                <br />
                                <div className="row" style={{ justifyContent: "center" }}>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                        onClick={() => handleClear()} >
                                        Clear
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                        onClick={() => handleSubmit()} disabled={loading}>
                                        <div>
                                            {(loading) ?
                                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                : <>Search</>}
                                        </div>
                                    </button>
                                </div>
                            </SearchPanel>
                        </div>

                        <br />
                        <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={excelElement(false)} customHeader={customHeader()}>
                            <TableHeaderBlack striped hover className='mt-2'>
                                <thead>
                                    <tr>
                                        <th style={{ alignContent: 'center' }}>
                                            <Sort label='วันที่ส่ง' currentSort={sortBy} sortName='deliveryDate' handleClick={onSort} sortType={sortType} /></th>
                                        <th style={{ alignContent: 'center' }}>ผู้ส่ง</th>
                                        <th style={{ alignContent: 'center' }}>หัวข้อเรื่อง</th>
                                        <th style={{ alignContent: 'center' }}>ประเภทกลุ่มเป้าหมาย</th>
                                        <th style={{ alignContent: 'center' }}>จำนวนที่ส่ง</th>
                                        <th style={{ alignContent: 'center' }} >
                                            Delivery Rate (%)<br />Bounce Rate (%)
                                        </th>
                                        <th style={{ alignContent: 'center' }}>
                                            Open Rate (%)<br />Click Rate (%)
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(items || []).map((item: PartnerRemarketing, index: number) => (
                                        <tr key={'answer_' + item.id}>
                                            <td className='text-center'>{Util.datetostr(new Date(item.deliveryDate))}</td>
                                            <td className='text-left'> {item.createBy}</td>
                                            <td className='text-left'> {item.subject} </td>
                                            <td className='text-left'> {item.targetGroup} </td>
                                            <td className='text-center'> {item.quantitySent} </td>
                                            <td className='text-center'> {item.deliveryRatePer} <br />{item.bounceRatePer} </td>
                                            <td className='text-center'> {item.openRatePer} <br /> {item.clickRatePer} </td>
                                            <td>
                                                <div className='row'>
                                                    <div onClick={(e) => handleEdit(item)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;
                                                    <div onClick={(e) => handleDelete(item.id)}><CancelIcon className="red pointer"></CancelIcon></div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                    {!items || items?.length == 0 && (
                                        <tr>
                                            <td colSpan={10}>ไม่มีรายการ</td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableHeaderBlack>
                        </CustomPage>
                    </>)}

                    {(((init < 1) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }

                </div>
            </div>

            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <RemarketingHistoryImportModal
                show={showAddModal} onHide={() => hideImportModal()} item={modalItem}
            />
            <AlertContainer floatingTime={3000} />
        </React.Fragment >
    );


}

export default PartnerRemarketingHistory;