import React, { useEffect, useRef, useState } from 'react'
import { Button, InputGroup, Modal, ModalProps, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { importPartnerRanking } from '../../../../store/partner/actions';
import { PartnerRankingImport } from '../../../../model/response/partner-ranking';
import { MAX_FILE_UPLOAD } from '../../../../api/conf';
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from '../../../../api/label';
import { queryclear } from '../../../../store/search/actions';
import { Util } from '../../../../api/util';
import { createPage } from '../../../tab/pagetab';
type Props = ModalProps & {
    excelelement: React.ReactElement;
    item?: PartnerRankingImport;
    showFailureTable: boolean;
    isReadyToSave: boolean;
}

const RankingRankingImportModal = (props: Props) => {
    const dispatch = useDispatch();
    const [errorUpload, setErrorUpload] = useState("");
    const ref = useRef<any>();
    const [currentFile, setCurrentFile] = useState<File>();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        setCurrentFile(undefined);
        setErrorUpload('');
    }, [props.show])
    

    const handleFileChange = (selectorFile: File | null) => {
        if (selectorFile) {
            setCurrentFile(selectorFile);
        }
    }

    const validateFile = (): boolean => {
        setErrorUpload('');
        if (currentFile) {
            let file_size = currentFile.size;
            if (file_size > MAX_FILE_UPLOAD * 1000000) {
                setErrorUpload(MAX_UPLOAD_FILE_SIZE_LABEL);
                return false;
            }
        } else {
            setErrorUpload(REQUIRE_LABEL);
            return false;
        }
        return true;
    }

    const handleUpload = () => {
        var res = validateFile();
        if (res && currentFile) {
            setCurrentPage(1);
            dispatch(queryclear());
            dispatch(importPartnerRanking(currentFile, "false"));
        }
    }

    const handleSaveFile = () => {
        if (currentFile) {
            dispatch(importPartnerRanking(currentFile, "true"));
        }
    }


    const handleItemPerPage = (l: number) => {
        setCurrentPage(1);
        setItemsPerPage(l);
    }

    const getAllErrorData = () => {
        if (!props.item || !props.item.importList) return [];
        return props.item.importList.filter((item: any) => !item.validateSucceed);
    }

    const getTotalItem = () => {
        return getAllErrorData().length;
    }

    const getPaginatedData = () => {
        const data = getAllErrorData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const getTotalPages = () => {
        return Math.ceil(getAllErrorData().length / itemsPerPage);
    }

    const handlePageChange = (page: number) => {
        if (page >= 0 && page < getTotalPages()) {
            setCurrentPage(page+1);
        }
    };

    return (
        <Modal
                {...props}
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Import ข้อมูล Partner Ranking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className='row'>
                            <div className='col-9'>
                                <div className="col-12 mt-2">1. Download Template {props.excelelement}</div>
                                <div className="col-12 mt-2" style={{display:'flex', flexDirection:'row'}}>2. Import File
                                    <div className="col-6" style={{ textAlign: "left" }}>
                                        <InputGroup hasValidation>
                                            <input type="file" className={"form-control " + ((errorUpload !== "") ? "is-invalid" : "")} accept=".xlsx" 
                                                id="contained-button-file" ref={ref} onChange={(e: any) => handleFileChange(e.target.files[0])}  />
                                        </InputGroup>
                                        <div className={(errorUpload !== "") ? "text-danger" : ""}><small>{errorUpload}</small></div>
                                    </div>
                                    <div className="col-4" onClick={(e) => { handleUpload() }}>
                                        <Button className="btn btn-primary" style={{ width: "120px" }} > Upload </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(props.showFailureTable) && 
                            <div style={{ background: "white", paddingTop: "40px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                    <div className="col-6" style={{ textAlign: "left" }}>
                                        จำนวนข้อมูลทั้งหมด : {props.item ? Util.integerWithCommas(props.item.totalElements) : 0} <br />
                                        <span className='green'>จำนวนข้อมูลที่สามารถนำเข้าได้ : {props.item ? Util.integerWithCommas(props.item.passedElements) : 0} </span><br />
                                        <span className='red'>จำนวนข้อมูลที่ไม่สามารถนำเข้าได้ : {props.item ? Util.integerWithCommas(props.item.failedElements) : 0} </span><br />
                                    </div>
                                    
                                    <div className="col-6" style={{ textAlign: "right", display: "flex", justifyContent: "flex-end" }}>
                                        <div style={{ textAlign: "right", marginTop: "auto" }}>จำนวนรายการต่อหน้า</div>&emsp;
                                        <select className="form-control" style={{ width: "80px" }} name="page" value={itemsPerPage} onChange={e => handleItemPerPage(parseInt(e.target.value))}>
                                            <option value={getTotalItem()}>All</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className='col-12 mt-2'>
                                    <Table striped hover style={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                                        <thead className="black-button">
                                            <tr>
                                                <th style={{ verticalAlign: "top" }}>Excel Line</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>Partner ID</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '23px' }}>
                                            {props.item && props.item.validateSucceed ? (
                                                <tr style={{ justifyContent: 'center', textAlign: 'center' }}>
                                                    <td colSpan={3}>ไม่พบรายการที่มีปัญหา</td>
                                                </tr>
                                            ) : (
                                                getPaginatedData().map((imporValue: any, index: number) => (
                                                    <tr key={index} style={{ justifyContent: 'center', textAlign: 'center' }}>
                                                        <td className="text-left">{imporValue.excelLine}</td>
                                                        <td className="text-left">{imporValue.partnerCode}</td>
                                                        <td className="text-left">{imporValue.remark}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                    
                                    {/* Pagination Controls */}
                                    <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                        {createPage(currentPage-1, getTotalPages(), handlePageChange)}
                                        <br />
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    </>
                </Modal.Body>

                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button className="btn btn-success" onClick={handleSaveFile} disabled={!props.isReadyToSave}> Save </Button>
                </Modal.Footer>
            </Modal>
    )
}

export default RankingRankingImportModal