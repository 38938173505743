import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { PartnerRankingAnalyzeHistory } from '../../../../model/response/partner-ranking';
import { Table } from 'react-bootstrap';
import { Util } from '../../../../api/util';
import iconNote from '../../../../img/iconNote.png'

type Props = {
    historyList: PartnerRankingAnalyzeHistory[];
    openHistoryModal: (answerId: number) => void;
}

const PartnerAnalyzeRankingHistory = (props: Props) => {
    const [expand, setExpand] = useState(false);
    const openModal = (answerId: number) => {
        props.openHistoryModal(answerId);
    }


    return (
        <div>
            <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {((expand === undefined) || (expand === false)) &&
                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>ประวัติการประเมิน</div>
                }
                {(expand === true) &&
                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>ประวัติการประเมิน</div>
                }
            </div>
            {expand && (
                <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                    {((props.historyList !== null) && (props.historyList.length >= 1)) && <>
                        <Table striped hover>
                            <thead className="black-button">
                                <tr>
                                    <th className='text-left' style={{ width: '20%' }}>วันที่ประเมิน</th>
                                    <th className='text-left' style={{ width: '30%' }}>ผู้ประเมิน</th>
                                    <th className='text-left' style={{ width: '30%' }}>Grade</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: "22px" }}>
                                {props.historyList.map((item, index) => (
                                    <React.Fragment key={'partner_package-' + index}>
                                        <tr>
                                            <td className='text-left'>{Util.datetimetostr(new Date(item.createDate))}</td>
                                            <td className='text-left'>{item.createBy}</td>
                                            <td className='text-left'>{item.grade}</td>
                                            <td>
                                                <img src={iconNote} 
                                                    onClick={(e) => openModal(item.answerId)} 
                                                    width={20} height={20} 
                                                    id={"icon_note_calculators" + index} 
                                                    style={{ cursor: "pointer" }} 
                                                    className='mr-2' />
                                            </td>
                                        </tr>
                                    </React.Fragment>))}
                                    
                            </tbody>
                        </Table>
                    </>}
                    {(!props.historyList || props.historyList.length == 0) && <span>ไม่พบประวัติการประเมิน</span> }
                </div>
            )}

        </div>
    )
}

export default PartnerAnalyzeRankingHistory