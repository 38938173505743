import { useDispatch, useSelector } from 'react-redux';
import { getCollapsed } from '../../store/menu/selectors';
import { setCollapse } from '../../store/menu/actions';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SubMenu } from 'react-pro-sidebar';
import logoa from '../../img/logoa.png';
import logoSetLearnScape from "../../img/Logo-SET-Learn-Scape.png"
import 'react-pro-sidebar/dist/css/styles.css';
import '../../Menu.scss';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useHistory } from 'react-router-dom';
import { getUsername } from '../../store/auth/selectors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const MenuSub = styled.div<{ $visible?: boolean }>`
  .title{
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    &:hover{
      color: #696969;
    }
    .title-text{
      font-family: "DB Helvethaica Bold";
      font-size: 20px;
      color: #955d03;
    }
  }
  div.menu{
    display:${props => (props.$visible ? `block` : `none`)} ;
    
  }
  
`

const MainMenu = (prop: any) => {
  const collapsed = useSelector(getCollapsed);
  const username = useSelector(getUsername);
  const history = useHistory();
  const [openMenuGeneral, setOpenMenuGeneral] = useState(true);
  const [openMenuConfig, setOpenMenuConfig] = useState(true);
  const dispatch = useDispatch();

  const isClass = () => {
    return (window.location.pathname === "/admin/search/class") || (window.location.pathname.includes("/admin/class"));
  };
  const isPartner = () => {
    return (window.location.pathname === "/admin/search/partner") || (window.location.pathname.includes("/admin/partner"));
  };
  const isPartnerRanking = () => {
    return (window.location.pathname === "/admin/search/partner-ranking") || (window.location.pathname.includes("/admin/partner-ranking"));
  };
  const isPartnerRemarketing = () => {
    return (window.location.pathname === "/admin/search/partner-remarketing") || (window.location.pathname.includes("/admin/partner-remarketing"));
  };
  const isTrainer = () => {
    return (window.location.pathname === "/admin/search/trainer") || (window.location.pathname.includes("/admin/trainer"));
  };
  const isSurvey = () => {
    return (window.location.pathname === "/admin/search/survey") || (window.location.pathname.includes("/admin/survey"));
  };
  const isProject = () => {
    return (window.location.pathname === "/admin/project-group/info") || (window.location.pathname.includes("/admin/project"));
  };
  const isAnnounce = () => {
    return (window.location.pathname === "/admin/announce/info") || (window.location.pathname.includes("/admin/announce/info"));
  };
  const isMedia = () => {
    return (window.location.pathname === "/admin/manage-knowledge") || (window.location.pathname.includes("/admin/manage-knowledge"))
  }
  const isLogRequest = () => {
    return (window.location.pathname === "/admin/log-request") || (window.location.pathname.includes("/admin/log-request"))
  }
  return (
    <>
      <ProSidebar collapsed={collapsed} style={{ position: "fixed", borderRadius: "20px" }}>
        <SidebarHeader style={{ backgroundColor: "#525252" }}>
          <br />
          <div className='mb-2' style={{ textAlign: "center" }} onClick={(e) => { dispatch(setCollapse(!collapsed)) }}>
            {collapsed && <>
              <div className='mt-2'></div>
              <img src={logoa} alt="Logo" height="30px" />
            </>}
            {!collapsed && <img src={logoSetLearnScape} alt="Logo" height="90px" />}
            <div className='text-white' style={{ visibility: (collapsed) ? "hidden" : "visible", height: (collapsed) ? "0px" : "",fontSize:16 }}>{username} <span style={{ fontSize: 16, color: "white" }}>(Admin)</span></div>
            
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuSub $visible={openMenuGeneral} title="General" className='sub-menu'>
              <div onClick={() => setOpenMenuGeneral(!openMenuGeneral)}><span className='ml-2 title'>{openMenuGeneral ? <ExpandMoreIcon /> : <ExpandLessIcon />}<span className='title-text'>General</span></span> </div>
              <hr style={{ marginTop: "10px" }} />
              <div className='menu'>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isClass()) ? "black" : "", color: (isClass()) ? "gray" : "black" }} onClick={e => { if (!isClass()) { history.push("/admin/search/class") } }}>
                  <div className='d-flex'>
                    <div><AppsIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการรอบการเรียน</div>}
                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isPartner()) ? "black" : "", color: (isPartner()) ? "gray" : "black" }} onClick={e => { history.push("/admin/search/partner") }}>
                  <div className='d-flex'>
                    <div><AccountBalanceIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการองค์กรพันธมิตร</div>}
                  </div>
                </MenuItem>
                
                <div style={{ fontFamily: "DB Helvethaica", fontSize:"20px",  marginLeft: (collapsed) ? "" : "50px"}}>
                  {!collapsed && <div className='d-flex flex-column' style={{ width:'93%' }}>
                      <div style={{ paddingLeft:"20px", cursor: "pointer", borderRadius: (collapsed) ? "" : "10px", backgroundColor: (isPartnerRanking()) ? "black" : "", color: (isPartnerRanking()) ? "gray" : "#053E96" }} onClick={e => { history.push("/admin/search/partner-ranking") }}>
                        &bull;&ensp;การจัดอันดับองค์กร
                      </div>
                      <div style={{ paddingLeft:"20px", cursor: "pointer", borderRadius: (collapsed) ? "" : "10px", backgroundColor: (isPartnerRemarketing()) ? "black" : "", color: (isPartnerRemarketing()) ? "gray" : "#053E96" }} onClick={e => { history.push("/admin/search/partner-remarketing") }}>
                        &bull;&ensp;การจัดทำ Remarketing
                      </div>
                  </div>}
                </div>

                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isTrainer()) ? "black" : "", color: (isTrainer()) ? "gray" : "black" }} onClick={e => { if (!isTrainer()) { history.push("/admin/search/trainer") } }} >
                  <div className='d-flex'>
                    <div><FolderSharedIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการวิทยากร</div>}

                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isSurvey()) ? "black" : "", color: (isSurvey()) ? "gray" : "black" }} onClick={e => { if (!isSurvey()) { history.push("/admin/search/survey") } }} >
                  <div className='d-flex'>
                    <div><AssignmentIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการแบบประเมินผล</div>}

                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px" }} >
                  <div className='d-flex'>
                    <div><LibraryAddIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>รายงาน / Dashboard</div>}

                  </div>
                </MenuItem>
              </div>

            </MenuSub>
            <MenuSub $visible={openMenuConfig} title="Configuration" className='sub-menu' >
              <div onClick={() => setOpenMenuConfig(!openMenuConfig)}><span className='ml-2 title'>{openMenuConfig ? <ExpandMoreIcon /> : <ExpandLessIcon />}<span className='title-text'>Configuration</span></span> </div>
              <hr style={{ marginTop: "10px" }} />
              <div className='menu'>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isProject()) ? "black" : "", color: (isProject()) ? "gray" : "black" }} onClick={e => { if (!isProject()) { history.push("/admin/project-group/info") } }} >
                  <div className='d-flex'>
                    <div><FindInPageIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการข้อมูลรายโครงการ</div>}

                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isAnnounce()) ? "black" : "", color: (isAnnounce()) ? "gray" : "black" }} onClick={e => { if (!isAnnounce()) { history.push("/admin/announce/info") } }} >
                  <div className='d-flex'>
                    <div><FindInPageIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการข้อมูลประชาสัมพันธ์</div>}

                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isMedia()) ? "black" : "", color: (isMedia()) ? "gray" : "black" }} onClick={e => { if (!isMedia()) { history.push("/admin/manage-knowledge") } }} >
                  <div className='d-flex'>
                    <div><FindInPageIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการสื่อความรู้</div>}

                  </div>
                </MenuItem>
                <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isLogRequest()) ? "black" : "", color: (isLogRequest()) ? "gray" : "black" }} onClick={e => { if (!isLogRequest()) { history.push("/admin/log-request") } }} >
                  <div className='d-flex'>
                    <div><FindInPageIcon /></div>&nbsp;
                    {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>การจัดการ Log</div>}

                  </div>
                </MenuItem>
              </div>
            </MenuSub>
          </Menu>
        </SidebarContent>
        <div style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer", color: "white" }} onClick={(e) => { dispatch(setCollapse(!collapsed)) }}><MenuIcon></MenuIcon></div>
      </ProSidebar>
      <div style={{ paddingLeft: (collapsed) ? "80px" : "300px" }} >
        {prop.children}
      </div>
    </>
  );
}

export default MainMenu;
