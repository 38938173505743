import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';
import { Util } from '../../api/util';
import { TEMPLATE_URL } from '../../api/student-api';
import { liststudent, savestudent, importstudent, listStudentPagination, editClassStudentById, deleteClassStudentById } from '../../store/editstudent/actions';
import { listwalkin, savewalkin } from '../../store/editwalkin/actions';
import { queryclear } from '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { createPage } from '../tab/pagetab';
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from '../../api/label';
import { StudentType } from '../../api/define';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import { getAccessToken } from '../../store/auth/selectors';
import { listPartnerWithOutPage } from '../../store/class/actions';
import { SingleSearch } from '../tab/search-component';
import { CustomPage } from '../share/CustomPage';
import { TableHeaderBlack } from '../../style/tableStyle';
import { ClassStudent } from '../../model/response/student-list';
import ImportStudentModal from './importStudentModal';

interface EditEnabledMap {
    [index: number]: boolean;
}

const EditStudent = () => {
    const rowTableEdit: React.CSSProperties = {
        backgroundColor: "#FFD8B2",
    };

    const token = useSelector(getAccessToken);
    const [init, setInit] = useState(0)
    const [initWalkin, setInitWalkin] = useState(0);
    const [initPartner, setInitPartner] = useState(0);
    const [initStudent, setInitStudent] = useState(0);

    const [items, setItems] = useState<any>({})
    const [itemsStudent, setItemsStudent] = useState<ClassStudent[]>([]);
    const [defaultItem, setDefaultItem] = useState<any>({});

    const [partnerItem, setPartnerItem] = useState<Array<any>>([])

    const [walkinItems, setWalkinItems] = useState<any>({});
    const [walkinDefaultItems, setWalkinDefaultItems] = useState<any>({});

    const [studentType, setStudentType] = useState<StudentType>(StudentType.LIST);
    const [studentChange, setStudentChange] = useState<boolean>(false);
    const [currentFile, setCurrentFile] = useState<FileList>();
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showWalkin, setShowWalkin] = useState(false)
    const ref = useRef<any>();
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [totalElement, setTotalElement] = useState<number>(0);

    const [limit, setLimit] = useState(10)
    const [edit, setEdit] = useState(false);
    const [walkinEdit, setWalkinEdit] = useState(false);

    const [success, setSuccess] = useState(false)
    const [leave, setLeave] = useState("")
    const [walkinLeave, setWalkinLeave] = useState("")

    const [errorUpload, setErrorUpload] = useState("");
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();

    const [isEditEnabled, setIsEditEnabled] = useState<EditEnabledMap>({});

    const [name, setName] = useState("");
    const [pendingEditIndex, setPendingEditIndex] = useState<number | null>(null);
    const [pendingEditId, setPendingEditId] = useState<number | null>(null);
    const [pendingData, setPendingData] = useState<ClassStudent | null>(null);
    const [isConfirmEdit, setIsConfirmEdit] = useState(false);
    const [isDeleteByRow, setIsDeleteByRow,] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<{ [index: number]: string[] }>({});
    const [showModalImport, setShowModalImport] = useState(false);
    const [startDateClass, setStartDateClass] = useState<Date | null>(null);


    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            // console.log("res", res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === 'list_student') {
                        setInit(1);
                        setStudentType(res.data.studentType);
                        setDefaultItem(JSON.parse(JSON.stringify(res.data)));
                        setItems(res.data);
                        setPage(0);
                        setEdit(false);
                        setDefaultIsEditEnabled(res.data);
                        setStartDateClass(new Date(res.data.fromDate));
                    } else if (res.table === 'list_student_pagination') {
                        setInitStudent(1);
                        setItemsStudent(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setUpdating(false);
                    }
                    else if (res.table === 'list_walkin') {
                        setInitWalkin(1);
                        setWalkinDefaultItems(JSON.parse(JSON.stringify(res.data)));
                        setWalkinItems(res.data);
                        setWalkinEdit(false);
                    } else if (res.table === "partner_with_out_page") {
                        setInitPartner(1);
                        setPartnerItem(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                if (res.table === 'list_student') {
                    setError('');
                    setUpdating(false);
                    setItems({});
                    setItems(res.data);
                    setPage(0);
                    setDefaultIsEditEnabled(res.data);
                    if ((res.data.importSuccess === undefined) && (res.data.success === true)) {
                        alert({ message: 'Saved', type: 'success' });
                        setDefaultItem(JSON.parse(JSON.stringify(res.data)));
                        setSuccess(true);
                        setEdit(false);
                    }
                    if (res.data.importSuccess === true) {
                        if (res.data.success === true) {
                            alert({ message: 'Imported', type: 'info' });
                            setEdit(true);
                        } else {
                            alert({ message: res.data.popup ?? 'Import error', type: 'error' });
                            setEdit(true);
                        }
                    }
                } else if (res.table === 'list_walkin') {
                    setError('');
                    setUpdating(false);
                    setWalkinItems({});
                    setWalkinItems(res.data);
                    if ((res.data.success === true)) {
                        alert({ message: 'Saved', type: 'success' });
                        //setWalkinDefaultItem(JSON.parse(JSON.stringify(res.data)));
                        handleWalkin(false);
                        setWalkinEdit(false);
                    }
                } else if (res.table === 'edit_class_student_by_id') {
                    alert({ message: 'Updated', type: 'success' });
                    setError('');
                    setUpdating(false);

                    // Reset edit states
                    setPendingData(null);
                    setIsEditEnabled({});
                    setPendingEditIndex(null);
                    setPendingEditId(null);
                    setIsConfirmEdit(false);
                    setValidationErrors([]);

                    dispatch(queryclear());
                    const pageStore = parseInt(localStorage.getItem('page') ?? '0');
                    const sizeStore = parseInt(localStorage.getItem('size') ?? '10');
                    dispatch(listStudentPagination(parseInt(id), name, pageStore, sizeStore));

                } else if (res.table === "delete_class_student_by_id") {
                    alert({ message: 'Deleted', type: 'success' });
                    setError('');
                    setUpdating(false);
                    dispatch(queryclear());
                    dispatch(listStudentPagination(parseInt(id), name, 0, size));
                }
                dispatch(queryclear());
            } else if (res.isFailed) {
                if (res.table === 'list_student') {
                    if ((res.data.success !== undefined) || (res.data.importSuccess !== undefined)) {
                        if ((res.data.studentList === null) || (res.data.studentList === undefined)) res.data.studentList = [];
                        //setValidate(true);
                        setItems(res.data);
                        setDefaultIsEditEnabled(res.data);
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scrollTo(0, 0);
                    }
                } else if (res.table === 'list_walkin') {
                    if ((res.data.success !== undefined) || (res.data.importSuccess !== undefined)) {
                        if ((res.data.studentList === null) || (res.data.studentList === undefined)) res.data.studentList = [];
                        setWalkinItems(res.data);
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scrollTo(0, 0);
                    }
                } else if (res.page === 'edit_class_student_by_id') {
                    setIsConfirmEdit(false);
                    alert({ message: res.data.message, type: 'error' });
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
                dispatch(queryclear());
            }
        })

        dispatch(queryclear());
        dispatch(liststudent(parseInt(id)));
        dispatch(listStudentPagination(parseInt(id), name, page, size));
        dispatch(listwalkin(parseInt(id)));
        dispatch(listPartnerWithOutPage());

        return unsubscribe;
    }, [])

    const checkAssignment = () => {
        var courseList = (items.courseList === undefined) ? [] : items.courseList;
        for (var i = 0; i < courseList.length; i++) {
            var c = courseList[i];
            if ((c.isAssignment !== null) && (c.isAssignment !== undefined) && (c.isAssignment === true)) return true;
        }
        return false;
    }

    const setDefaultIsEditEnabled = (items: any) => {
        if (items && items.studentList) {
            items.studentList.forEach((student: any, index: number) => {
                setIsEditEnabled((prevState) => ({
                    ...prevState,
                    [index]: student.errorPartnerCode || student.errorGeneration || student.errorFirstName || student.errorLastName || student.errorEmail,
                }));
            })
        }
    };

    const checkStudentList = () => {
        return (items.studentType === StudentType.LIST);
    }

    const validateFile = (): boolean => {
        setErrorUpload('');
        if ((currentFile !== undefined) && (currentFile.length > 0)) {
            let file_size = currentFile[0].size;
            //let file_name = currentFile[0].name;
            //let file_type = currentFile[0].type;
            if (file_size > MAX_FILE_UPLOAD * 1000000) {
                setErrorUpload(MAX_UPLOAD_FILE_SIZE_LABEL);
                return false;
            }
        }
        if ((currentFile === undefined) || (currentFile.length === 0)) {
            setErrorUpload(REQUIRE_LABEL);
            return false;
        }
        return true;
    }

    const getNumber = (i: number) => {
        return items.studentList[i].number;
    }

    const setNumber = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...items };
        newItems.studentList[i].number = parseInt(v);
        if (isNaN(newItems.studentList[i].number)) newItems.studentList[i].number = null;
        setItems(newItems);
        setEdit(true);
    }

    const setPartner = (i: number, e: string) => {
        if (updating) return;
        var newItems: any = { ...items };

        partnerItem.forEach(item => {
            if (item.id === e) {
                newItems.studentList[i].partnerId = item.id;
                newItems.studentList[i].partnerCode = item.code;
                newItems.studentList[i].partner = item.name;
                newItems.studentList[i].errorPartnerCode = undefined;
                setItems(newItems);
                setEdit(true);
            }
        });
    }

    const createPartnerSearchFilter = () => {
        return partnerItem.map((item) => ({
            label: `${item.code} : ${item.name}`,
            value: item.id,
        }));
    };

    const getPartner = (index: number) => {
        for (var i = 0; i < partnerItem.length; i++) {
            const partner = partnerItem[i];
            if (items.studentList[index].partnerId === partner.id) {
                return { label: partner.code, value: partner.id };
            }
        }
        return null;
    }

    const getGeneration = (i: number) => {
        return items.studentList[i].generation;
    }

    const getEmail = (i: number) => {
        return items.studentList[i].email;
    }

    const getFirstName = (i: number) => {
        return items.studentList[i].firstName;
    }

    const getLastName = (i: number) => {
        return items.studentList[i].lastName;
    }

    const setGeneration = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...items };
        newItems.studentList[i].generation = parseInt(v);
        if (isNaN(newItems.studentList[i].generation)) newItems.studentList[i].generation = null;
        setItems(newItems);
        setEdit(true);
    }

    const setEmail = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...items };
        newItems.studentList[i].email = v;
        setItems(newItems);
        setEdit(true);
    }

    const setFirstName = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...items };
        newItems.studentList[i].firstName = v;
        setItems(newItems);
        setEdit(true);
    }

    const setLastName = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...items };
        newItems.studentList[i].lastName = v;
        setItems(newItems);
        setEdit(true);
    }

    const upload = () => {
        var res = validateFile();
        if ((res) && (currentFile !== undefined)) {
            dispatch(queryclear());
            dispatch(importstudent(parseInt(id), studentType, currentFile));
            ref.current.value = "";
        }
    }

    const clear = () => {
        var newItem = JSON.parse(JSON.stringify(defaultItem));
        setItems(newItem);
        setStudentType((newItem.studentType === StudentType.LIST) ? StudentType.LIST : StudentType.NUMBER);
        setEdit(false);
    }

    const handleClear = () => {
        if (!edit) clear();
        else setLeave("clear");
    }

    const handleFileChange = (selectorFiles: FileList | null) => {
        if (selectorFiles !== null) {
            setCurrentFile(selectorFiles);
        }
    }

    const handleUpload = () => {
        if (!edit) { upload(); }
        else { setLeave("upload"); }
    }

    const handleSubmit = () => {
        var newItem = correctError();
        dispatch(queryclear());
        dispatch(savestudent(parseInt(id), newItem));
    }

    const handleClose = () => {
        history.push("/admin/search/class");
    }

    const handleBack = () => {
        if (!edit) history.push("/admin/search/class");
        else if (StudentType.LIST === studentType) setLeave("/admin/search/class");
        else setLeave("/admin/search/class");
    }

    const handleClass = () => {
        if (!edit) history.push("/admin/class/" + id);
        else setLeave("/admin/class/" + id);
    }

    const handleDoc = () => {
        if (!edit) history.push("/admin/class/" + id + "/doc");
        else setLeave("/admin/class/" + id + "/doc");
    }

    const handleAssignment = () => {
        if (!edit) history.push("/admin/class/" + id + "/assignment");
        else setLeave("/admin/class/" + id + "/assignment");
    }

    const handleHistory = () => {
        if (!edit) history.push("/admin/class/" + id + "/history");
        else setLeave("/admin/class/" + id + "/history");
    }

    const handleResult = () => {
        if (!edit) history.push("/admin/class/" + id + "/result");
        else setLeave("/admin/class/" + id + "/result");
    }
    const handleSurvey = () => {
        if (!edit) history.push("/admin/class/" + id + "/survey");
        else setLeave("/admin/class/" + id + "/survey");
    }

    const handleResultNumber = () => {
        if (!edit) history.push("/admin/class/" + id + "/result-number");
        else setLeave("/admin/class/" + id + "/result-number");
    }

    const handleReedit = () => {
        setSuccess(false);
    }

    const handleTemplate = () => {
        window.open(TEMPLATE_URL + "?template_type=" + studentType + "&token=" + encodeURIComponent(token), '_blank', 'noopener,noreferrer')
    }

    const handleEdit = (index: number) => {
        setIsEditEnabled(prevEditEnabled => ({
            ...prevEditEnabled,
            [index]: true, // Toggle edit for this row
        }));
    };

    const handleDelete = (id: number) => {
        if (updating) return;
        var newItem: any = { ...items };
        var list: any[] = [];
        for (var i = 0; i < newItem.studentList.length; i++)
            if (i !== id) {
                list.push(newItem.studentList[i]);
            }
        newItem.studentList = list;
        setItems(newItem);
        setEdit(true);
    }

    const handleLeave = () => {
        if (leave === "clear") {
            clear();
        } else if (leave === "upload") {
            upload();
        } else {
            history.push(leave);
        }
        setLeave("");
    }

    const handleStudentChange = () => {
        setStudentType((studentType === StudentType.LIST) ? StudentType.NUMBER : StudentType.LIST);
        setPage(0);
        setStudentChange(false);
        setEdit(true);
    }

    const handleLimit = (l: number) => {
        setLimit(l);
    }

    const handlePage = (l: number) => {
        setPage(l);
    }

    const getTotalItem = () => {
        if (items.studentList === undefined) return 0;
        if (items.studentType !== studentType) return 0;
        return items.studentList.length;
    }

    const getTotalPage = () => {
        if (items.studentList === undefined) return 0;
        if (items.studentType !== studentType) return 0;
        return Math.ceil(items.studentList.length / limit);
    }

    const getTotalImport = () => {
        if (items.studentList === undefined) return 0;
        var count = 0;
        for (var i = 0; i < items.studentList.length; i++) {
            if (items.studentList[i].line !== undefined) count++;
        }
        return count;
    }

    const getTotalNew = () => {
        if (items.studentList === undefined) return 0;
        var count = 0;
        for (var i = 0; i < items.studentList.length; i++) {
            if (items.studentList[i].id === undefined) count++;
        }
        return count;
    }

    const getTotalUpdate = () => {
        if (items.studentList === undefined) return 0;
        var count = 0;
        for (var i = 0; i < items.studentList.length; i++) {
            if (items.studentList[i].updated === true) count++;
        }
        return count;
    }


    const getTotalError = () => {
        if (items.studentList === undefined) return 0;
        var count = 0;
        for (var i = 0; i < items.studentList.length; i++) {
            if ((items.studentList[i].errorGeneration !== undefined) ||
                (items.studentList[i].errorPartnerCode !== undefined) ||
                (items.studentList[i].errorFirstName !== undefined) ||
                (items.studentList[i].errorLastName !== undefined) ||
                (items.studentList[i].errorEmail !== undefined) ||
                (items.studentList[i].errorCourse !== undefined) ||
                (items.studentList[i].errorNumber !== undefined))
                count++;
        }
        return count;
    }

    const correctError = () => {
        var newItem = { ...items };
        var s: any[] = [];
        if ((items != null) && (items !== undefined)) {
            for (var i = 0; i < items.studentList.length; i++) {
                if (items.studentList[i].error === undefined) {
                    s.push(items.studentList[i]);
                }
            }
        }
        newItem.studentList = s;
        return newItem;
    }

    const handleWalkin = (e: boolean) => {
        setShowWalkin(e);
    }

    /***********************  Walkin  */
    const getWalkin = (i: number) => {
        return walkinItems.studentList[i].walkin;
    }

    const setWalkin = (i: number, v: string) => {
        if (updating) return;
        var newItems: any = { ...walkinItems };
        newItems.studentList[i].walkin = v;
        setWalkinItems(newItems);
        setWalkinEdit(true);
    }

    const getWalkinSummary = (i: number) => {
        var walkin = isNaN(parseInt(walkinItems.studentList[i].walkin)) ? 0 : parseInt(walkinItems.studentList[i].walkin);
        return walkinItems.studentList[i].number + walkin;
    }

    const handleWalkinSave = () => {
        dispatch(queryclear());
        dispatch(savewalkin(parseInt(id), walkinItems));
    }

    const handleWalkinLeave = () => {
        if (walkinEdit) setWalkinLeave("edit");
        else handleWalkin(false);
    }

    const clearWalkin = () => {
        var newItem = JSON.parse(JSON.stringify(walkinDefaultItems));
        setWalkinItems(newItem);
        setWalkinEdit(false);
        setWalkinLeave("");
        handleWalkin(false);
    }

    // ------------------------------------------------ new -------------------------------------------------- //

    useEffect(() => {
        if (initStudent === 1) {
            dispatch(queryclear());
            dispatch(listStudentPagination(parseInt(id), name, 0, size));
        }
    }, [name]);

    const onPageChange = (value: number) => {
        setPage(value);
        handleCancelByRow();
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);
    }

    useEffect(() => {
        localStorage.setItem('page', page.toString());
        localStorage.setItem('size', size.toString());
        if (initStudent === 1) {
            dispatch(listStudentPagination(parseInt(id), name, page, size));
        }
    }, [page, size]);

    const getPartnerStudent = (index: number) => {
        for (var i = 0; i < partnerItem.length; i++) {
            const partner = partnerItem[i];
            if (itemsStudent[index].partnerId === partner.id) {
                return { label: partner.code, value: partner.id };
            }
        }
        return null;
    }

    const getGenerationStudent = (index: number): string | number => {
        const generation = itemsStudent[index]?.generation;
        return generation === null || generation === undefined ? 0 : generation;
    };

    const getFirstNameStudent = (i: number) => {
        return itemsStudent[i].firstName;
    }

    const getLastNameStudent = (i: number) => {
        return itemsStudent[i].lastName;
    }

    const getEmailStudent = (i: number) => {
        return itemsStudent[i].email;
    }

    const setPartnerStudent = (index: number, selectedValue: string) => {
        if (updating) return;

        // Create a new copy of the row being edited
        const updatedItemsStudent = [...itemsStudent];
        const updatedItem = { ...updatedItemsStudent[index] };

        // Update the dropdown data
        partnerItem.forEach(item => {
            if (item.id === selectedValue) {
                updatedItem.partnerId = item.id;
                updatedItem.partnerCode = item.code;
                updatedItem.partner = item.name;
            }
        });

        // Update the itemsStudent array
        updatedItemsStudent[index] = updatedItem;
        setItemsStudent(updatedItemsStudent);
        setEdit(true);
    };

    const setGenerationStudent = (i: number, v: number) => {
        if (updating) return;

        // Create a new copy of the row being edited
        const updatedItemsStudent = [...itemsStudent];
        const updatedItem = { ...updatedItemsStudent[i] };

        // Update the generation data
        updatedItem.generation = isNaN(v) ? null : v;
        updatedItemsStudent[i] = updatedItem;
        setItemsStudent(updatedItemsStudent);
        setEdit(true);
    }

    const setFirstNameStudent = (i: number, v: string) => {
        if (updating) return;

        // Create a new copy of the row being edited
        const updatedItemsStudent = [...itemsStudent];
        const updatedItem = { ...updatedItemsStudent[i] };

        // Update the first name data
        updatedItem.firstName = v;
        updatedItemsStudent[i] = updatedItem;
        setItemsStudent(updatedItemsStudent);
        setEdit(true);
    }

    const setLastNameStudent = (i: number, v: string) => {
        if (updating) return;

        // Create a new copy of the row being edited
        const updatedItemsStudent = [...itemsStudent];
        const updatedItem = { ...updatedItemsStudent[i] };

        // Update the last name data
        updatedItem.lastName = v;
        updatedItemsStudent[i] = updatedItem;
        setItemsStudent(updatedItemsStudent);
        setEdit(true);
    }

    const setEmailStudent = (i: number, v: string) => {
        if (updating) return;

        // Create a new copy of the row being edited
        const updatedItemsStudent = [...itemsStudent];
        const updatedItem = { ...updatedItemsStudent[i] };

        // Update the email data
        updatedItem.email = v;
        updatedItemsStudent[i] = updatedItem;
        setItemsStudent(updatedItemsStudent);
        setEdit(true);
    }

    const checkClssIsStarted = () => {
        return startDateClass !== null && startDateClass <= new Date();
    }

    const handleEditByRow = (index: number, id: number) => {
        setPendingEditIndex(index);
        setPendingEditId(id);
        setIsEditEnabled({ [index]: true });

        // Deep copy the item to ensure independence
        const itemCopy = JSON.parse(JSON.stringify(itemsStudent[index]));
        setPendingData(itemCopy);
    };

    const handleCancelByRow = () => {
        if (pendingEditIndex !== null && pendingData) {
            // Create a new array and replace the specific row with the original data
            const updatedItemsStudent = [...itemsStudent];
            updatedItemsStudent[pendingEditIndex] = { ...pendingData };

            setItemsStudent(updatedItemsStudent); // Update the state with the restored data
            setValidationErrors([]); // Clear any validation errors
        }

        // Reset edit states
        setPendingData(null);
        setIsEditEnabled({});
        setPendingEditIndex(null);
        setPendingEditId(null);
    };

    const handleDeleteByRow = (id: number) => {
        setPendingEditId(id);
        setIsDeleteByRow(true);
    }

    const validateRowData = (index: number): boolean => {
        const row = itemsStudent[index];
        const errors: string[] = [];

        if (!row.partnerId) errors.push("partnerId");
        if (!row.generation) errors.push("generation");
        if (!row.firstName?.trim()) errors.push("firstName");
        if (!row.lastName?.trim()) errors.push("lastName");
        if (!row.email?.trim()) errors.push("email");

        if (errors.length > 0) {
            setValidationErrors((prev) => ({ ...prev, [index]: errors }));
            return false;
        }

        // Clear validation errors for this index if validation passes
        setValidationErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[index];
            return newErrors;
        });

        return true;
    };

    const handleModalConfirmEdit = () => {
        if (pendingEditIndex === null) return;

        // Validate the row using the existing validation logic

        const request = itemsStudent[pendingEditIndex];

        // Dispatch the edit action
        dispatch(queryclear());
        dispatch(editClassStudentById(request));
    };

    const handleModalConfirmDelete = () => {
        if (pendingEditId === null) return;
        dispatch(queryclear());
        dispatch(deleteClassStudentById(pendingEditId));
        setIsDeleteByRow(false);
        setPendingEditId(null);
    }

    const customFrontHeader = () => {
        return <div className="blue" onClick={(e) => { handleWalkin(true) }} style={{ cursor: "pointer", textAlign: "left" }}>ดูข้อมูลผู้เรียนนอกระบบ</div>;
    }

    const customBehindHeader = () => {
        return <button type="button" className="btn btn-warning" style={{ marginLeft: "10px" }} onClick={() => { setShowModalImport(true) }}>
            จัดการข้อมูลผู้เรียน
        </button>;
    }

    const closeImportModal = () => {
        setShowModalImport(false);
        dispatch(queryclear());
        dispatch(listStudentPagination(parseInt(id), name, 0, size));
    }

    return (<>
        <MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">รอบการเรียน</h4></div>
                    {(init + initWalkin + initPartner === 3) &&
                        <>
                            <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">โครงการ</label><br />
                                        <input className="form-control" type="text" value={items.code + ":" + items.project} readOnly={true} />
                                    </div>
                                    <div className="col-1">
                                        <label className="col-form-label">รอบ</label><br />
                                        <input className="form-control" type="text" value={items.session} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่เริ่ม</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(items.fromDate))} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่สิ้นสุด</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(items.toDate))} readOnly={true} />
                                    </div>
                                    <div className="col-3">
                                        <label className="col-form-label">ฝ่ายผู้รับผิดชอบโครงการ</label><br />
                                        <input className="form-control" type="text" value={items.organizer} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            {ClassTab(2, true, checkAssignment(), checkStudentList(), handleClass, null, handleDoc, handleAssignment, handleHistory, handleResult, handleSurvey, handleResultNumber, items.showResultStudentTab)}
                            <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                                <div className="row">
                                    <div className="col-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        <input className="form-check-input" type="radio" checked={studentType === StudentType.LIST} onClick={e => { if (studentType === StudentType.NUMBER) setStudentChange(true); }} disabled={success} />
                                        <label className="form-check-label">
                                            ผู้เรียน (ระบุรายชื่อ)
                                        </label>
                                    </div>
                                    <div className="col-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        <input className="form-check-input" type="radio" checked={studentType === StudentType.NUMBER} onClick={e => { if (studentType === StudentType.LIST) setStudentChange(true); }} disabled={success} />
                                        <label className="form-check-label">
                                            ผู้เรียน (ระบุจำนวน)
                                        </label>
                                    </div>

                                    {(studentType === StudentType.NUMBER) && <div className="col-4">
                                        <div className="row">
                                            <div className="col-8" style={{ textAlign: "left" }}>
                                                <InputGroup hasValidation>
                                                    <input type="file" className={"form-control " + ((errorUpload !== "") ? "is-invalid" : "")} accept=".xlsx" id="contained-button-file" ref={ref} onChange={(e: any) => handleFileChange(e.target.files)} disabled={success} /><br />
                                                </InputGroup>
                                                <div className={(errorUpload !== "") ? "text-danger" : ""}><small>{errorUpload}</small></div>
                                            </div>
                                            <div className="col-4" onClick={(e) => { handleUpload() }}>
                                                <Button className="btn btn-primary" style={{ width: "120px" }} disabled={success}>
                                                    Upload
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {(studentType === StudentType.NUMBER) && <div className="col-2" style={{ textAlign: "right", justifyContent: "right" }}>
                                        <div onClick={(e) => { handleTemplate() }} style={{ cursor: "pointer" }}><Button className="btn green-button" style={{ color: "white" }}><GetAppIcon></GetAppIcon> Template File</Button></div>
                                    </div>
                                    }
                                </div>
                                <br />
                                {(studentType === StudentType.LIST) && <div
                                    className="col"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        padding: "0px"
                                    }}
                                >
                                    <label
                                        className="col-form-label"
                                        style={{ marginRight: "10px" }}
                                    >
                                        ค้นหาผู้เรียนจาก ชื่อ-นามสกุล
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        style={{ width: "500px" }}
                                    />
                                </div>}
                                {(studentType === StudentType.NUMBER) && <div className="row">
                                    <div className="col-6" style={{ justifyContent: "left", textAlign: "left" }}>
                                        <table>
                                            <tr>
                                                <td>
                                                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-6 row" style={{ justifyContent: "right", textAlign: "right", paddingRight: "0px" }}>
                                        <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                                        <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                                            <option value={getTotalItem()}>All</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>

                                </div>}

                                {(updating) && (error === "") ? (
                                    <div style={{ textAlign: "center", width: "1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                ) : (<br />)
                                }

                                {initStudent && (studentType === StudentType.LIST) && <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} customHeader={customFrontHeader()} postElement={customBehindHeader()} disabledAll={true}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>ลำดับ</th>
                                                <th className='text-center'>รหัสองค์กร</th>
                                                <th className='text-center'>ชื่อองค์กร</th>
                                                <th className='text-center'>รุ่น</th>
                                                <th className='text-center'>ชื่อ-นามสกุล</th>
                                                <th className='text-center'>Username<br />(SET Member)</th>
                                                <th className='text-center'>ประเภทการเรียน</th>
                                                <th className='text-center'>SET Member<br />Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemsStudent?.map((item: ClassStudent, index: number) => (
                                                <tr style={isEditEnabled[index] ? rowTableEdit : undefined}>
                                                    <td className='text-center'> {page * size + index + 1}</td>
                                                    <td className='text-left'>
                                                        <SingleSearch
                                                            id={index.toString()}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            placeholder=""
                                                            disabled={!isEditEnabled[index]}
                                                            onChange={(id: string, e: any) => setPartnerStudent(index, e?.value)}
                                                            value={getPartnerStudent(index)}
                                                            options={createPartnerSearchFilter()}
                                                            style={{ width: "170px" }}
                                                        />
                                                    </td>
                                                    <td className={"text-left"}> {item.partner} </td>
                                                    <td className="text-left">
                                                        <InputGroup>
                                                            <input
                                                                id={index.toString()}
                                                                className={`form-control ${validationErrors[index]?.includes("generation") ? "is-invalid" : ""}`}
                                                                value={getGenerationStudent(index)}
                                                                onChange={(e) => setGenerationStudent(parseInt(e.currentTarget.id), parseInt(e.target.value))}
                                                                style={{ width: "70px" }}
                                                                disabled={!isEditEnabled[index]}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td className="text-left">
                                                        <InputGroup>
                                                            <div className="column">
                                                                <input
                                                                    id={index.toString()}
                                                                    className={`form-control ${validationErrors[index]?.includes("firstName") ? "is-invalid" : ""}`}
                                                                    value={getFirstNameStudent(index)}
                                                                    onChange={(e) => setFirstNameStudent(parseInt(e.currentTarget.id), e.target.value)}
                                                                    style={{ width: "140px" }}
                                                                    disabled={item.isSetMember || !isEditEnabled[index]}
                                                                />
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div className="column">
                                                                <input
                                                                    id={index.toString()}
                                                                    className={`form-control ${validationErrors[index]?.includes("lastName") ? "is-invalid" : ""}`}
                                                                    value={getLastNameStudent(index)}
                                                                    onChange={(e) => setLastNameStudent(parseInt(e.currentTarget.id), e.target.value)}
                                                                    style={{ width: "140px" }}
                                                                    disabled={item.isSetMember || !isEditEnabled[index]}
                                                                />
                                                            </div>
                                                        </InputGroup>
                                                    </td>
                                                    <td className="text-left">
                                                        <InputGroup>
                                                            <input
                                                                id={index.toString()}
                                                                className={`form-control ${validationErrors[index]?.includes("email") ? "is-invalid" : ""}`}
                                                                value={getEmailStudent(index)}
                                                                onChange={(e) => setEmailStudent(parseInt(e.currentTarget.id), e.target.value)}
                                                                style={{ width: "270px" }}
                                                                disabled={item.isSetMember || !isEditEnabled[index]}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td className={"text-left"} style={{ width: "95px" }}> {item.course} </td>
                                                    <td>
                                                        <CircleIcon className={item.isSetMember ? "green" : "red"} />
                                                    </td>
                                                    <td>
                                                        <div className="row">
                                                            {(!success) && (pendingEditIndex === null || pendingEditIndex === index) ? (
                                                                <>
                                                                    <div onClick={() => {
                                                                        if (isEditEnabled[index]) {
                                                                            if (validateRowData(index)) {
                                                                                setIsConfirmEdit(true);
                                                                            }
                                                                        } else {
                                                                            handleEditByRow(index, item.id);
                                                                        }
                                                                    }}>
                                                                        {isEditEnabled[index] ? (
                                                                            <CheckIcon className="green pointer" />
                                                                        ) : (
                                                                            <EditIcon className="yellow pointer" />
                                                                        )}
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        if (isEditEnabled[index]) {
                                                                            handleCancelByRow();
                                                                        } else {
                                                                            handleDeleteByRow(item.id);
                                                                        }
                                                                    }}>
                                                                        {isEditEnabled[index] ? (
                                                                            <CancelIcon className="red pointer" />
                                                                        ) : !checkClssIsStarted() && (
                                                                            <DeleteIcon className="red pointer" />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {itemsStudent?.length == 0 && (
                                                <tr>
                                                    <td colSpan={10}>ไม่มีรายการ</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>}

                                {/* {(studentType === StudentType.LIST) &&
                                    <Table striped hover style={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                                        <thead className="black-button">
                                            <tr>
                                                <th style={{ verticalAlign: "top" }}>ลำดับ</th>
                                                {(items.success === false) &&
                                                    <th style={{ verticalAlign: "top" }}>Excel no.</th>
                                                }
                                                <th className='text-left' style={{ verticalAlign: "top" }}>รหัสองค์กร</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>ชื่อองค์กร</th>
                                                <th style={{ verticalAlign: "top" }}>รุ่น</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>ชื่อ-นามสกุล</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>Username<br />(SET Member)</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>ประเภทการเรียน</th>
                                                <th style={{ verticalAlign: "top" }}>SET Member<br />Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: "23px" }}>
                                            {(items.studentType === StudentType.LIST) &&
                                                items.studentList.map((item: any, index: number) => {
                                                    if ((index >= page * limit) && (index < (page + 1) * limit))
                                                        return <tr key={item.id} className={(item.updated ? "green" : "") + " " + (((item.id === undefined) && (item.updated === false)) ? "blue" : "")}>
                                                            <td> {index + 1}</td>
                                                            {(items.success === false) &&
                                                                <td> {item.line}</td>
                                                            }
                                                            <td className='text-left'>
                                                                <SingleSearch
                                                                    id={index.toString()} isSearchable={true} isMulti={false} placeholder="" disabled={!isEditEnabled[index]}
                                                                    onChange={(id: string, e: any) => setPartner(index, e?.value)}
                                                                    value={getPartner(index)}
                                                                    options={createPartnerSearchFilter()}
                                                                    style={{ width: "170px" }}
                                                                />
                                                            </td>
                                                            <td className={(item.errorPartnerCode !== undefined ? "text-danger text-left" : "text-left")}> {(item.errorPartnerCode !== undefined) ? item.errorPartnerCode : item.partner} </td>
                                                            <td className='text-left'>
                                                                <InputGroup hasValidation>
                                                                    <input id={index.toString()} className={"form-control " + ((item.errorGeneration !== undefined) ? "is-invalid" : "")} value={getGeneration(index)} onChange={(e) => { setGeneration(parseInt(e.currentTarget.id), e.target.value) }} style={{ width: "70px" }} disabled={!isEditEnabled[index]} /><br />
                                                                </InputGroup>
                                                                <div className={(item.errorGeneration !== undefined) ? "text-danger" : ""}><small>{item.errorGeneration}</small></div>
                                                            </td>
                                                            <td className='text-left'>
                                                                <InputGroup hasValidation>
                                                                    <div className="column">
                                                                        <input id={index.toString()} className={"form-control " + ((item.errorFirstName !== undefined) ? "is-invalid" : "")} value={getFirstName(index)} onChange={(e) => { setFirstName(parseInt(e.currentTarget.id), e.target.value) }} style={{ width: "140px" }} disabled={item.isSetMember || !isEditEnabled[index]} />
                                                                        <span className={(item.errorFirstName !== undefined ? "text-danger" : "")}><small>{item.errorFirstName}</small></span>
                                                                    </div>
                                                                    &nbsp;&nbsp;
                                                                    <div className="column">
                                                                        <input id={index.toString()} className={"form-control " + ((item.errorLastName !== undefined) ? "is-invalid" : "")} value={getLastName(index)} onChange={(e) => { setLastName(parseInt(e.currentTarget.id), e.target.value) }} style={{ width: "140px" }} disabled={item.isSetMember || !isEditEnabled[index]} />
                                                                        <span className={(item.errorLastName !== undefined ? "text-danger" : "")}><small>{item.errorLastName}</small></span>
                                                                    </div>
                                                                </InputGroup>
                                                            </td>
                                                            <td className='text-left'>
                                                                <InputGroup hasValidation>
                                                                    <input id={index.toString()} className={"form-control " + ((item.errorEmail !== undefined) ? "is-invalid" : "")} value={getEmail(index)} onChange={(e) => { setEmail(parseInt(e.currentTarget.id), e.target.value) }} style={{ width: "270px" }} disabled={item.isSetMember || !isEditEnabled[index]} /><br />
                                                                </InputGroup>
                                                                <div className={(item.errorEmail !== undefined ? "text-danger" : "")}><small>{item.errorEmail}</small></div>
                                                            </td>
                                                            <td className={(item.errorCourse !== undefined ? "text-danger text-left" : "text-left")} style={{ width: "95px" }}> {item.course}  {(item.errorCourse !== undefined) ? <><br /><small>{item.errorCourse}</small></> : ""}</td>
                                                            <td>
                                                                <CircleIcon className={item.isSetMember ? "green" : "red"} />
                                                            </td>
                                                            <td>
                                                                <div className="row">
                                                                    {(!success) && (
                                                                        <>
                                                                            <div onClick={(e) => handleEdit(index)}><EditIcon className="yellow pointer"></EditIcon></div>
                                                                            <div onClick={(e) => handleDelete(index)}><CancelIcon className="red pointer"></CancelIcon></div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                })}
                                        </tbody>
                                    </Table>
                                } */}

                                {(studentType === StudentType.NUMBER) &&
                                    <Table striped hover style={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                                        <thead className="black-button">
                                            <tr>
                                                <th style={{ verticalAlign: "top" }}>ลำดับ</th>
                                                {(items.success === false) &&
                                                    <th style={{ verticalAlign: "top" }}>Excel no.</th>
                                                }
                                                <th className='text-left' style={{ verticalAlign: "top" }}>รหัสองค์กร</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>ชื่อองค์กร</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>รุ่น</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>จำนวน</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: "23px" }}>
                                            {(items.studentType === StudentType.NUMBER) &&
                                                items.studentList.map((item: any, index: number) => {
                                                    if ((index >= page * limit) && (index < (page + 1) * limit))
                                                        return <tr key={item.id} className={(item.updated ? "green" : "") + " " + (((item.id === undefined) && (item.updated === false)) ? "blue" : "")}>
                                                            <td> {index + 1}</td>
                                                            {(items.success === false) &&
                                                                <td> {item.line}</td>
                                                            }
                                                            <td className='text-left'>
                                                                <SingleSearch
                                                                    id={index.toString()} isSearchable={true} isMulti={false} placeholder=""
                                                                    onChange={(id: string, e: any) => setPartner(index, e?.value)}
                                                                    value={getPartner(index)}
                                                                    options={createPartnerSearchFilter()}
                                                                    style={{ width: "170px" }}
                                                                />
                                                            </td>
                                                            {/* <td className='text-left'> {item.partnerCode} </td> */}
                                                            <td className={(item.errorPartnerCode !== undefined ? "text-danger text-left" : "text-left")}> {(item.errorPartnerCode !== undefined) ? item.errorPartnerCode : item.partner} </td>
                                                            <td className='text-left'>
                                                                <InputGroup hasValidation>
                                                                    <input id={index.toString()} className={"form-control " + ((item.errorGeneration != undefined) ? "is-invalid" : "")} value={getGeneration(index)} onChange={(e) => { setGeneration(parseInt(e.currentTarget.id), e.target.value) }} disabled={success} style={{ width: "50px" }} /><br />
                                                                </InputGroup>
                                                                <div className={(item.errorGeneration !== undefined) ? "invalid-feedback" : ""}><small>{item.errorGeneration}</small></div>
                                                            </td>
                                                            <td className='text-left'>
                                                                <InputGroup hasValidation>
                                                                    <input id={index.toString()} className={"form-control " + ((item.errorNumber != undefined) ? "is-invalid" : "")} value={getNumber(index)} onChange={(e) => { setNumber(parseInt(e.currentTarget.id), e.target.value) }} disabled={success} style={{ width: "50px" }} /><br />
                                                                </InputGroup>
                                                                <div className={(item.errorNumber !== undefined) ? "invalid-feedback" : ""}><small>{item.errorNumber}</small></div>
                                                            </td>
                                                            <td>
                                                                {(!success) &&
                                                                    <div onClick={(e) => handleDelete(index)}><CancelIcon className="red pointer"></CancelIcon></div>
                                                                }
                                                            </td>
                                                        </tr>
                                                })}
                                        </tbody>
                                    </Table>
                                }
                                <br />
                                {(studentType === StudentType.NUMBER) && <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                    {createPage(page, getTotalPage(), handlePage)}
                                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                                        จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ <br />
                                        {((items.importSuccess !== undefined) && (items.studentType === studentType)) &&
                                            <>
                                                จำนวนนำเข้า {Util.integerWithCommas(getTotalImport())} รายการ <br />
                                                <span className='blue'>จำนวนใหม่ {Util.integerWithCommas(getTotalNew())} รายการ</span><br />
                                                <span className='green'>จำนวนแก้ไขของเดิม {Util.integerWithCommas(getTotalUpdate())} รายการ</span><br />
                                                <span className='red'>จำนวนผิดพลาด {Util.integerWithCommas(getTotalError())} รายการ</span><br />
                                            </>
                                        }
                                    </div>
                                    <br /><br /><br /><br />
                                </div>}
                            </div>
                        </>
                    }
                    <br />
                    {((init + initWalkin + initPartner !== 3) || (loading)) && (error === "") &&
                        <div style={{ textAlign: "center", width: "1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                    {(init + initWalkin + initPartner === 3) && (!loading) && (!success) && (!updating) &&
                        <div style={{ justifyContent: "right", textAlign: "right" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => handleBack()} >
                                Back
                            </button>&nbsp;
                            {(studentType === StudentType.NUMBER) && <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                onClick={() => handleClear()} >
                                Reset
                            </button>}&nbsp;
                            {(studentType === StudentType.NUMBER) && <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                onClick={() => handleSubmit()} >
                                Save
                            </button>}
                        </div>
                    }
                    {(success) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => handleClose()} >
                                Back
                            </button>&nbsp;
                            <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                                onClick={() => handleReedit()} >
                                Edit
                            </button>
                        </div>
                    }
                </div>
                <br />
            </div>
        </MainMenu>

        <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
            <Modal.Header closeButton>
                <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setLeave("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleLeave() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={studentChange} onHide={() => { setStudentChange(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>ยืนยันการเปลี่ยนข้อมูลผู้เรียน?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setStudentChange(false) }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleStudentChange() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={walkinLeave !== ""} onHide={() => { setWalkinLeave("") }}>
            <Modal.Header closeButton>
                <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setWalkinLeave("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { clearWalkin(); }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={isConfirmEdit} onHide={() => { setIsConfirmEdit(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>ยืนยันการแก้ไขข้อมูลผู้เรียน?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setIsConfirmEdit(false) }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleModalConfirmEdit() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={isDeleteByRow} onHide={() => { setIsDeleteByRow(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>ยืนยันการลบข้อมูลผู้เรียน?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setIsDeleteByRow(false) }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleModalConfirmDelete() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal size="xl" show={showWalkin} onHide={() => { handleWalkin(false) }}>
            <Modal.Body>
                <Table striped hover style={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                    <thead className="black-button" style={{ borderColor: "black", background: "black" }}>
                        <tr>
                            <th style={{ verticalAlign: "middle" }} rowSpan={2}>Step</th>
                            <th style={{ verticalAlign: "middle" }} rowSpan={2}>ชื่อหลักสูตร</th>
                            <th style={{ verticalAlign: "top", textAlign: "center", borderStyle: "none" }} colSpan={3}>จำนวนผู้เข้าเรียน</th>
                        </tr>
                        <tr>
                            <th style={{ verticalAlign: "top", borderStyle: "none" }}>มีรายชื่อในระบบ</th>
                            <th style={{ verticalAlign: "top", width: "250px", borderStyle: "none" }}>ลงทะเบียนนอกระบบ</th>
                            <th style={{ verticalAlign: "top", borderStyle: "none" }}>ทั้งหมด</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: "23px" }}>
                        {(walkinItems.studentList !== undefined) &&
                            walkinItems.studentList.map((item: any, index: number) => {
                                return <tr key={item.id} >
                                    <td> {index + 1}</td>
                                    <td> {item.shortName + ":" + item.name} </td>
                                    <td> {item.number} </td>
                                    <td className='text-left'>
                                        <InputGroup hasValidation>
                                            <div style={{ width: "200px" }}>
                                                <input id={index.toString()} className={"form-control " + ((item.errorWalkin !== undefined) ? "is-invalid" : "")} value={getWalkin(index)} maxLength={32} onChange={(e) => { setWalkin(parseInt(e.currentTarget.id), e.target.value) }} disabled={success} />
                                            </div>
                                        </InputGroup>
                                        <div className={(item.errorWalkin !== undefined) ? "text-danger" : ""}><small>{item.errorWalkin}</small></div>
                                    </td>
                                    <td>
                                        {getWalkinSummary(index)}
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleWalkinLeave() }}>
                    Close
                </Button>
                <Button variant="danger" onClick={() => { handleWalkinSave() }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        <ImportStudentModal show={showModalImport} onHide={() => closeImportModal()} id={id} />

        <AlertContainer floatingTime={10000} />
    </>)
}

export default EditStudent;