import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { StudentAPI } from '../../api/student-api';
import { dispatchError } from '../search/actions';
import { StudentType } from '../../api/define';
import { ClassStudent, TempClassStudentUpdation } from '../../model/response/student-list';

export const getclass = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.getClass(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "edit_student",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_student", dispatch, error);
    })
};

export const liststudent = (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    StudentAPI.list(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_student", dispatch, error);
    })
};

export const savestudent = (classId: number, item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.save(classId, item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                table: "list_student",
                page: "edit_student",
                payload: error.response.data.additional
            });
        } else {
            dispatchError("edit_student", dispatch, error);
        }

    })
};

export const importstudent = (classId: number, studentType: StudentType, file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.import(classId, studentType, file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "list_student",
            page: "edit_student",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_student",
                payload: error.response.data.additional
            });
        } else
            dispatchError("edit_student", dispatch, error);
    })
};

export const listStudentPagination = (classId: number, name: string, page: number, size: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    StudentAPI.studentPagination(classId, name, page, size).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_student_pagination",
            page: "list_student",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("list_student_pagination", dispatch, error);
    })
};

export const editClassStudentById = (item: ClassStudent) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.editStudentById(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "edit_class_student_by_id",
            page: "list_student",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                table: "edit_class_student_by_id",
                page: "list_student",
                payload: error.response.data.additional
            });
        } else {
            dispatchError("edit_class_student_by_id", dispatch, error);
        }
    })
};

export const deleteClassStudentById = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.deleteStudentById(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "delete_class_student_by_id",
            page: "list_student",
            action: ActionType.DELETE
        });
    }, function (error) {
        dispatchError("delete_class_student_by_id", dispatch, error);
    })
};

export const importStudentModal = (classId: number, file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.importStudent(classId, file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "import_student",
            page: "list_student_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("edit_student", dispatch, error);
    })
};

export const clearTempClassStudent = (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.clearTempImport(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "clear_temp_class_student",
            page: "list_student_modal",
            action: ActionType.DELETE
        });
    }, function (error) {
        dispatchError("clear_temp_class_student", dispatch, error);
    })
};

export const tempStudentPagination = (classId: number, keyword: string, validateStatus: string[], request: TempClassStudentUpdation | undefined, page: number, size: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    StudentAPI.tempStudentPagination(classId, keyword, validateStatus, request, page, size).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "temp_student_pagination",
            page: "list_student_modal",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("temp_student_pagination", dispatch, error);
    })
};

export const saveTempStudent = (classId: number, request: TempClassStudentUpdation | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    StudentAPI.saveTempStudent(classId, request).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "save_temp_student",
            page: "list_student_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                table: "save_temp_student",
                page: "list_student_modal",
                payload: error.response.data.additional
            });
        } else {
            dispatchError("save_temp_student", dispatch, error);
        }

    })
};

