import Select from 'react-select';

export const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: 'red'
      }
    })
  }
  export const customStateStyles = {
    control: (base: any, state: any) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      width:300,
      backgroundColor: state.selectProps.value?.value == 'SAVE' || state.selectProps.value?.value == 'NEW'?'#deffe9':'white',
      borderColor: 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: 'red'
      }
    })
  }
  export const greenStateStyles = {
    control: (base: any, state: any) => {
      return ({
        ...base,
        width:300,
        backgroundColor: state.selectProps.value?.value == 'SAVE' || state.selectProps.value?.value == 'NEW'?'#deffe9':'white',
      })
    } 
  }

export const SingleSearch = ((props: any) => {
    return (<div style={props.style}>
    <Select isDisabled={props.disabled} isSearchable={true} isMulti={false} placeholder={props.placeholder?props.placeholder:""}
    isClearable={props.isClearable}
    styles={ props.isError?customStyles:{} }
    onChange={ e=>props.onChange(props.id,e)} 
    value={props.value} 
    options={props.options} 
    /></div>
        );
});

export const MultiSearch = ((props: any) => {
  return (<div style={props.style}>
  <Select isDisabled={props.disabled} isSearchable={true} isMulti={true} placeholder=""
  isClearable={props.isClearable}
  styles={ props.isError?customStyles:{} }
  onChange={ e=>props.onChange(props.id,e)} 
  value={props.value} 
  options={props.options} 
  /></div>
      );
});