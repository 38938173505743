import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { Util } from './util';

const CLASS ="/api/v1/class";
const ELEARNING = "elearning";
const EXPORT ="/api/v1/class/export";

export const EXPORT_CLASS_URL = DEFAULT_SERVER+EXPORT;
export const EXPORT_CLASS_TRAINER_URL = DEFAULT_SERVER+'/api/v1/page/trainer/class-pagination/export'
const SEARCH_CLASS_URL = DEFAULT_SERVER + CLASS;
const ADD_CLASS_URL = DEFAULT_SERVER + CLASS;
const GET_CLASS_URL = DEFAULT_SERVER + CLASS;
const PRIVACY_CLASS_URL = DEFAULT_SERVER + CLASS;
const ELEARNING_CLASS_URL = DEFAULT_SERVER + CLASS;
const REFRESH_ELEARNING_COURSE_URL = DEFAULT_SERVER + "/api/v1/elearning";

export class ClassAPI {
        
    public static list = async (projectId: number, partnerId: number,fromDate : Date | null, toDate: Date | null,page: number,limit: number,session:string|null,sort:string|null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_CLASS_URL;
            var queryParams: string[] = [];
            if(!isNaN(projectId)){
                queryParams.push(`project_id=${projectId}`)
            }
            if(!isNaN(partnerId)){
                queryParams.push(`partner_id=${partnerId}`)
            }
            if(fromDate!=null){
                queryParams.push(`from_date=`+ encodeURIComponent(Util.getLocalISOString(fromDate)))
            }
            if(toDate!=null){
                queryParams.push(`to_date=`+ encodeURIComponent(Util.getLocalISOString(toDate)))
            }
            if(session){
                queryParams.push(`session=${session}`)
            }
            queryParams.push(`page=${page}`)
            queryParams.push(`limit=${limit}`)
            if(sort){
                queryParams.push(`sort=${sort}`)
            }
            
            apiClient.get(s+ '?' + queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list class error:", err);
                return reject(err); 
            })
        })
    }

    public static add = async (item:any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ADD_CLASS_URL;
            apiClient.post(s,
                //payload
                item
                ,{//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("add class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("add class error:", err);
                return reject(err); 
            })
        })
    }

    public static update = async (id:number,item:any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ADD_CLASS_URL+"/"+id;
            apiClient.patch(s,
                //payload
                item
                ,{//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("update class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("update class error:", err);
                return reject(err); 
            })
        })
    }
    
    public static getClass = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_CLASS_URL+"/"+id;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get class error:", err);
                return reject(err); 
            })
        })
    }

    public static deleteClass = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_CLASS_URL+"/"+id;
            apiClient.delete(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("delete class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("delete class error:", err);
                return reject(err); 
            })
        })
    }

    public static getClassCourseWithName = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_CLASS_URL+"/course/"+id;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get class course with name response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get class course with name error:", err);
                return reject(err); 
            })
        })
    }

    public static uploadClassBanner = async (classId: string,file: FileList): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file[0]);

            var s=PRIVACY_CLASS_URL+"/class_banner/"+classId;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("upload class banner response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("upload class banner error:", err);
                return reject(err); 
            })
        })
    }

    public static uploadClassCourseBanner = async (classCourseId: string,file: FileList): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file[0]);

            var s=PRIVACY_CLASS_URL+"/class_course_banner/"+classCourseId;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("upload class course banner response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("upload class course banner error:", err);
                return reject(err); 
            })
        })
    }
    public static uploadPrivacy = async (classCourseId: string,file: FileList): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file[0]);

            var s=PRIVACY_CLASS_URL+"/privacy/"+classCourseId;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("upload privacy response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("upload privacy error:", err);
                return reject(err); 
            })
        })
    }


    public static listELearning = async (classId: number, classCourseId: number,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ELEARNING_CLASS_URL+"/elearning/"+classId+"/"+classCourseId+"?page="+page+"&limit="+limit; 
            console.log(s);
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list elearning response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list elearning error:", err);
                return reject(err); 
            })
        })
    }

    public static importELearning = async (classId: number, classCourseId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ELEARNING_CLASS_URL+"/elearning/"+classId+"/"+classCourseId; 
            apiClient.post(s,
                //payload
                {
                }
                ,{//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("import elearning response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("import elearning error:", err);
                return reject(err); 
            })
        })
    }

    public static refreshElearningCourse = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=REFRESH_ELEARNING_COURSE_URL;
            apiClient.post(s,
                //payload
                {
                }
                ,{//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("refresh elearning course response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("refresh elearning course error:", err);
                return reject(err); 
            })
        })
    }
}