import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { dispatchError } from '../search/actions'; 

export const liststudent = (classId:number,classCourseId: number,page:number,limit:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.listELearning(classId,classCourseId,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_student",
            page: "edit_elearning",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_elearning",dispatch,error);
    })
};

export const importstudent = (classId: number,classCourseId:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ClassAPI.importELearning(classId,classCourseId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "import_student",
            page: "edit_elearning",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_elearning",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_elearning",dispatch,error);
    })
};

export const refreshElearningCourse = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.refreshElearningCourse().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "refresh_elearning_course",
            page: "elearning_course",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("elearning_course",dispatch,error);
    })
};