import React, { useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
type Props = ModalProps & {
}

const PartnerAnalyzeRankingResetModal = (props: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [action, setAction] = useState<"reset" | "back" | null>(null);

    // Helper function to check if data has changed
    const hasDataChanged = (initial: any, current: any): boolean => {
        return JSON.stringify(initial) !== JSON.stringify(current);
    };

    // Modal Handlers
    const handleConfirm = () => {
        setIsModalOpen(false);
        props.onConfirm();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
                {...props}
                dialogClassName="modal-40w"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Unsaved Changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You have unsaved changes. Are you sure you want to proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={props.confirmReset}>
                        Proceed
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

export default PartnerAnalyzeRankingResetModal