import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'

interface MessageBoxProps {
    messages: string[];
    maxLine?: number; // Optional, default length for truncation
    truncated?: boolean;
    showExpandIcon?: boolean;
}

const DynamicSuggestion: React.FC<MessageBoxProps> = ({ messages, maxLine: maxLength = 3, truncated=true, showExpandIcon=true }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTruncated, setIsTruncated] = useState(truncated);
    const [expand, setExpand] = useState(true)
    const tagLevel1 = "level_1";

    // Handlers
    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    // Combine messages into a styled JSX structure
    const fullMessage = (
    <div>
        {messages.map((message, index) => (
        <div
            key={index}
            className={`col-12 ${message.includes(tagLevel1) ? 'pl-4' : 'pl-5'}`}>
                {message.includes("<br/>") ? <br/> : message.substring(9, message.length)}
        </div>
        ))}
    </div>
    );


    const previewMessage = messages.join(" ").length > maxLength
    ? (
        <div>
            {messages.slice(0, maxLength).map((message, index) => (
            <div
                key={index}
                className={`col-12 ${message.includes(tagLevel1) ? 'pl-4' : 'pl-5'}`}>
                    {message.substring(9, message.length)}
                    {index+1 === maxLength && 
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleModalOpen()} >{" "}... ดูเพิ่มเติม</span>
                    }
            </div>
            ))}
        </div>
        )
    : fullMessage;

    return (
        <div style={{width:'100%'}}>
            <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {(!showExpandIcon) && 
                    <div style={{marginLeft: "30px"}}>คำอธิบายการใช้งาน</div>
                }
                {(showExpandIcon) &&
                <>
                    {expand === false && <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำอธิบายการใช้งาน</div>}
                    {expand === true && <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำอธิบายการใช้งาน</div>}
                </>}
            </div>
            {expand && (
            <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                <div className='row'>
                    {isTruncated ? previewMessage : fullMessage}
                </div>
            </div>
            )}
            
            {/* Modal for Full Message */}
            <Modal show={isModalOpen} onHide={handleModalClose} 
                dialogClassName="modal-60w"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>คำอธิบายการใช้งาน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {fullMessage}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DynamicSuggestion