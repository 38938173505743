import { ClassStudent, TempClassStudentUpdation } from '../model/response/student-list';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { StudentType } from './define';

const STUDENT = "/api/v1/student/class";
const STUDENT_PAGINATION = "/api/v1/student/type-list/class/pagination";
const STUDENT_EDIT = "/api/v1/student/type-list/class/edit-by-id";
const STUDENT_DELETE = "/api/v1/student/type-list/class/delete-by-id";
const WALKIN = "/api/v1/student/walkin/class";
const TEMPLATE = "/api/v1/student/template";
const STUDENT_TYPE_LIST = "/api/v1/student/type-list/class";

const STUDENT_URL = DEFAULT_SERVER + STUDENT;
const STUDENT_PAGINATION_URL = DEFAULT_SERVER + STUDENT_PAGINATION;
const STUDENT_EDIT_URL = DEFAULT_SERVER + STUDENT_EDIT;
const STUDENT_DELETE_URL = DEFAULT_SERVER + STUDENT_DELETE;
const WALKIN_URL = DEFAULT_SERVER + WALKIN;

//
const STUDENT_TYPE_LIST_URL = DEFAULT_SERVER + STUDENT_TYPE_LIST;



export const TEMPLATE_URL = DEFAULT_SERVER + TEMPLATE;
export const EXPORT_EXCEL_IMPORT_URL = STUDENT_TYPE_LIST_URL;

export class StudentAPI {

    public static list = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_URL + "/" + classId;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list student error:", err);
                    return reject(err);
                })
        })
    }

    public static studentPagination = async (classId: number, name: string, page: number, size: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var params: string[] = [];
            if (page) { params.push(`page=${page}`); }
            if (size) { params.push(`size=${size}`); }
            if (classId) { params.push("classId=" + classId); }
            if (name) { params.push("name=" + name); }
            var queryParams = params.join("&");
            var s = STUDENT_PAGINATION_URL + "?" + queryParams;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list student pagination error:", err);
                    return reject(err);
                })
        })
    }

    public static editStudentById = async (item: ClassStudent): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_EDIT_URL;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("edit student error:", err);
                    return reject(err);
                })
        })
    }

    public static deleteStudentById = async (studentId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_DELETE_URL + "/" + studentId;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("delete student response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("delete student error:", err);
                    return reject(err);
                })
        })
    }

    public static import = async (classId: number, studentType: StudentType, file: FileList): Promise<any> => {
        var formData = new FormData();
        formData.append("file", file[0]);
        formData.append("student_type", studentType);

        return new Promise(function (resolve, reject) {
            var s = STUDENT_URL + "/" + classId;
            apiClient.post(s, formData,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("import student response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("import student error:", err);
                    return reject(err);
                })
        })
    }

    public static save = async (classId: number, item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_URL + "/" + classId;
            apiClient.patch(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("save student error:", err);
                    return reject(err);
                })
        })
    }

    public static listwalkin = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = WALKIN_URL + "/" + classId;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list walkin error:", err);
                    return reject(err);
                })
        })
    }

    public static savewalkin = async (classId: number, item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = WALKIN_URL + "/" + classId;
            apiClient.patch(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("save walkin response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("save walkin error:", err);
                    return reject(err);
                })
        })
    }


    public static importStudent = async (classId: number, file: FileList): Promise<any> => {
        var formData = new FormData();
        formData.append("file", file[0]);

        return new Promise(function (resolve, reject) {
            var s = STUDENT_TYPE_LIST_URL + "/" + classId + "/import";
            apiClient.post(s, formData,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("import student type list response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("import student type list error:", err);
                    return reject(err);
                })
        })
    }

    public static clearTempImport = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_TYPE_LIST_URL + "/" + classId + "/delete";
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("clear temp import student response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("clear temp import student error:", err);
                    return reject(err);
                })
        })
    }

    public static tempStudentPagination = async (classId: number, keyword: string, validateStatus: string[], request: TempClassStudentUpdation | undefined, page: number, size: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParam = StudentAPI.getParamImportListStudent(classId, keyword, validateStatus, page, size);
            var s = STUDENT_TYPE_LIST_URL + '/' + classId + '/' + 'pagination' + "?" + queryParam;
            apiClient.patch(s, request,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("temp student pagination error:", err);
                    return reject(err);
                })
        })
    }

    public static saveTempStudent = async (classId: number, request: TempClassStudentUpdation | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = STUDENT_TYPE_LIST_URL + "/" + classId + "/save";
            apiClient.post(s, request,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("save temp student response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("save temp student error:", err);
                    return reject(err);
                })
        })
    }


    public static getParamImportListStudent = (classId: number, keyword: string, validateStatus: string[], page: number, size: number) => {
        var queryParams: string[] = [];
        if (page) { queryParams.push(`page=${page}`); }
        if (size) { queryParams.push(`size=${size}`); }
        if (keyword) { queryParams.push("keyword=" + keyword); }
        if (validateStatus && validateStatus.length > 0) { queryParams.push(validateStatus.map((i) => `validateStatus=${encodeURIComponent(i)}`).join('&')); }
        return queryParams.join('&');
    }



}