import React, { useEffect, useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { PartnerRankingQuestion, scoreList } from '../../../../model/response/partner-ranking';
import { SingleSearch } from '../../../tab/search-component';
type Props = {
    questionList: PartnerRankingQuestion[];
    onAnswerUpdate: (questionIndex: number, subQuestionIndex: number, answer: number) => void;
}

const PartnerAnalyzeRankingResult = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [questionList, setQuestionList] = useState(props?.questionList || []); // Default to empty array

      
    useEffect(() => {
      setQuestionList(props?.questionList || []); // Update local state when prop changes
    }, [props?.questionList]);


    const getScore = (questionIndex: number, subQuestionIndex: number) => {
      const answer = questionList[questionIndex]?.subQuestionList[subQuestionIndex]?.answer ?? null;
      if (answer != null) {
        return scoreList.find((score) => score.value === answer) || null;
      }
      return null;
    };

  const updateScore = (
      selectedScore: any,
      questionIndex: number,
      subQuestionIndex: number
    ) => {
      // Create a deep copy of questionList
      const updatedQuestionList = [...questionList];
      const updatedSubQuestions = [
        ...updatedQuestionList[questionIndex].subQuestionList,
      ];
    
      // Update the specific subQuestion's answer
      updatedSubQuestions[subQuestionIndex] = {
        ...updatedSubQuestions[subQuestionIndex],
        answer: selectedScore?.value || null,
      };
    
      // Update the questionList with the modified subQuestionList
      updatedQuestionList[questionIndex] = {
        ...updatedQuestionList[questionIndex],
        subQuestionList: updatedSubQuestions,
      };
    
      setQuestionList(updatedQuestionList); // Update state
      props.onAnswerUpdate(questionIndex, subQuestionIndex, selectedScore?.value ?? null); // Notify parent
    };

    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำถามสำหรับการประเมิน</div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำถามสำหรับการประเมิน</div>
                    }
                </div>
                {expand && (
                    <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                        {questionList?.map((question, index) => (
                            <div className="row mt-1 mb-2" key={index} style={{ display: "flex" }}>
                                <label className="col-3 mt-2" style={{ fontWeight: 600, flex: "0 0 30%"/*30% width*/ }}>{index+1}. {question.questionText} ({question.questionPercentage}%)</label>
                                <div className='gray-button' style={{ padding: "15px", borderRadius: "5px", flex: "0 0 70%"/*70% width*/ }}>
                                    {question.subQuestionList?.map((subQuestion, subIndex) => (
                                        <div className="row mt-1" key={`${index}-${subIndex}`}>
                                            <label className="col-8">{index+1}.{subIndex+1} {subQuestion.questionText} ({subQuestion.questionPercentage}%)</label>
                                            <SingleSearch
                                                isSearchable={true}
                                                isMulti={false}
                                                placeholder="-"
                                                onChange={(id: string, selectedOption: any) => updateScore(selectedOption, index, subIndex)}
                                                value={getScore(index, subIndex)}
                                                options={scoreList}
                                                style={{ width: "150px", paddingBottom: "10px", textAlign: "right" }}
                                                disabled={false}
                                            />
                                        </div>
                                    ))}
                                </div>
                                
                          </div>
                        ))}
                    
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default PartnerAnalyzeRankingResult