import React, { useEffect, useState } from 'react'
import MainMenu from '../../menu'
import loadinglogo from '../../../../img/loading.gif';
import PartnerTab from '../../../tab/partner-tab';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PackageInteresting } from '../../../../model/response/partner-package';
import PartnerPackageInteresting from './PartnerPackageInteresting';
import { getPackageSubcategoryInterest } from '../../../../store/partner/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerPackageModal from './PartnerPackageModal';
type Props = {}

const PartnerPackage = (props: Props) => {
    const [init, setInit] = useState(1);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();
    const [interestingItem, setInterestingItem] = useState<PackageInteresting[]>([])
    const [knowledgeItem, setKnowledgeItem] = useState<PackageInteresting>();
    const [showPartnerPackageModal, setShowPartnerPackageMOdal] = useState<boolean>(false);
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "get_parner_package") {
                        setInterestingItem(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(getPackageSubcategoryInterest(parseInt(id)));
        return unsubscribe;
    }, []);
    const handleProfile = () => {
        history.push("/admin/partner/profile/" + id);
    }
    const handleActivityOrganizeTraining = () => {
        history.push("/admin/partner/activity/" + id + "/organize-training");
    }
    const handlePackage = () => {
        history.push("/admin/partner/package/" + id);
    }
    const handleAnalyzeRanking = () => {
        history.push("/admin/partner/analyze-ranking/" + id);
    }
    const hidePartnerPackageModal = () => {
        setShowPartnerPackageMOdal(false)
        setKnowledgeItem(undefined);
    }
    const openPartnerPackageModal = (item: PackageInteresting) => {
        setKnowledgeItem(item as any);
        setShowPartnerPackageMOdal(true)
    }
    const handleBack = () => {
        history.push('/admin/search/partner')
    }
    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">ข้อมูลองค์กร</h4></div>
                        {(init === 1) &&
                            <>
                                <br />
                                {PartnerTab(3, true, handleProfile, handleActivityOrganizeTraining, handlePackage, handleAnalyzeRanking)}
                                <br />
                                <PartnerPackageInteresting items={interestingItem} openPartnerPackageModal={openPartnerPackageModal} />
                            </>
                        }
                        <div className='d-flex mt-3'>
                            <div className='ml-auto mb-3'>
                                <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                    onClick={() => handleBack()} >
                                    Back
                                </button>
                            </div>
                        </div>
                        {(((init < 1) || (loading)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                </div>
                <PartnerPackageModal show={showPartnerPackageModal} knowledgeItem={knowledgeItem} onHide={() => hidePartnerPackageModal()} />
                <AlertContainer floatingTime={3000} />
            </MainMenu>
        </div>
    )
}

export default PartnerPackage