import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AlertContainer, alert } from 'react-custom-alert';
import { getPartnerRankingYear, partnerRankingPagination } from '../../../../store/partner/actions';
import MainMenu from '../../menu';
import { GradeDropdownList, PartnerRankingBody, PartnerRankingImport, PartnerRankingYear } from '../../../../model/response/partner-ranking';
import { queryclear } from '../../../../store/search/actions';
import PartnerRankingTab from '../../../tab/partner-ranking-tab';
import DynamicSuggestion from './DynamicSuggestion';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { SingleSearch } from '../../../tab/search-component';
import { SingleValue } from 'react-select';
import loadinglogo from '../../../../img/loading.gif';
import { Util } from '../../../../api/util';
import { Button } from 'react-bootstrap';
import { SearchPanel, TableHeaderBlack } from '../../../../style/tableStyle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CustomPage } from '../../../share/CustomPage';
import excellogo from '../../../../img/excel.png';
import { EXPORT_HPARTNER_RANKING_TEMPLATE_URL, EXPORT_HPARTNER_RANKING_URL, PartnerAPI } from '../../../../api/partner-api';
import { getAccessToken } from '../../../../store/auth/selectors';
import { MultiSelectComponent } from '../../../tab/multi-select-component';
import RankingRankingImportModal from './RankingRankingImportModal';

type Props = {}

const PartnerRanking = (props: Props) => {
    const token = useSelector(getAccessToken);
    const [init, setInit] = useState(0);
    const [initYearDropdown, setInitYearDropdown] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();

    const [expand, setExpand] = useState(true)
    const [partnerName, setPartnerName] = useState<string>();
    const [grade, setGrade] =  useState<any[]>([]);
    const [createYear, setCreateYear] = useState<SingleValue<{ label: string, value: number }>>()
    const [page, setPage] = useState(0);
    const [size, setSize] = useState<number>(10);
    const [totalElement, setTotalElement] = useState<number>(0);

    const [items, setItem] = useState<Array<PartnerRankingBody> | null>(null);
    const [yearDropdown, setYearDropdown] = useState<Array<PartnerRankingYear> | null>(null);

    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [partnerRankingImport, setPartnerRankingImport] = useState<PartnerRankingImport>();
    const [isReadyToSave, setIsReadyToSave] = useState(false);
    const [showFailureTable, setShowFailureTable] = useState(false);
    
    const tagLevel1 = "<level_1>";
    const messages = [
        tagLevel1+"1. เพื่อใช้ดูข้อมูลการจัดลำดับองค์กรพันธมิตร สำหรับบริหารจัดการความสัมพันธ์กับองค์กรพันธมิตรได้อย่างเหมาะสม",
        tagLevel1+"2. สามารถเลือกชื่อองค์กร Grade และ ปีที่ประเมิน โดยระบบจะแสดงข้อมูลตามที่เงื่อนไขที่เลือก​",
        tagLevel1+"3. หากต้องการ Import ข้อมูลองค์กรพันธมิตร สามารถกด + เพื่อ Import ข้อมูล Partner Ranking เข้าระบบได้",
    ];

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) ) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_partner_ranking") {
                        setItem(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setInit(1);
                    }
                    if (res.table === "list_partner_ranking_year") {
                        setYearDropdown(res.data);
                        setInitYearDropdown(1);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                manageTableImportPartnerRanking(res);
                dispatch(queryclear());
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }
        })
        dispatch(partnerRankingPagination(undefined, undefined, undefined, page, size));
        dispatch(getPartnerRankingYear());
        setPartnerRankingImport(undefined);
        setIsReadyToSave(false);
        setShowFailureTable(false);
        return unsubscribe;
    }, []);

    const manageTableImportPartnerRanking = (res: any) => {
        if (res.table === "import_partner_ranking") {
            if (res.data.saveFile && res.data.validateSucceed) {
                alert({ message: 'Saved', type: 'success' });
                dispatch(partnerRankingPagination(undefined, undefined, undefined, page, size));
                hideImportModal();
            } else if (res.data.validateSucceed) {
                setPartnerRankingImport(res.data);
                setIsReadyToSave(true);
                setShowFailureTable(true);
            } else {
                setPartnerRankingImport(res.data);
                setIsReadyToSave(false);
                setShowFailureTable(true);
            }
        }
    }

    const createYearFilter = (yearDropdown: PartnerRankingYear[] | null) => {
        var res: any[] = [];
        if (yearDropdown) {
            for (var i = 0; i < yearDropdown.length; i++) {
                res.push({ label: yearDropdown[i].year , value: yearDropdown[i].year });
            }
            return res;
        } else {
            return [];
        }
    }

    const handleRanking = () => {
        history.push("/admin/search/partner-ranking");
    }
    const handleRemarketing = () => {
        history.push("/admin/search/partner-remarketing");
    }


    const onPageChange = (value: number) => {
        setPage(value);
        dispatch(partnerRankingPagination(undefined, undefined, undefined, value, size));
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);
        dispatch(partnerRankingPagination(undefined, undefined, undefined, pageNo, value));
    }

    const handleClear = () => {
        setPage(0);
        setSize(10);
        setPartnerName("");
        setGrade([]);
        setCreateYear(null);
        dispatch(queryclear());
        dispatch(partnerRankingPagination(undefined, undefined, undefined, page, size));
      }

    const handleSubmit = () => {
        dispatch(queryclear());
        dispatch(partnerRankingPagination(partnerName, grade?.map(a => a.value), createYear?.value, page, size));
    }

    const handleOpenPartnerAnalyze = (partnerId: number) => {
        window.open(`/admin/partner/analyze-ranking/${partnerId}`, '_blank');
    }

    const excelElement: any = (isDefaultTemplate: boolean) => {
        return <Button type="button" className="btn btn-success green-button ml-1" onClick={() => handleExcel(isDefaultTemplate)} >
                    <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
                </Button>
    }

    const handleExcel = (isDefaultTemplate: boolean) => {
        if (isDefaultTemplate) {
            window.open(EXPORT_HPARTNER_RANKING_TEMPLATE_URL + "?token=" + encodeURIComponent(token));
        } else {
            window.open(EXPORT_HPARTNER_RANKING_URL + "?" + PartnerAPI.getParamPartnerRanking(partnerName, grade?.map(a => a.value), createYear?.value, undefined, undefined) + "&token=" + encodeURIComponent(token));
        }
    }

    const customHeader = () => {
        return <div style={{ fontWeight: 600, fontSize:30 }}>
            <AddCircleOutlineIcon className="yellow mr-2" style={{cursor: 'pointer',fontSize:35}} onClick={() => openImportModal()} />
            Import ข้อมูล Partner Ranking
        </div>;
    }

    const openImportModal = () => {
        dispatch(queryclear());
        setShowImportModal(true)
        setPartnerRankingImport(undefined);
        setIsReadyToSave(false);
        setShowFailureTable(false);
    }
    const hideImportModal = () => {
        dispatch(queryclear());
        setShowImportModal(false)
        setPartnerRankingImport(undefined);
        setIsReadyToSave(false);
        setShowFailureTable(false);
    }

    return (
        <div>
            <MainMenu>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">Partner Ranking & Remarketing</h4></div>
                    {(init+initYearDropdown === 2) &&
                        <>
                            <br />
                            {PartnerRankingTab(1, handleRanking, handleRemarketing)}
                            <br />
                            <div style={{ display: 'flex'}}>
                                <DynamicSuggestion 
                                    messages={messages}
                                    truncated={false} />
                                <span style={{marginLeft: '15px'}}></span>
                            </div>
                            <br />

                            <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                {((expand === undefined) || (expand === false)) &&
                                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>ข้อมูล Partner Ranking</div>
                                }
                                {(expand === true) &&
                                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>ข้อมูล Partner Ranking</div>
                                }
                            </div>
                            
                            {expand && ( <>
                                <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <form>
                                        <SearchPanel>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className="col-form-label">ชื่อองค์กร</label>
                                                    <input className="form-control" value={partnerName} onChange={(e) => { setPartnerName(e.currentTarget.value) }} />
                                                </div>
                                               
                                                <div className="col-6">
                                                    <label className="col-form-label">Grade</label>
                                                    <MultiSelectComponent
                                                        options={GradeDropdownList}
                                                        value={grade}
                                                        onChange={setGrade}
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <label className="col-form-label">ปีที่ประเมิน</label>
                                                    <SingleSearch
                                                        isSearchable={true} isMulti={false} isClearable={true}
                                                        onChange={(id: string, e: any) => setCreateYear(e)}
                                                        value={createYear}
                                                        options={createYearFilter(yearDropdown)} />
                                                </div>
                                            </div>

                                            <br/>
                                            <div className="row" style={{ justifyContent: "center" }}>
                                                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                                    onClick={() => handleClear()} >
                                                    Clear
                                                </button>&nbsp;
                                                <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                                    onClick={() => handleSubmit()} disabled={loading}>
                                                    <div>
                                                        {(loading) ? 
                                                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                        : <>Search</>}
                                                    </div>
                                                </button>
                                            </div>
                                        </SearchPanel>
                                        <br />
                                    </form>
                                </div>

                                <br/>
                                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={excelElement(false)} customHeader={customHeader()}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className='text-left'>ผู้ประเมิน/วันที่ประเมิน</th>
                                                <th className='text-left'>ชื่อองค์กร</th>
                                                <th className='text-left'>ประเภทองค์กร</th>
                                                <th className='text-left'>หมวดหมู่</th>
                                                <th className='text-left'>Grade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items?.map((item: PartnerRankingBody, index: number) => (
                                                <tr key={'answer_' + item.answerId}>
                                                    <td></td>
                                                    <td className='text-left'> {item.createBy}<br/>{Util.datetimetostr(new Date(item.createDate))} </td>
                                                    <td className='text-left' onClick={()=>handleOpenPartnerAnalyze(item.partnerId)} style={{cursor: 'pointer'}}> {item.partnerName} </td>
                                                    <td className='text-left'> {item.organizationTypeName} </td>
                                                    <td className='text-left'> {item.organizationGroupName} </td>
                                                    <td className='text-left'> {item.grade} </td>
                                                </tr>
                                            ))}
                                            {!items || items?.length == 0 && (
                                                <tr>
                                                    <td colSpan={10}>ไม่มีรายการ</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>
                                <br />
                                <br />
                            </>)}
                        </>
                    }
                </div>
                <RankingRankingImportModal 
                    show={showImportModal} onHide={() => hideImportModal()} 
                    excelelement={excelElement(true)} 
                    item={partnerRankingImport}
                    showFailureTable={showFailureTable}
                    isReadyToSave={isReadyToSave} />
                <AlertContainer floatingTime={3000} />
            </MainMenu>
        </div>
    );

}

export default PartnerRanking