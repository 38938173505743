import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { SearchPanel, TableHeaderBlack } from "../../../../../style/tableStyle";
import { MultiSelectComponent } from "../../../../tab/multi-select-component";
import { Button, InputGroup } from "react-bootstrap";
import { ThaiDateTimePicker } from "../../../../tab/thai-datetime-picker";
import CancelIcon from '@material-ui/icons/Cancel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { CustomPage } from "../../../../share/CustomPage";
import loadinglogo from '../../../../../img/loading.gif';
import { GradeDropdownList, PartnerRankingBody } from "../../../../../model/response/partner-ranking";
import excellogo from '../../../../../img/excel.png';
import React from "react";
import { listorganization, listorganizationGroup, partnerRemarketingTargetPagination } from "../../../../../store/partner/actions";
import { useDispatch, useSelector, useStore } from "react-redux";
import { SingleSearch } from "../../../../tab/search-component";
import { queryclear } from "../../../../../store/search/actions";
import { PartnerRemarketingTargetPagination } from "../../../../../model/response/partner-remarketing";
import iconTaxiMail from '../../../../../img/taximail.png';
import { MultiValue, SingleValue } from "react-select";
import { set } from "date-fns";
import { OrgGroup } from "../../../../../model/response/organization.model";
import { createListedComapny } from "../../../../../api/define";
import { ProjectGroupResponse } from "../../../../../model/response/project.model";
import { listGroupActive } from "../../../../../store/editprojectinfo/action";
import { listproject } from "../../../../../store/class/actions";
import { listCourseByPartnerRemarketing, listcourse } from "../../../../../store/editclass/actions";
import { EXPORT_TARGET_PARTNER_REMARKETING, PartnerAPI } from "../../../../../api/partner-api";
import { getAccessToken } from "../../../../../store/auth/selectors";

type Props = {}

const PartnerRemarketingTarget = (props: Props) => {

    const dispatch = useDispatch();
    const store = useStore();
    const token = useSelector(getAccessToken);
    const [error, setError] = useState('');
    const [init, setInit] = useState(0)
    const [updating, setUpdating] = useState(false)
    const [upload, setUpload] = useState(false);

    const [items, setItems] = useState<PartnerRemarketingTargetPagination[]>([]);

    const [expand, setExpand] = useState(true)
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState<number>(10);
    const [totalElement, setTotalElement] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string | null>('');
    const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');

    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([]);
    const [orgGroupItem, setOrgGroupItem] = useState<OrgGroup[]>([])
    const [projectGroupList, setProjectGroupList] = useState<ProjectGroupResponse[]>([]);
    const [projectItem, setProjectItem] = useState<Array<any>>([])
    const [courseItem, setCourseItem] = useState<Array<any>>([])

    const [orgType, setOrgType] = useState<SingleValue<{ label: string, value: number }> | null>();
    const [orgGroup, setOrgGroup] = useState<SingleValue<{ label: string, value: number }> | null>()
    const [companyType, setCompanyType] = useState<MultiValue<{ label: string, value: string }> | null>([]);
    const [grade, setGrade] = useState<any[]>([]);
    const [projectGroup, setProjectGroup] = useState<SingleValue<{ label: string, value: number }> | null>();
    const [projectName, setProjectName] = useState<MultiValue<{ label: string, value: number }> | null>([]);
    const [course, setCourse] = useState<MultiValue<{ label: string, value: number }> | null>([]);
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)

    const [months, setMonths] = useState<number>();
    const [days, setDays] = useState<number>();

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            // console.log(res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_target_partner_remarketing") {
                        setItems(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setInit(1);
                    } else if (res.table === "org_type") {
                        setInit(1);
                        setOrgTypeItem(res.data);
                    } else if (res.table === "org_type_group") {
                        setOrgGroupItem(res.data)
                    } else if (res.table === "list_project_group_active") {
                        setProjectGroupList(res.data);
                    } else if (res.table === "project") {
                        setProjectItem(res.data);
                    } else if (res.table === "list_course_partner_remarketing") {
                        setCourseItem(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                setUpload(false);
                dispatch(queryclear());
            } else if (res.isFailed) {
                setError(res.data.message);
                setUpload(false);
                setLoading(false);
                // alert({ message: res.data.message, type: 'error' });
            }
        })
        return unsubscribe;
    }, [])

    useEffect(() => {
        // dispatch(partnerRemarketingTargetPagination(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, page, size, sortBy + ',' + sortType));
        dispatch(listorganization(0, 0));
        dispatch(listorganizationGroup());
        dispatch(listGroupActive());
        dispatch(listproject());
        dispatch(listCourseByPartnerRemarketing());
    }, []);

    const createOrgTypeSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < orgTypeItem.length; i++) {
            res.push({ label: orgTypeItem[i].name, value: orgTypeItem[i].id });
        }
        return res;
    }

    const createOrgGroupSearchFilter = () => {
        var res: any[] = [];
        if (orgType) {
            for (var i = 0; i < orgTypeItem.length; i++) {
                if (orgTypeItem[i].id === orgType.value) {
                    for (var j = 0; j < orgTypeItem[i].orgGroup.length; j++) {
                        var gitem = orgTypeItem[i].orgGroup[j];
                        res.push({ label: gitem.name, value: gitem.id });
                    }
                }
            }
            return res;
        } else {
            for (var i = 0; i < orgGroupItem.length; i++) {
                res.push({ label: orgGroupItem[i].name, value: orgGroupItem[i].id });

            }
            return res;
        }
    }

    const createProjectGroupFilter = (): { label: string, value: number }[] => {
        var res: any[] = [];
        for (var i = 0; i < projectGroupList.length; i++) {
            res.push({ label: projectGroupList[i].shortName + ":" + projectGroupList[i].name, value: projectGroupList[i].id });
        }
        return res;
    }

    const createProjectSearchFilter = () => {
        var res: any[] = [];
        if (projectGroup) {
            for (var i = 0; i < projectItem.length; i++) {
                if (projectItem[i].projectGroupId === projectGroup.value) {
                    res.push({ label: projectItem[i].code + " : " + projectItem[i].name, value: projectItem[i].id });
                }
            }
            return res;
        } else {
            for (var i = 0; i < projectItem.length; i++) {
                res.push({ label: projectItem[i].code + " : " + projectItem[i].name, value: projectItem[i].id });
            }
            return res;
        }
    }

    // const createProjectSearchFilter = () => {
    //     var res: any[] = [];
    //     for (var i = 0; i < projectItem.length; i++) {
    //         res.push({ label: projectItem[i].code + " : " + projectItem[i].name, value: projectItem[i].id });
    //     }
    //     return res;
    // }

    const createCourseSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < courseItem.length; i++) {
            res.push({ label: courseItem[i].shortName + ":" + courseItem[i].name, value: courseItem[i].id });
        }
        return res;
    }

    const handleFromDate = (e: Date | null) => {
        setFromDate(e);
    }

    const handleToDate = (e: Date | null) => {
        setToDate(e);
    }

    useEffect(() => {
        calculateDate();
    }, [fromDate, toDate]);

    const calculateDate = () => {
        console.log(fromDate, toDate);
        if (fromDate == null || toDate == null) {
            setMonths(undefined);
            setDays(undefined);
            return false;
        }
        if (new Date(fromDate) > new Date(toDate)) {
            setMonths(undefined);
            setDays(undefined);
            return false;
        }

        // Convert dates to numeric timestamps (milliseconds since epoch)
        const startDate = new Date(fromDate).getTime();
        const endDate = new Date(toDate).getTime();

        // Calculate the difference in days and include both start and end dates
        const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

        // Calculate months and days
        const months = Math.floor(totalDays / 30);
        const days = totalDays % 30;

        console.log(months, days, totalDays);

        setMonths(months);
        setDays(days);
    };

    const handleClear = () => {
        setOrgType(null);
        setOrgGroup(null);
        setCompanyType([]);
        setGrade([]);
        setProjectGroup(null);
        setProjectName([]);
        setCourse([]);
        setFromDate(null);
        setToDate(null);
        setPage(0);
        setSubmitted(false);
        setIsFirst(true);
        setItems([]);
        setTotalElement(0);

        // dispatch(partnerRemarketingTargetPagination(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, page, size, sortBy + ',' + sortType));
    }

    const handleSubmit = () => {
        setIsFirst(false);
        if ((fromDate == null && toDate == null) || (fromDate != null && toDate != null)) {
            let listCompanyType: string[] = [];
            listCompanyType = setListCompanyType(listCompanyType);

            let listProjectNameId: number[] = [];
            listProjectNameId = setListProjectNameId(listProjectNameId);

            let listCourseId: number[] = [];
            listCourseId = setListCourseId(listCourseId);

            dispatch(queryclear());
            dispatch(partnerRemarketingTargetPagination(orgType?.value, orgGroup?.value, listCompanyType, grade?.map(a => a.value), listProjectNameId.length == 0 ? projectGroup?.value : undefined, listProjectNameId, listCourseId, fromDate || undefined, toDate || undefined, page, size, sortBy + ',' + sortType));
        } else {
            setSubmitted(true);
        }

    }


    const setListCompanyType = (e: string[]) => {
        if (companyType) {
            companyType.map((item) => {
                e.push(item.value);
            })
        }
        return e;
    }

    const setListProjectNameId = (e: number[]) => {
        if (projectName) {
            projectName.map((item) => {
                e.push(item.value);
            })
        }
        return e;
    }

    const setListCourseId = (e: number[]) => {
        if (course) {
            course.map((item) => {
                e.push(item.value);
            })
        }
        return e;
    }



    const onPageChange = (value: number) => {
        setPage(value);
        let listCompanyType: string[] = [];
        listCompanyType = setListCompanyType(listCompanyType);

        let listProjectNameId: number[] = [];
        listProjectNameId = setListProjectNameId(listProjectNameId);

        let listCourseId: number[] = [];
        listCourseId = setListCourseId(listCourseId);

        dispatch(partnerRemarketingTargetPagination(orgType?.value, orgGroup?.value, listCompanyType, grade?.map(a => a.value), projectGroup?.value, listProjectNameId, listCourseId, fromDate || undefined, toDate || undefined, value, size, sortBy + ',' + sortType));
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);

        let listCompanyType: string[] = [];
        listCompanyType = setListCompanyType(listCompanyType);

        let listProjectNameId: number[] = [];
        listProjectNameId = setListProjectNameId(listProjectNameId);

        let listCourseId: number[] = [];
        listCourseId = setListCourseId(listCourseId);

        dispatch(partnerRemarketingTargetPagination(orgType?.value, orgGroup?.value, listCompanyType, grade?.map(a => a.value), projectGroup?.value, listProjectNameId, listCourseId, fromDate || undefined, toDate || undefined, 0, value, sortBy + ',' + sortType));
    }

    const excelElement: any = (showOnly: boolean) => {
        if (showOnly) {
            return <Button type="button" className="btn btn-success green-button ml-1" style={{ cursor: 'default', pointerEvents: 'none' }} >
                <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
            </Button>
        } else {
            return <Button type="button" className="btn btn-success green-button ml-1" onClick={() => handleExcel()} >
                <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
            </Button>
        }
    }

    const handleExcel = () => {
        let listCompanyType: string[] = [];
        listCompanyType = setListCompanyType(listCompanyType);

        let listProjectNameId: number[] = [];
        listProjectNameId = setListProjectNameId(listProjectNameId);

        let listCourseId: number[] = [];
        listCourseId = setListCourseId(listCourseId);

        window.open(EXPORT_TARGET_PARTNER_REMARKETING + "?" + PartnerAPI.getParamPartnerRemarketingTarget(0, 0, "", orgType?.value, orgGroup?.value, listCompanyType, grade?.map(a => a.value), projectGroup?.value, listProjectNameId, listCourseId, fromDate || undefined, toDate || undefined) + "&token=" + encodeURIComponent(token));
    }

    const customHeader = () => {
        return <div style={{ cursor: "pointer", color: "#4176C7" }} onClick={() => window.open('https://www.taximail.com', '_blank')}>
            <img src={iconTaxiMail} width={25} height={25} className='mr-2' />
            go to taximail
        </div>;
    }

    const getErrorRequire = (value: any) => {
        return submitted && (value === null || value === undefined || value === '') ? true : false;
    }

    return (
        <React.Fragment>
            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expand === undefined) || (expand === false)) &&
                            <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>ข้อมูลกลุ่มเป้าหมาย</div>
                        }
                        {(expand === true) &&
                            <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>ข้อมูลกลุ่มเป้าหมาย</div>
                        }
                    </div>

                    {expand && (init === 1) && (<>
                        <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                            <form>
                                <SearchPanel>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="col-form-label">ประเภทองค์กร</label>
                                            <SingleSearch
                                                isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                                onChange={(id: string, e: any) => setOrgType(e)}
                                                value={orgType}
                                                options={createOrgTypeSearchFilter()}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">หมวดหมู่องค์กร</label>
                                            <SingleSearch
                                                isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                                onChange={(id: string, e: any) => setOrgGroup(e)}
                                                value={orgGroup}
                                                options={createOrgGroupSearchFilter()}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">ประเภทบริษัทจดทะเบียน</label>
                                            <MultiSelectComponent
                                                options={createListedComapny}
                                                value={companyType}
                                                onChange={setCompanyType}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">Grade</label>
                                            <MultiSelectComponent
                                                options={GradeDropdownList}
                                                value={grade}
                                                onChange={setGrade}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">กลุ่มโครงการ</label>
                                            <SingleSearch
                                                isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                                onChange={(id: string, e: any) => {
                                                    setProjectGroup(e);
                                                    setProjectName([]);
                                                }}
                                                value={projectGroup}
                                                options={createProjectGroupFilter()}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">ชื่อโครงการ</label>
                                            <MultiSelectComponent
                                                options={createProjectSearchFilter()}
                                                value={projectName}
                                                onChange={setProjectName}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">หลักสูตร</label>
                                            <MultiSelectComponent
                                                options={createCourseSearchFilter()}
                                                value={course}
                                                onChange={setCourse}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="col-form-label">ช่วงเวลาที่เข้าร่วมโครงการ</label>
                                            <div className="row" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <InputGroup className="col-5">
                                                    <label className='position-relative' style={{ width: "100%" }}>
                                                        <ThaiDateTimePicker
                                                            className={"form-control " + ((getErrorRequire(fromDate)) ? "is-invalid" : "")}
                                                            selected={fromDate}
                                                            onChange={(fromDate: any) => handleFromDate(fromDate)}
                                                        />
                                                        <div
                                                            className="search-icon"
                                                            style={{ visibility: (getErrorRequire(fromDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px" }}
                                                        >
                                                            <CancelIcon
                                                                style={{ visibility: (fromDate == null) ? "hidden" : "visible", color: "gray" }}
                                                                onClick={(e) => { setFromDate(null); }}
                                                            />
                                                            <CalendarTodayIcon style={{ color: "gray" }} />
                                                        </div>
                                                    </label>
                                                </InputGroup>
                                                <InputGroup className="col-5">
                                                    <label className='position-relative' style={{ width: "100%" }}>
                                                        <ThaiDateTimePicker
                                                            locale="th"
                                                            className={"form-control " + ((getErrorRequire(toDate)) ? "is-invalid" : "")}
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={toDate}
                                                            clear={() => setToDate(null)}
                                                            onChange={(toDate: any) => handleToDate(toDate)}
                                                        />
                                                        <div
                                                            className="search-icon"
                                                            style={{ visibility: (getErrorRequire(toDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px" }}
                                                        >
                                                            <CancelIcon
                                                                style={{ visibility: (toDate == null) ? "hidden" : "visible", color: "gray" }}
                                                                onClick={(e) => setToDate(null)}
                                                            />
                                                            <CalendarTodayIcon style={{ color: "gray" }} />
                                                        </div>
                                                    </label>
                                                </InputGroup>
                                            </div>
                                            {months != undefined && days != undefined && <div style={{ color: "#4176C7" }}> <i>{months}</i>  เดือน <i>{days}</i>  วัน</div>}
                                        </div>

                                    </div>

                                    <br />
                                    <div className="row" style={{ justifyContent: "center" }}>
                                        <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                            onClick={() => handleClear()} >
                                            Clear
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                            onClick={() => handleSubmit()} disabled={loading}>
                                            <div>
                                                {(loading) ?
                                                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                    : <>Search</>}
                                            </div>
                                        </button>
                                    </div>
                                </SearchPanel>
                            </form>
                        </div>

                        <br />
                        <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={excelElement(false)} customHeader={customHeader()}>
                            {!isFirst && <TableHeaderBlack striped hover className='mt-2'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-left'>ชื่อองค์กร</th>
                                        <th className='text-left'>ผู้ประสานงาน</th>
                                        <th className='text-left'>e-Mail</th>
                                        <th className='text-left'>เบอร์มือถือ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items?.map((item: PartnerRemarketingTargetPagination, index: number) => (
                                        <tr key={'answer_' + item.id}>
                                            <td></td>
                                            <td className='text-left'> {item.partnerName}</td>
                                            <td className='text-left'> {item.firstName} {item.lastName}</td>
                                            <td className='text-left'> {item.email} </td>
                                            <td className='text-left'> {item.mobile} </td>
                                        </tr>
                                    ))}
                                    {items?.length == 0 && (
                                        <tr>
                                            <td colSpan={10}>ไม่มีรายการ</td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableHeaderBlack>}
                        </CustomPage>
                        <br />
                        <br />

                    </>)}
                </div>

            </div>
        </React.Fragment>

    );


}

export default PartnerRemarketingTarget;