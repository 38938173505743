import { Button, InputGroup, Modal, ModalProps, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ThaiDateTimePicker } from "../../../../tab/thai-datetime-picker";
import CancelIcon from '@material-ui/icons/Cancel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoIcon from '@material-ui/icons/Info';
import { alert } from 'react-custom-alert';
import { saveRemargetingInformation } from "../../../../../store/partner/actions";
import { PartnerRemarketing } from "../../../../../model/response/partner-remarketing";
import { useDispatch } from "react-redux";
import { queryclear } from "../../../../../store/search/actions";

type Props = ModalProps & {
    item: PartnerRemarketing | undefined;
}

const RemarketingHistoryImportModal = (props: Props) => {

    const header: React.CSSProperties = {
        padding: "0px",
        display: "flex",
    };

    const rowConteant: React.CSSProperties = {
        paddingRight: "0px",
    };

    const textPercentage: React.CSSProperties = {
        fontSize: "20px",
        color: "#628295",
        alignContent: "center",
    };

    const dispatch = useDispatch();

    const [id, setId] = useState<number>(0);
    const [subject, setSubject] = useState<string>();
    const [partnerType, setPartnerType] = useState<string>();
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [quantitySent, setQuantitySent] = useState<number | null>(null);
    const [deliveryRate, setDeliveryRate] = useState<number | null>(null);
    const [bounceRate, setBounceRate] = useState<number | null>(null);
    const [openRate, setOpenRate] = useState<number | null>(null);
    const [clickRate, setClickRate] = useState<number | null>(null);

    const [deliveryRatePer, setDeliveryRatePer] = useState<number | null>(null);
    const [bounceRatePer, setBounceRatePer] = useState<number | null>(null);
    const [openRatePer, setOpenRatePer] = useState<number | null>(null);
    const [clickRatePer, setClickRatePer] = useState<number | null>(null);

    const [showDeliveryAndBounce, setShowDeliveryAndBounce] = useState<boolean>(true);
    const [showOpenRate, setShowOpenRate] = useState<boolean>(true);
    const [showClickReate, setShowClickRate] = useState<boolean>(true);

    const [submitted, setSubmitted] = useState<boolean>(false);


    useEffect(() => {
        setSubmitted(false);
        if (props.show && props.item) {
            console.log(props.item);
            setId(props.item.id);
            setSubject(props.item.subject);
            setPartnerType(props.item.targetGroup);
            handleFromDate(new Date(props.item.deliveryDate));
            setQuantitySent(props.item.quantitySent);
            setDeliveryRate(props.item.deliveryRate);
            setBounceRate(props.item.bounceRate);
            setOpenRate(props.item.openRate);
            setClickRate(props.item.clickRate);
            setDeliveryRatePer(props.item.deliveryRatePer);
            setBounceRatePer(props.item.bounceRatePer);
            setOpenRatePer(props.item.openRatePer);
            setClickRatePer(props.item.clickRatePer);
        }

    }, [props.show]);


    // Recalculate percentages when Sent Amount changes
    useEffect(() => {
        if (quantitySent && quantitySent > 0) {
            const newDeliveryPercentage = deliveryRate ? (deliveryRate / quantitySent) * 100 : 0;
            const newBouncePercentage = bounceRate ? (bounceRate / quantitySent) * 100 : 0;

            setDeliveryRatePer(Number(newDeliveryPercentage.toFixed(2)));
            setBounceRatePer(Number(newBouncePercentage.toFixed(2)));

            // Check if delivery + bounce exceeds 100%
            setShowDeliveryAndBounce(newDeliveryPercentage + newBouncePercentage <= 100);
        } else {
            setDeliveryRatePer(null);
            setBounceRatePer(null);
        }
    }, [quantitySent, deliveryRate, bounceRate]);

    // Recalculate percentages when Delivery Rate changes
    useEffect(() => {
        if (deliveryRate && deliveryRate > 0) {
            const newOpenPercentage = openRate ? (openRate / deliveryRate) * 100 : 0;
            const newClickPercentage = clickRate ? (clickRate / deliveryRate) * 100 : 0;

            setOpenRatePer(Number(newOpenPercentage.toFixed(2)));
            setClickRatePer(Number(newClickPercentage.toFixed(2)));

            // Check if openPercentage or clickPercentage individually exceed 100
            setShowOpenRate(newOpenPercentage <= 100);
            setShowClickRate(newClickPercentage <= 100);
        } else {
            setOpenRatePer(null);
            setClickRatePer(null);
            setShowOpenRate(false);
            setShowClickRate(false);
        }
    }, [deliveryRate, openRate, clickRate]);

    const handleFromDate = (e: Date | null) => {
        setFromDate(e);
    }

    const handleClear = () => {
        if (id !== 0 && props.item) {
            setId(props.item.id);
            setSubject(props.item.subject);
            setPartnerType(props.item.targetGroup);
            handleFromDate(new Date(props.item.deliveryDate));
            setQuantitySent(props.item.quantitySent);
            setDeliveryRate(props.item.deliveryRate);
            setBounceRate(props.item.bounceRate);
            setOpenRate(props.item.openRate);
            setClickRate(props.item.clickRate);
            setDeliveryRatePer(props.item.deliveryRatePer);
            setBounceRatePer(props.item.bounceRatePer);
            setOpenRatePer(props.item.openRatePer);
            setClickRatePer(props.item.clickRatePer);
        } else {
            setId(0);
            setSubject('');
            setPartnerType('');
            setFromDate(null);
            setQuantitySent(null);
            setDeliveryRate(null);
            setBounceRate(null);
            setOpenRate(null);
            setClickRate(null);
        }

    }

    const handleSave = () => {
        setSubmitted(true);
        console.log(subject, partnerType, fromDate, quantitySent, deliveryRate, bounceRate, openRate, clickRate);
        if (
            subject &&
            partnerType &&
            fromDate &&
            quantitySent !== null &&
            quantitySent !== undefined &&
            deliveryRate !== null &&
            deliveryRate !== undefined &&
            bounceRate !== null &&
            bounceRate !== undefined &&
            openRate !== null &&
            openRate !== undefined &&
            clickRate !== null &&
            clickRate !== undefined
        ) {
            if (!((deliveryRate + bounceRate) == quantitySent)) {
                alert({ message: 'Delivery Rate และ Bounce Rate รวมกันต้องเท่ากับจำนวนที่ส่ง', type: 'error' });
                return;
            }
            else if (openRate > deliveryRate) {
                alert({ message: 'Open Rate ต้องไม่มากกว่า Delivery Rate', type: 'error' });
                return
            }
            else if (clickRate > deliveryRate) {
                alert({ message: 'Click Rate ต้องไม่มากกว่า Delivery Rate', type: 'error' });
                return
            }

            const newPartnerRemarketing = {
                id: id,
                subject: subject,
                targetGroup: partnerType,
                deliveryDate: fromDate,
                quantitySent: quantitySent,
                deliveryRate: deliveryRate,
                bounceRate: bounceRate,
                openRate: openRate,
                clickRate: clickRate,
                deliveryRatePer: deliveryRatePer?.toFixed(2) as any,
                bounceRatePer: bounceRatePer?.toFixed(2) as any,
                openRatePer: openRatePer?.toFixed(2) as any,
                clickRatePer: clickRatePer?.toFixed(2) as any,
            };

            console.log(newPartnerRemarketing);
            dispatch(queryclear());
            dispatch(saveRemargetingInformation(newPartnerRemarketing as PartnerRemarketing));

        } else {
            alert({ message: 'กรุณากรอกข้อมูลให้ครบ', type: 'error' });
        }
    }


    const getErrorRequire = (value: any) => {
        return submitted && (value === null || value === undefined || value === '') ? true : false;
    }

    return (
        <Modal
            {...props}
            dialogClassName="modal-80w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            onShow={() => {
                handleClear();
            }}
        >
            <form>
                <Modal.Header closeButton>
                    <Modal.Title>ข้อมูลการทำ Remarketing</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <>
                        <div style={{ display: 'flex' }}>
                            <div className="col-2" style={{ paddingLeft: "0px" }}>
                                <label className="col-form-label">เรื่องที่ส่ง <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className="col-9">
                                <input type="text" className={'form-control ' + ((getErrorRequire(subject)) ? "is-invalid" : "")} value={subject} onChange={e => setSubject(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-2" style={{ paddingLeft: "0px" }}>
                                <label className="col-form-label">ประเภทกลุ่มเป้าหมาย <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className="col-9">
                                <input type="text" className={'form-control ' + ((getErrorRequire(partnerType)) ? "is-invalid" : "")} value={partnerType} onChange={e => setPartnerType(e.target.value)} />

                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-6" style={header}>
                                <div className="col-4" style={{ paddingLeft: "0px" }}>
                                    <label className="col-form-label">วันที่ส่ง <span style={{ color: "red" }}>*</span></label>
                                </div>
                                <InputGroup className="col-6">
                                    <label className='position-relative' style={{ width: "100%" }}>
                                        <ThaiDateTimePicker className={"form-control " + ((getErrorRequire(fromDate)) ? "is-invalid" : "")} selected={fromDate} onChange={(e: any) => setFromDate(e)} />
                                        <div className="search-icon" style={{ visibility: (getErrorRequire(fromDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 1 }}>
                                            <CancelIcon
                                                style={{ visibility: (fromDate == null) ? "hidden" : "visible", color: "gray" }}
                                                onClick={(e) => {
                                                    setFromDate(null);
                                                }}
                                            />
                                            <CalendarTodayIcon style={{ color: "gray" }} />
                                        </div>
                                    </label>
                                </InputGroup>
                            </div>

                            <div className="col-6" style={header}>
                                <div className="col-4" >
                                    <label className="col-form-label">จำนวนที่ส่ง <span style={{ color: "red" }}>*</span>
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    จำนวนรายการที่ทำการส่ง e-Mail marketing ทั้งหมด (นำข้อมูลจาก total rcpt. มากรอก)
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="number" min="0" className={'form-control ' + ((getErrorRequire(quantitySent)) ? "is-invalid" : "")} value={quantitySent ?? ''} onChange={(e) => {
                                        const parsedValue = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                                        if (parsedValue !== undefined && parsedValue < 0) {
                                            return;
                                        }
                                        setQuantitySent(Number(parsedValue));
                                    }} />

                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-6" style={header}>
                                <div className="col-4" style={{ paddingLeft: "0px" }} >
                                    <label className="col-form-label">Delivery Rate <span style={{ color: "red" }}>*</span>
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    จำนวนรายการที่สามารถส่งถึงผู้รับได้ (นำข้อมูลจาก Delivered มากรอก)
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="number" min="0" className={'form-control ' + ((getErrorRequire(deliveryRate)) ? "is-invalid" : "")} value={deliveryRate ?? ''} onChange={(e) => {
                                        const parsedValue = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                                        if (parsedValue !== undefined && parsedValue < 0) {
                                            return;
                                        }
                                        setDeliveryRate(Number(parsedValue));
                                    }} />
                                </div>
                                <div className="col-1" style={header}>
                                    {deliveryRatePer !== undefined && deliveryRatePer !== 0 && (
                                        <i style={textPercentage}>
                                            {showDeliveryAndBounce && deliveryRatePer !== null ? `${deliveryRatePer}%` : ""}
                                        </i>
                                    )}
                                </div>
                            </div>

                            <div className="col-6" style={header}>
                                <div className="col-4">
                                    <label className="col-form-label">Bounce Rate <span style={{ color: "red" }}>*</span>
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    จำนวนรายการที่ไม่สามารถส่งไปถึงผู้รับได้ (นำข้อมูลจาก Bounce มากรอก)
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="number" min="0" className={'form-control ' + ((getErrorRequire(bounceRate)) ? "is-invalid" : "")} value={bounceRate ?? ''} onChange={(e) => {
                                        const parsedValue = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                                        if (parsedValue !== undefined && parsedValue < 0) {
                                            return;
                                        }
                                        setBounceRate(Number(parsedValue));
                                    }} />
                                </div>
                                <div className="col-1" style={header}>
                                    {bounceRatePer !== undefined && bounceRatePer !== 0 && (
                                        <i style={textPercentage}>
                                            {showDeliveryAndBounce && bounceRatePer !== null ? `${bounceRatePer}%` : ""}
                                        </i>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-6" style={header}>
                                <div className="col-4" style={{ paddingLeft: "0px" }}>
                                    <label className="col-form-label">Open Rate <span style={{ color: "red" }}>*</span>
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    จำนวนรายการที่ผู้รับได้ทำการเปิด e-Mail ขึ้นมาอ่าน  (นำข้อมูลจาก opens มากรอก) โดยที่ %rate คำนวณจาก Open Rate เทียบกับ Delivery Rate
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="number" min="0" className={'form-control ' + ((getErrorRequire(openRate)) ? "is-invalid" : "")} value={openRate ?? ''} onChange={(e) => {
                                        const parsedValue = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                                        if (parsedValue !== undefined && parsedValue < 0) {
                                            return;
                                        }
                                        setOpenRate(Number(parsedValue));
                                    }} />
                                </div>
                                <div className="col-1" style={header}>
                                    {openRatePer !== undefined && openRatePer !== 0 && (
                                        <i style={textPercentage}>
                                            <span>{showOpenRate && openRatePer !== null ? `${openRatePer}%` : ""}</span>
                                        </i>
                                    )}
                                </div>
                            </div>

                            <div className="col-6" style={header}>
                                <div className="col-4">
                                    <label className="col-form-label">Click Rate <span style={{ color: "red" }}>*</span>
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    จำนวนรายการที่ผู้รับได้ทำการเปิด e-Mail ขึ้นมาอ่าน และ click link ใน e-mail เพื่อดูข้อมูลเพิ่มเติม (นำข้อมูลจาก clicks มากรอก)
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="number" min="0" className={'form-control ' + ((getErrorRequire(clickRate)) ? "is-invalid" : "")} value={clickRate ?? ''} onChange={(e) => {
                                        const parsedValue = e.currentTarget.value ? Number(e.currentTarget.value) : undefined;
                                        if (parsedValue !== undefined && parsedValue < 0) {
                                            return;
                                        }
                                        setClickRate(Number(parsedValue));

                                    }} />
                                </div>
                                {clickRatePer !== undefined && clickRatePer !== 0 && (
                                    <i style={textPercentage}>
                                        {showClickReate && clickRatePer !== null ? `${clickRatePer}%` : ""}
                                    </i>
                                )}
                            </div>
                        </div>

                    </>
                </Modal.Body>

                <Modal.Footer style={{ justifyContent: "center" }}>
                    <button type="button" className="btn btn-outline-primary" onClick={handleClear}> Reset </button>&nbsp;
                    <Button variant="primary" onClick={handleSave} disabled={false}> Save </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default RemarketingHistoryImportModal;
