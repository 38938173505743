import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { AlertContainer, alert } from 'react-custom-alert';
import { getAnalyzeForRanking, getAnalyzeForRankingHistory, getAnalyzeForRankingHistoryDetail, saveAnalyzeForRanking } from '../../../../store/partner/actions';
import MainMenu from '../../menu';
import PartnerTab from '../../../tab/partner-tab';
import PartnerAnalyzeRankingResult from './PartnerAnalyzeRankingResult';
import PartnerAnalyzeRankingHistory from './PartnerAnalyzeRankingHistory';
import { PartnerRankingAnalyze, PartnerRankingAnalyzeHistory } from '../../../../model/response/partner-ranking';
import { message } from 'antd';
import { queryclear } from '../../../../store/search/actions';
import PartnerAnalyzeRankingHistoryModal from './PartnerAnalyzeRankingHistoryModal';
import PartnerAnalyzeRankingGrade from './PartnerAnalyzeRankingGrade';
import PartnerAnalyzeRankingResetModal from './PartnerAnalyzeRankingResetModal';
import DynamicSuggestion from './DynamicSuggestion';

type Props = {}

const PartnerAnalyzeRanking = (props: Props) => {
    const [init, setInit] = useState(0);
    const [initHistory, setInitHistory] = useState(0)
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();
    const [initPartnerAnalyzeRanking, setInitPartnerAnalyzeRanking] = useState("");
    const [partnerAnalyzeRanking, setPartnerAnalyzeRanking] = useState<PartnerRankingAnalyze>({ admin: [] } as any);
    const [partnerAnalyzeRankingHistoryList, setPartnerAnalyzeRankingHistoryList] = useState<PartnerRankingAnalyzeHistory[]>({ admin: [] } as any);
    const [partnerAnalyzeRankingHistory, setPartnerAnalyzeRankingHistory] = useState<PartnerRankingAnalyze>();
    const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
    const [showResetModal, setResetModal] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isCreatePartner, setIsCreatePartner] = useState(false);
    const [isReadyToSave, setIsReadyToSave] = useState(false);
    const [currentAction, setCurrentAction] = useState<(() => void) | null>(null);
    const tagLevel1 = "<level_1>";
    const tagLevel2 = "<level_2>";
    const messages = [
        tagLevel1+"1. เพื่อให้คะแนนองค์กรพันธมิตร โดยการให้คะแนนด้านต่างๆ ดังนี้",
        tagLevel2+"1.1 การทำงานขององค์กร",
        tagLevel2+"1.2 การจัดกิจกรรมร่วมกับตลาดหลักทรัพย์ฯ อย่างเหมาะสมและต่อเนื่อง",
        tagLevel2+"1.3 ผลลัพธ์จากความร่วมมือ",
        tagLevel2+"<br/>",
        tagLevel2+"โดยมีเกณฑ์การให้คะแนน ดังนี้",
        tagLevel2+"5 หมายถึง องค์กรที่มีความร่วมมือ ระดับดีเยี่ยม",
        tagLevel2+"4 หมายถึง องค์กรที่มีความร่วมมือ ระดับดี",
        tagLevel2+"3 หมายถึง องค์กรที่มีความร่วมมือ ระดับปานกลาง",
        tagLevel2+"2 หมายถึง องค์กรที่มีความร่วมมือ ระดับน้อย",
        tagLevel2+"1 หมายถึง องค์กรที่มีความร่วมมือ ระดับน้อยมาก",
        tagLevel2+"0 หมายถึง องค์กรที่ยังไม่มีความร่วมมือ",
        tagLevel2+"<br/>",
        tagLevel1+"2. เพื่อใช้จัดลำดับองค์กรพันธมิตร โดยการให้คะแนนตามเงื่อนไขที่กำหนด",
        tagLevel2+"A หมายถึง องค์กรที่มีความร่วมมือ ระดับดีเยี่ยม",
        tagLevel2+"B+ หมายถึง องค์กรที่มีความร่วมมือ ระดับดี ที่มีศักยภาพพัฒนาไปในระดับสูงขึ้นได้",
        tagLevel2+"B หมายถึง องค์กรที่มีความร่วมมือ ระดับดี",
        tagLevel2+"C+ หมายถึง องค์กรที่มีความร่วมมือ ระดับปานกลาง ที่มีศักยภาพพัฒนาไปในระดับสูงขึ้นได้",
        tagLevel2+"C หมายถึง องค์กรที่มีความร่วมมือ ระดับปานกลาง",
        tagLevel2+"D หมายถึง องค์กรที่มีความร่วมมือ ระดับน้อย",
        tagLevel2+"F หมายถึง องค์กรที่มีความร่วมมือ ระดับน้อยมาก",
        tagLevel2+"<br/>",
        tagLevel1+"3. เพื่อดูประวัติการประเมินคะแนนและการจัดลำดับองค์กรพันธมิตร",
    ];

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) ) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "get_partner_analyze_ranking") {
                        setInitPartnerAnalyzeRanking(JSON.stringify(res.data));
                        setPartnerAnalyzeRanking(res.data);
                        setInit(1);
                    }
                    if (res.table === "get_partner_analyze_ranking_history") {
                        setPartnerAnalyzeRankingHistoryList(res.data);
                        setInitHistory(1);
                    }
                    if (res.table === "get_partner_analyze_ranking_history_detail") {
                        setPartnerAnalyzeRankingHistory(res.data);
                    }
                    if (res.table === "save_partner_analyze_ranking") {
                        setInitPartnerAnalyzeRanking(JSON.stringify(res.data));
                        setPartnerAnalyzeRanking(res.data);
                        dispatch(getAnalyzeForRankingHistory(parseInt(id)));
                        alert({ message: 'Saved', type: 'success' });
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                dispatch(queryclear);
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }
        })
        if (parseInt(id) === 0) {
            setInit(1);
            setInitHistory(1);
            setIsCreatePartner(true);
        } else {
            dispatch(getAnalyzeForRanking(parseInt(id)));
            dispatch(getAnalyzeForRankingHistory(parseInt(id)));
        }
        return unsubscribe;
    }, []);

    useEffect(() => {
        const allAnswered = partnerAnalyzeRanking.questionList?.every((question: any) =>
            question.subQuestionList.every((subQuestion: any) => subQuestion.answer != null && subQuestion.answer !== "")
        );
        setIsReadyToSave(allAnswered);
    }, [partnerAnalyzeRanking?.questionList]);

    // Helper function to check if data has changed
    const hasDataChanged = (): boolean => {
        if (initPartnerAnalyzeRanking) {
            return initPartnerAnalyzeRanking !== JSON.stringify(partnerAnalyzeRanking);
        }
        return false;
    };

    const handleProfile = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => history.push("/admin/partner/profile/" + id))
        } else {
            history.push("/admin/partner/profile/" + id);
        }
        
    }
    const handleActivityOrganizeTraining = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => history.push("/admin/partner/activity/" + id + "/organize-training"))
        } else {
            history.push("/admin/partner/activity/" + id + "/organize-training");
        }
    }
    const handlePackage = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => history.push("/admin/partner/package/" + id))
        } else {
            history.push("/admin/partner/package/" + id);
        }
    }
    const handleAnalyzeRanking = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => history.push("/admin/partner/analyze-ranking/" + id))
        } else {
            history.push("/admin/partner/analyze-ranking/" + id);
        }
    }
    const handleBack = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => history.push('/admin/search/partner'))
        } else {
            history.push('/admin/search/partner')
        }
    }
    const handleClear = () => {
        if (hasDataChanged()) {
            handleShowResetModal(() => dispatch(getAnalyzeForRanking(parseInt(id))))
        } else {
            dispatch(getAnalyzeForRanking(parseInt(id)))
        }
    }

    const invalidScore = () => {
        return partnerAnalyzeRanking?.questionList?.some(question =>
            question.subQuestionList?.some(subQuestion => subQuestion.answer == null) // Strict check for null/undefined
        ) ?? false;
    }

    const handleSubmit = () => {
        let messageError = [];
        setSubmitted(true);
        if (invalidScore()) {
            messageError.push('กรุณากรอกข้อมูลให้ครบถ้วน')
        }

        if (messageError.length > 0) {
            let content = <span className='text-left'>
                <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                {messageError.map(x => (<div>
                    - {x}
                </div>))}
            </span>
            messageApi.error({
                type: 'error',
                content: content,
                className: 'custom-class'
            });
        } else {
            const body = { ...partnerAnalyzeRanking }
            dispatch(saveAnalyzeForRanking(body as PartnerRankingAnalyze));
        }
    }

    const handleAnswerUpdate = (questionIndex: number, subQuestionIndex: number, answer: number) => {
        const updatedQuestions = [...partnerAnalyzeRanking.questionList];
        updatedQuestions[questionIndex].subQuestionList[subQuestionIndex].answer = answer;
      
        setPartnerAnalyzeRanking({
          ...partnerAnalyzeRanking,
          questionList: updatedQuestions,
        });
      };

    const hideHistoryModal = () => {
        setShowHistoryModal(false)
        setPartnerAnalyzeRankingHistory(undefined);
    }
    const openHistoryModal = (answerId: number) => {
        dispatch(getAnalyzeForRankingHistoryDetail(answerId));
        setShowHistoryModal(true)
    }

    const handleShowResetModal = (action: () => void) => {
        setCurrentAction(() => action); // Set the action to perform on confirm
        setResetModal(true);
    };
    const hideResetModal = () => {
        setResetModal(false)
        setCurrentAction(null); // Clear the action when canceled
    }
    const confirmResetModal = async () => {
        if (currentAction) {
            await currentAction(); // Execute the current action
        }
        setResetModal(false); // Close the modal
        setCurrentAction(null); // Clear the action after execution
    }

    return (
        <div>
            {contextHolder}
            <MainMenu>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">ข้อมูลองค์กร</h4></div>
                    {(init === 1 && initHistory === 1) &&
                        <>
                            <br />
                            {PartnerTab(4, true, handleProfile, handleActivityOrganizeTraining, handlePackage, handleAnalyzeRanking)}
                            <br />
                            <div style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}>
                                <h5>การประเมินเพื่อจัดทำ Partner Ranking <i className="header">{partnerAnalyzeRanking.partnerName}</i></h5>
                            </div>
                            
                            <div style={{ display: 'flex'}}>
                                <DynamicSuggestion 
                                    messages={messages}
                                    showExpandIcon={false} />
                                <span style={{marginLeft: '15px'}}></span>
                                <PartnerAnalyzeRankingGrade 
                                    grade={partnerAnalyzeRanking.grade}
                                    gradeCompareResult={partnerAnalyzeRanking.gradeCompareResult}
                                    createDate={partnerAnalyzeRanking.createDate} />
                            </div>
                            <br />
                            <PartnerAnalyzeRankingResult 
                                questionList={[...(partnerAnalyzeRanking?.questionList || [])]} // Spread to create a new array
                                onAnswerUpdate={handleAnswerUpdate} />  {/* Pass the callback function  */}
                            
                            <br />
                            <PartnerAnalyzeRankingHistory 
                                historyList={partnerAnalyzeRankingHistoryList}
                                openHistoryModal={openHistoryModal} />
                            <br />
                            <div className='d-flex'>
                                <div className='ml-auto mb-3'>
                                    <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>
                                    {(!isCreatePartner) && <>
                                        &nbsp;
                                        <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                            onClick={() => handleClear()} >
                                            Clear
                                        </button>&nbsp;
                                        <button
                                            type="button"
                                            className={`btn ${isReadyToSave ? "btn-success" : "dark-gray-button"}`}
                                            style={{ width: "120px" }}
                                            onClick={handleSubmit}
                                            disabled={!isReadyToSave} >
                                            Save
                                        </button>
                                    </>}
                                </div>
                            </div>
                        </>
                    }
                </div>
                <PartnerAnalyzeRankingHistoryModal show={showHistoryModal} partnerAnalyzeRanking={partnerAnalyzeRankingHistory} onHide={() => hideHistoryModal()} />
                <PartnerAnalyzeRankingResetModal show={showResetModal} onHide={() => hideResetModal()} confirmReset={() => confirmResetModal()} />
                <AlertContainer floatingTime={3000} />
            </MainMenu>
        </div>
    );

}

export default PartnerAnalyzeRanking