import React, { useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { PartnerRankingAnalyze } from '../../../../model/response/partner-ranking';
import { Util } from '../../../../api/util';
import PartnerAnalyzeRankingGrade from './PartnerAnalyzeRankingGrade';
type Props = ModalProps & {
    partnerAnalyzeRanking?: PartnerRankingAnalyze; 
}

const PartnerAnalyzeRankingHistoryModal = (props: Props) => {
    return (
        <Modal
                {...props}
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    {(props.partnerAnalyzeRanking) && (
                        <>
                            <div className='row'>
                                <div className='col-9'>
                                    <div className="col-12 mt-2">ชื่อบริษัท : <b>{props.partnerAnalyzeRanking.partnerName}</b></div>
                                    <div className="col-12 mt-2">ผู้ประเมิน : <b>{props.partnerAnalyzeRanking.createBy}</b></div>
                                    <div className="col-12 mt-2">วันที่ประเมิน : <b>{Util.datetimetostr(new Date(props.partnerAnalyzeRanking.createDate))}</b></div>
                                </div>
                                <div className='col-3'>
                                    <PartnerAnalyzeRankingGrade 
                                        grade={props.partnerAnalyzeRanking.grade}
                                        gradeCompareResult={null}
                                        createDate={null} />
                                </div>
                            </div>
                            <React.Fragment>
                                <div className="text-left mt-4" style={{ position: "relative", marginBottom: 5 }}>
                                    <div className="yellow-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <div style={{marginLeft: "15px"}}>คำถามสำหรับการประเมิน</div>
                                    </div>
                                    <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                        {props.partnerAnalyzeRanking.questionList?.map((question, index) => (
                                            <div className="row mt-1 mb-2" key={index} style={{ display: "flex" }}>
                                                <label className="col-3 mt-2" style={{ fontWeight: 600, flex: "0 0 30%"/*30% width*/ }}>{index+1}. {question.questionText} ({question.questionPercentage}%)</label>
                                                <div className='gray-button' style={{ padding: "15px", borderRadius: "5px", flex: "0 0 70%"/*70% width*/ }}>
                                                    {question.subQuestionList?.map((subQuestion, subIndex) => (
                                                        <div className="row mt-1" key={`${index}-${subIndex}`}>
                                                            <label className="col-8">{index+1}.{subIndex+1} {subQuestion.questionText} ({subQuestion.questionPercentage}%)</label>
                                                            <input id={`answer-${index}-${subIndex}`} maxLength={10} 
                                                                className="form-control" value={subQuestion.answer} style={{ width: "150px", paddingBottom: "10px", textAlign: "right" }} disabled />
                                                        </div>
                                                    ))}
                                                </div>
                                        </div>
                                        ))}
                                    
                                    </div>
                                </div>
                            </React.Fragment>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        {props.cancelButton || 'Close'}
                    </Button>
                </Modal.Footer>

            </Modal>
    )
}

export default PartnerAnalyzeRankingHistoryModal