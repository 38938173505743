import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { ProjectAPI } from '../../api/project-api';
import { TrainerAPI } from '../../api/trainer-api';
import { CourseAPI } from '../../api/course-api';
import { SurveyAPI } from '../../api/survey-api';
import { HistoryAPI } from '../../api/history-api';
import { dispatchError } from '../search/actions'; 
import { CourseType } from '../../api/define';

export const listproject = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    ProjectAPI.list().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "project",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const createclass = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_class"
    });
    ClassAPI.add(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "class",
            page: "edit_class",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_class",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_class",dispatch,error);
    })
};

export const updateclass = (id: number,item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_class"
    });
    ClassAPI.update(id,item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "class",
            page: "edit_class",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_class",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_class",dispatch,error);
    })
};


export const getclass = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    ClassAPI.getClass(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listtrainer = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    TrainerAPI.listAll().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "trainer",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listcourse = (name: string | null,courseType: CourseType | null,classId:number | null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    CourseAPI.list(name,courseType,classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "course",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listcourseByClassId = (classId: number | null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    CourseAPI.listByClassId(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "course_by_classId",
            page: "result_number",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listallsurvey = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    SurveyAPI.listAll().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "survey",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const deleteclass = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ClassAPI.deleteClass(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "class",
            page: "edit_class",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const uploadprivacy = (classCourseId: string,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    ClassAPI.uploadPrivacy(classCourseId,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "privacy",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const uploadclassbanner = (classId: string,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    ClassAPI.uploadClassBanner(classId,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class_banner",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const uploadclasscoursebanner = (classCourseId: string,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    ClassAPI.uploadClassCourseBanner(classCourseId,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class_course_banner",
            page: "edit_class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const generateseparategroup= (class_course_id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    HistoryAPI.generateSeparateGroup(class_course_id)
    .then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "generate_separategroup",
            page: "edit_class",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listCourseByPartnerRemarketing = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    CourseAPI.listCourseByPartnerRemarketing().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_course_partner_remarketing",
            page: "partner_remarketing",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("list_course_partner_remarketing",dispatch,error);
    })
};