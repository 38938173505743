import { PartnerKnowledgeRegistration } from '../model/request/partner-knowledge-regis';
import { OrgGroup } from '../model/response/organization.model';
import { PartnerMediaRequest } from '../model/response/partner-activity-media';
import { PartnerProfileListResponse } from '../model/response/partner-profile';
import { PartnerRankingAnalyze } from '../model/response/partner-ranking';
import { PartnerRemarketing } from '../model/response/partner-remarketing';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { MediaType } from './define';
import { Util } from './util';

const PARTNER = "/api/v1/partner";
const CONTACT = "/api/v1/contact";
const ORGANIZATION = "/api/v1/organization";
const EXPORT = "/api/v1/partner/export";
const HR = "/api/v1/hr";

export const EXPORT_PARTNER_URL = DEFAULT_SERVER + EXPORT;

const SEARCH_PARTNER_URL = DEFAULT_SERVER + PARTNER;
const SEARCH_PARTNER_WITHOUT_PAGE_URL = DEFAULT_SERVER + "/api/v1/partner/list"
const GET_PARTNER_URL = DEFAULT_SERVER + PARTNER;
const ADD_PARTNER_URL = DEFAULT_SERVER + PARTNER;
const LIST_CONTACT_URL = DEFAULT_SERVER + CONTACT;
const LIST_ORGANIZATION_URL = DEFAULT_SERVER + ORGANIZATION;
const LIST_ORGANIZATION_GROUP_URL = DEFAULT_SERVER + '/api/v1/organization/group'
const LIST_HR_URL = DEFAULT_SERVER + HR;
const LIST_DEPARTMENT_URL = DEFAULT_SERVER + '/api/v1/partner/knowledge-management/department';
const LIST_KNOWLEDGE_CATEGORY_URL = DEFAULT_SERVER + '/api/v1/partner/knowledge-management/category';
const UPLOAD_FILE_KNOWLEDGE = DEFAULT_SERVER + '/api/v1/partner/knowledge-management/file';
const SAVE_KNOWLEDGE_PARTNER = DEFAULT_SERVER + '/api/v1/partner/knowledge-management/category/save';
const PARTNER_LOG_REQUEST_PROFILE = DEFAULT_SERVER + '/api/v1/partner-log-request';
const PARTNER_PROFILE = DEFAULT_SERVER + '/api/v1/partner';
const MERGE_PARTNER_SEARCH = DEFAULT_SERVER + '/api/v1/partner/search';
const PARTNER_RANKING = DEFAULT_SERVER + '/api/v1/partner/ranking';
const IMPORT_ARTNER_RANKING = DEFAULT_SERVER + '/api/v1/partner/ranking/import';
const PARTNER_REMARKETING = DEFAULT_SERVER + '/api/v1/partner/remarking';
const PARTNER_REMARKETING_TARGET = DEFAULT_SERVER + '/api/v1/partner';
export const EXPORT_HPARTNER_RANKING_URL = DEFAULT_SERVER + '/api/v1/partner/ranking/export';
export const EXPORT_HPARTNER_RANKING_TEMPLATE_URL = DEFAULT_SERVER + '/api/v1/partner/ranking/export-template';
export const EXPORT_HISTORY_PARTNER_REMARKETING = DEFAULT_SERVER + '/api/v1/partner/remarking/export-history';
export const EXPORT_TARGET_PARTNER_REMARKETING = DEFAULT_SERVER + '/api/v1/partner/remarking/export-target';

export class PartnerAPI {
    static listByClassId = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_PARTNER_URL + "/list/" + classId;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list partner by classId error:", err);
                    return reject(err);
                })
        })
    }
    public static list = async (name: string, code: string, orgType: string | null, page: number, limit: number, orgGroup: string | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_PARTNER_URL + "?" + ((name !== "") ? "name=" + encodeURIComponent(name) : "") + ((code !== "") ? "&code=" + encodeURIComponent(code) : "") + ((orgGroup != null) ? "&org_group=" + orgGroup : "") + ((orgType != null) ? "&org_type=" + orgType : "") + "&page=" + page + "&limit=" + limit;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list partner error:", err);
                    return reject(err);
                })
        })
    }

    public static listWithoutPage = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_PARTNER_WITHOUT_PAGE_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list partner error:", err);
                    return reject(err);
                })
        })
    }

    public static get = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = GET_PARTNER_URL + "/" + id;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("get partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get partner error:", err);
                    return reject(err);
                })
        })
    }

    public static listOrganization = async (page: number, limit: number, partnerProfile?: boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_ORGANIZATION_URL + "?page=" + page + "&limit=" + limit;
            if (partnerProfile) {
                s = s + "&partnerProfile=true";
            }
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list organization response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list organization error:", err);
                    return reject(err);
                })
        })
    }

    public static listOrganizationGroup = async (): Promise<OrgGroup[] | any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_ORGANIZATION_GROUP_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {

                    return resolve(response);
                }).catch(err => {
                    console.log("list organization group error:", err);
                    return reject(err);
                })
        })
    }

    public static create = async (item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = ADD_PARTNER_URL;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("add partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("add partner error:", err);
                    return reject(err);
                })
        })
    }

    public static update = async (id: number, item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = ADD_PARTNER_URL + "/" + id;
            apiClient.patch(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("update partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("update partner error:", err);
                    return reject(err);
                })
        })
    }

    public static delete = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = ADD_PARTNER_URL + "/" + id;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("delete partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("delete partner error:", err);
                    return reject(err);
                })
        })
    }

    public static listHR = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_HR_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list hr response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list hr error:", err);
                    return reject(err);
                })
        })
    }

    //NOT USED
    public static listContact = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_CONTACT_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list contact response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list contact error:", err);
                    return reject(err);
                })
        })
    }

    public static listKnowledgeDepartment = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_DEPARTMENT_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list department response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list department error:", err);
                    return reject(err);
                })
        })
    }

    public static listKnowledgeCategory = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_KNOWLEDGE_CATEGORY_URL;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list category response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list category error:", err);
                    return reject(err);
                })
        })
    }

    public static uploadFileKnowledge = async (partnerKnowledgeFileType: string, file: File): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file);
            if (partnerKnowledgeFileType) {
                formData.append("partnerKnowledgeFileType", partnerKnowledgeFileType)
            }
            var s = UPLOAD_FILE_KNOWLEDGE;
            apiClient.post(s, formData
                , {//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("upload knowledge response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("upload knowledge error:", err);
                    return reject(err);
                })
        })
    }

    public static saveKnowledgePartner = async (item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SAVE_KNOWLEDGE_PARTNER;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("save knowledge partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("save knowledge partner error:", err);
                    return reject(err);
                })
        })
    }

    public static getPartnerProfile = async (registrationId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/" + registrationId;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("get partner profile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get partner profile error:", err);
                    return reject(err);
                })
        })
    }

    public static getMergeLogRequestPartnerProfile = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/partner/" + partnerId;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getMergeLogRequestPartnerProfile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getMergeLogRequestPartnerProfilee error:", err);
                    return reject(err);
                })
        })
    }

    public static getPaticipantActivity = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/activity/project-participation/overview";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getPaticipantActivity response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getPaticipantActivity error:", err);
                    return reject(err);
                })
        })
    }
    public static getInfoTrainingOrganizationPartner = async (partnerId: number, page: number, size: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/activity/project-participation/partner-organization" + "?page=" + page + "&size=" + size;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getInfoTrainingOrganization response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getInfoTrainingOrganization error:", err);
                    return reject(err);
                })
        })
    }

    public static getInfoTrainingOrganizationPublic = async (partnerId: number, page: number, size: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/activity/project-participation/general" + "?page=" + page + "&size=" + size;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getInfoTrainingOrganizationPublic response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getInfoTrainingOrganizationPublic error:", err);
                    return reject(err);
                })
        })
    }

    public static getParnerProfile = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/profile";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getParnerProfile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getParnerProfile error:", err);
                    return reject(err);
                })
        })
    }

    public static getSurveyProjectForAdd = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/question-survey/profile";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getSurveyProjectForAdd response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getSurveyProjectForAdd error:", err);
                    return reject(err);
                })
        })
    }

    public static getPackageSubcategoryInterest = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/package/subcategory-history";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getPackageSubcategoryInterest response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getPackageSubcategoryInterest error:", err);
                    return reject(err);
                })
        })
    }

    public static getPartnerFaculty = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/partner/" + partnerId + "/faculty";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("getMergeLogRequestPartnerProfile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("getMergeLogRequestPartnerProfilee error:", err);
                    return reject(err);
                })
        })
    }

    public static updatePartnerProfileByRegistraion = async (registrationId: number, body: PartnerProfileListResponse): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/" + registrationId;
            apiClient.put(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("updatePartnerProfileByRegistraion response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("updatePartnerProfileByRegistraion error:", err);
                    return reject(err);
                })
        })
    }
    public static createPartnerProfile = async (body: PartnerProfileListResponse): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/new/profile";
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("createPartnerProfile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("createPartnerProfile error:", err);
                    return reject(err);
                })
        })
    }
    public static updatePartnerProfileByPartnerId = async (partnerId: number, body: PartnerProfileListResponse): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/profile";
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("updatePartnerProfileByPartnerId response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("updatePartnerProfileByPartnerId error:", err);
                    return reject(err);
                })
        })
    }
    public static mergeLogRequestToPartner = async (registrationId: number, partnerId: number, body: PartnerProfileListResponse): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/" + registrationId + "/merge/" + partnerId;
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("mergeLogRequestToPartner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("mergeLogRequestToPartner error:", err);
                    return reject(err);
                })
        })
    }

    public static createLogRequestToPartner = async (registrationId: number, body: PartnerProfileListResponse): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/" + registrationId + "/merge";
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("mergeLogRequestToPartner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("mergeLogRequestToPartner error:", err);
                    return reject(err);
                })
        })
    }

    public static resendVerifyEmail = async (registrationId: number, body: PartnerKnowledgeRegistration): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_LOG_REQUEST_PROFILE + "/" + registrationId + "/resend-verify-email";
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("add partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("add partner error:", err);
                    return reject(err);
                })
        })
    }

    public static resendVerifyEmailByPartnerId = async (partnerId: number, body: PartnerKnowledgeRegistration): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + partnerId + "/resend-verify-email";
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("add partner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("add partner error:", err);
                    return reject(err);
                })
        })
    }

    public static searchMergePartner = async (keyword: string | undefined, orgType: number | undefined, orgGroup: number | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = MERGE_PARTNER_SEARCH;
            var queryParams: string[] = [];
            if (keyword) {
                queryParams.push(`keyword=${keyword}`)
            }
            if (orgType) {
                queryParams.push(`orgType=${orgType}`)
            }
            if (orgGroup) {
                queryParams.push(`orgGroup=${orgGroup}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("searchMergePartner response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("searchMergePartner error:", err);
                    return reject(err);
                })
        })
    }

    public static getReceiveMediaOverview = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_PROFILE}/${partnerId}/activity/partner-media/overview`;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("partner media overview by partnerId error:", err);
                    return reject(err);
                })
        })
    }

    public static listPartnerMedia = async (partnerId: number, page: number, limit: number, name?: string | undefined, categoryId?: number | undefined, fileType?: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_PROFILE}/${partnerId}/activity/partner-media/search`;
            var queryParams: string[] = [];
            if (name && name.trim().length > 0) {
                queryParams.push(`name=${name}`)
            }
            if (categoryId && !isNaN(categoryId)) {
                queryParams.push(`category_id=${categoryId}`)
            }
            if (fileType && fileType.trim().length > 0) {
                queryParams.push(`file_type=${fileType}`)
            }
            queryParams.push(`page=${page}`)
            queryParams.push(`limit=${limit}`)
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("search list partner media error:", err);
                    return reject(err);
                })
        })
    }

    public static createPartnerMedia = async (partnerId: number, item: PartnerMediaRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_PROFILE}/${partnerId}/activity/partner-media/create`;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("add partner media response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("add partner media error:", err);
                    return reject(err);
                })
        })
    }

    public static updatePartnerMedia = async (partnerId: number, item: PartnerMediaRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_PROFILE}/${partnerId}/activity/partner-media/update`;
            apiClient.put(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("update partner media response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("update partner media error:", err);
                    return reject(err);
                })
        })
    }

    public static deletePartnerMedia = async (partnerId: number, partnerMediaId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_PROFILE}/${partnerId}/activity/partner-media/delete/${partnerMediaId}`;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("delete partner media response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("delete partner media error:", err);
                    return reject(err);
                })
        })
    }

    public static getAnalyzeForRanking = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (partnerId) { queryParams.push(`partnerId=${partnerId}`); }

            var s = `${PARTNER_RANKING}/analyze-ranking`;
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Analyze for Ranking error:", err);
                    return reject(err);
                })
        })
    }

    public static saveAnalyzeForRanking = async (item: PartnerRankingAnalyze): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_RANKING}/analyze-ranking/save`;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("save Partner Analyze for Ranking error:", err);
                    return reject(err);
                })
        })
    }

    public static getAnalyzeForRankingHistory = async (partnerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (partnerId) { queryParams.push(`partnerId=${partnerId}`); }

            var s = `${PARTNER_RANKING}/analyze-ranking/history`;
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Analyze for Ranking History error:", err);
                    return reject(err);
                })
        })
    }

    public static getAnalyzeForRankingHistoryDetail = async (answerId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_RANKING}/analyze-ranking/history/${answerId}`;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Analyze for Ranking History Detail error:", err);
                    return reject(err);
                })
        })
    }

    public static partnerRankingPagination = async (partnerName: string | undefined, gradeList: string[] | undefined, createYear: number | undefined, page: number, size: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParam = PartnerAPI.getParamPartnerRanking(partnerName, gradeList, createYear, page, size);

            var s = `${PARTNER_RANKING}/pagination` + '?' + queryParam;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Ranking Pagination error:", err);
                    return reject(err);
                })
        })
    }

    public static getPartnerRankingYear = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_RANKING}/get-year`;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Ranking Year error:", err);
                    return reject(err);
                })
        })
    }

    public static getParamPartnerRanking = (partnerName: string | undefined, gradeList: string[] | undefined, createYear: number | undefined, page: number | undefined, size: number | undefined) => {
        var queryParams: string[] = [];
        if (page) { queryParams.push(`page=${page}`); }
        if (size) { queryParams.push(`size=${size}`); }
        if (partnerName) { queryParams.push(`partnerName=${partnerName}`); }
        if (gradeList) { queryParams.push(gradeList.map((grade) => `grade=${encodeURIComponent(grade)}`).join('&')); }
        if (createYear) { queryParams.push(`createYear=${createYear}`); }
        return queryParams.join('&');
    }

    public static importPartnerRanking = async (file: File, saveFile: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("saveFile", saveFile);

            var s = `${IMPORT_ARTNER_RANKING}`;
            apiClient.post(s, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data; charset=utf-8",
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("import Partner Ranking error:", err);
                    return reject(err);
                })
        })
    }


    // Remarketing Service
    public static saveRemargetingInformation = async (item: PartnerRemarketing): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_REMARKETING}/save-information`;
            apiClient.post(s, item,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("save Information for Remarketing error:", err);
                    return reject(err);
                })
        })
    }

    public static partnerRemarketingHistoryPagination = async (fromDate: Date | undefined, toDate: Date | undefined, subject: string | undefined, senderId: number[] | undefined, page: number, size: number, sort: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParam = PartnerAPI.getParamPartnerRemarketingHistory(fromDate, toDate, subject, senderId, page, size, sort);

            var s = `${PARTNER_REMARKETING}/history-pagination` + '?' + queryParam;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Remarketing History Pagination error:", err);
                    return reject(err);
                })
        })
    }

    public static deleteRemargetingInformation = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_REMARKETING + "/" + id;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("delete Remargeting Information response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("delete Remargeting Information error:", err);
                    return reject(err);
                })
        })
    }

    public static getHistoryPartnerRemarketingOverview = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_REMARKETING}/get-overview`;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get History Partner Remarketing Overview error:", err);
                    return reject(err);
                })
        })
    }

    public static getPartnerRemarketingListSender = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = `${PARTNER_REMARKETING}/list-sender`;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get History Partner Remarketing Overview error:", err);
                    return reject(err);
                })
        })
    }

    public static getParamPartnerRemarketingHistory = (fromDate: Date | undefined, toDate: Date | undefined, subject: string | undefined, senderId: number[] | undefined, page?: number, size?: number, sort?: string) => {
        var queryParams: string[] = [];
        if (page) { queryParams.push(`page=${page}`); }
        if (size) { queryParams.push(`size=${size}`); }
        if (sort) { queryParams.push(`sort=${sort}`); }
        if (fromDate) { queryParams.push(`fromDate=${encodeURIComponent(Util.getLocalISOString(fromDate))}`); }
        if (toDate) { queryParams.push(`toDate=${encodeURIComponent(Util.getLocalISOString(toDate))}`); }
        if (subject) { queryParams.push(`subject=${subject}`); }
        if (senderId) { queryParams.push(senderId.map((id) => `senderId=${encodeURIComponent(id)}`).join('&')); }
        return queryParams.join('&');
    }

    public static partnerRemarketingTargetPagination = async (orgType: number | undefined, orgGroup: number | undefined, listCompanyType: string[] | undefined, gradeList: string[] | undefined, projectGroupId: number | undefined, listProjectNameId: number[] | undefined, listCourseId: number[] | undefined, fromDate: Date | undefined, toDate: Date | undefined, page: number, size: number, sort: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParam = PartnerAPI.getParamPartnerRemarketingTarget(page, size, sort, orgType, orgGroup, listCompanyType, gradeList, projectGroupId, listProjectNameId,listCourseId, fromDate, toDate);

            var s = `${PARTNER_REMARKETING}/remarketing/target-pagination` + '?' + queryParam;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get Partner Remarketing Target Pagination error:", err);
                    return reject(err);
                })
        })
    }

    public static getParamPartnerRemarketingTarget = (page: number, size: number, sort: string, orgType: number | undefined, orgGroup: number | undefined, listCompanyType: string[] | undefined, gradeList: string[] | undefined, projectGroupId: number | undefined, listProjectNameId: number[] | undefined, listCourseId: number[] | undefined, fromDate: Date | undefined, toDate: Date | undefined) => {
        var queryParams: string[] = [];
        if (orgType) { queryParams.push(`orgType=${orgType}`); }
        if (orgGroup) { queryParams.push(`orgGroup=${orgGroup}`); }
        if (listCompanyType) { queryParams.push(listCompanyType.map((id) => `companyType=${id}`).join('&')); }
        if (gradeList) { queryParams.push(gradeList.map((grade) => `grade=${encodeURIComponent(grade)}`).join('&')); }
        if (projectGroupId) { queryParams.push(`projectGroupId=${projectGroupId}`); }
        if (listProjectNameId) { queryParams.push(listProjectNameId.map((id) => `projectNameId=${id}`).join('&')); }
        if (listCourseId) { queryParams.push(listCourseId.map((id) => `courseId=${id}`).join('&')); }
        if (fromDate) { queryParams.push(`fromDate=${encodeURIComponent(Util.getLocalISOString(fromDate))}`); }
        if (toDate) { queryParams.push(`toDate=${encodeURIComponent(Util.getLocalISOString(toDate))}`); }
        if (page) { queryParams.push(`page=${page}`); }
        if (size) { queryParams.push(`size=${size}`); }
        if (sort) { queryParams.push(`sort=${sort}`); }
        return queryParams.join('&');
    }

}