import React from 'react'

const tabStyle = { paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px",minWidth:"20%" };
const tabPointerStyle = { cursor: "pointer", paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px",minWidth:"20%"  };

const PartnerRankingTab = (step: number, handleRanking: Function | null, handleRemarketing: Function | null) => {
    return (<div className="row" style={{ justifyContent: "left", paddingLeft: "20px" }}>
        <div className={(step === 1) ? "yellow-button" : "gray-button"} style={(handleRanking == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleRanking != null) ? handleRanking() : () => { })}>
            <b>การจัดอันดับองค์กร</b>
        </div>&nbsp;
        <div className={(step === 2) ? "yellow-button" : "gray-button"} style={(handleRemarketing == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleRemarketing != null) ? handleRemarketing() : () => { })}>
            <b>การทำ Remarkeing</b>
        </div>
        
    </div>);
}

export default PartnerRankingTab;