export enum PartnerGradeCompareResult {
    INCREASED = "INCREASED",
    DECREASED = "DECREASED"
}

export const GradeDropdownList = [
    { label: "A", value: "A" },
    { label: "B+", value: "B+" },
    { label: "B", value: "B" },
    { label: "C+", value: "C+" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "F", value: "F" },
  ];

export const scoreList = [
    { label: "-", value: null },
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
  ];

export interface PartnerRankingAnalyze {
    answerId: number;
    grade: string;
    gradeCompareResult: PartnerGradeCompareResult;
    createDate: Date;
    createBy: string;
    partnerId: number;
    partnerName: string;
    questionList: PartnerRankingQuestion[];
}

export interface PartnerRankingQuestion {
    questionId: number;
    questionText: string;
    questionLevel: number;
    questionPercentage: number;
    subQuestionList: SubPartnerRankingSubQuestion[];
}

export interface SubPartnerRankingSubQuestion {
    questionId: number;
    questionText: string;
    questionLevel: number;
    questionParentId: number;
    questionPercentage: number;
    answer: number;
}

export interface PartnerRankingAnalyzeHistory {
    answerId: number;
    grade: string;
    createDate: Date;
    createBy: string;
}

export interface PageResponsePartnerRanking {
    totalPages: number;
    totalElements: number;
    elements: PartnerRankingBody[];
}

export interface PartnerRankingBody {
    answerId: number;
    grade: string;
    createDate: Date;
    createBy: string;
    createByFullName: string;
    partnerId: number;
    partnerName: string;
    organizationGroupName: string;
    organizationTypeName: string;
}

export interface PartnerRankingYear {
    year: number;
}

export interface PartnerRankingImport {
    totalElements: number;
    passedElements: number;
    failedElements: number;
    validateSucceed: boolean;
    saveFile: boolean;
    importList: PartnerRankingImportBody[];
}

export interface PartnerRankingImportBody {
    validateSucceed: boolean;
    excelLine: number;
    remark: string;
    partnerId: number;
    partnerCode: string;
    partnerName: string;
    questionList: PartnerRankingQuestion[];
}
